import React, {useState} from "react";

import {Col, Form, Row,} from "@govtechsg/sgds-react";


function Awarded({data, studentDetail, isDisabled, studentAwardDetail}) {
    const [academicYear, setAcademicYear] = useState(null);

    // define variable
    const [awardType, setAwardType] = useState(null);
    const [award, setAward] = useState(null);
    const [yearAwarded, setYearAwarded] = useState(null);
    const [amountAwarded, setAmountAwarded] = useState(null);

    // define list

    const handleClickEdit = () => {
        setIsDisabled(!isDisabled);
    };

    const handleAcademicYearChange = (e) => {
        const newValue = e.target.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
        }
    };

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Award Type<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    studentAwardDetail?.studFinAssistList?.length > 0
                                        ? studentAwardDetail?.studFinAssistList[0]?.awardType +" - "+ studentAwardDetail?.studFinAssistList[0]?.awardTypeDesc
                                        : "-"
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Award<span className={`text-danger`}> *</span></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    studentAwardDetail?.studFinAssistList?.length > 0
                                        ? studentAwardDetail?.studFinAssistList[0]?.award +" - " + studentAwardDetail?.studFinAssistList[0]?.awardDesc
                                        : "-"
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Year Awarded</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    studentAwardDetail?.studFinAssistList?.length > 0
                                        ? studentAwardDetail?.studFinAssistList[0]?.yrAwarded
                                        : "-"
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>Amount Awarded</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    studentAwardDetail?.studFinAssistList?.length > 0
                                        ? studentAwardDetail?.studFinAssistList[0]?.amtAwarded
                                        : "-"
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default Awarded;

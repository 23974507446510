import { createUrlWithParams } from "@/components/utils/utils";
import { apiCall } from "@/utils/apiCall";

export async function getApplicationList(paramsURL = null) {
  if (paramsURL) {
    const {
      acad_yr,
      exercise,
      adm_no,
      rec_award_type,
      rec_award_code,
      order_by_field,
      limit = 20,
    } = paramsURL;

    let params = {};
    if (acad_yr !== null && acad_yr !== undefined) {
      params = {
        ...params,
        acad_yr: acad_yr,
      };
    }
    if (exercise !== null && exercise !== undefined) {
      params = {
        ...params,
        exercise: exercise,
      };
    }
    if (adm_no !== null && adm_no !== undefined) {
      params = {
        ...params,
        adm_no: adm_no,
      };
    }
    if (rec_award_type !== null && rec_award_type !== undefined) {
      params = {
        ...params,
        rec_award_type: rec_award_type,
      };
    }
    if (rec_award_code !== null && rec_award_code !== undefined) {
      params = {
        ...params,
        rec_award_code: rec_award_code,
      };
    }

    if (order_by_field !== null && order_by_field !== undefined) {
      params = {
        ...params,
        orderByField: order_by_field,
      };
    }
    params = {
      ...params,
      limit: 0,
      appln_status_not_equals: "D",
    };
    let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
    const url = createUrlWithParams(baseUrl, params);
    return apiCall(url, {}, "GET");
  } else {
    return apiCall(`${process.env.REACT_APP_API_URL}/applications`, {}, "GET");
  }
}

export function getApplicationDetail(acadyear, exercise, admno) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/details?acad_yr=${acadyear}&exercise=${exercise}&adm_no=${admno}`,
    {},
    "GET"
  );
}

export function submitApplicationDetail(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    params,
    "POST"
  );
}

export function editApplicationDetail(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    params,
    "PATCH"
  );
}

export function transferApplicationExercise(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/transfers/anothers/exercises`,
    params,
    "POST"
  );
}

export function getDetailApplicationLog(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/details/logs?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

export function getApplicationParentLog(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/parents/logs?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

export function getApplicationSiblingLog(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/siblings/logs?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

export function getScholarship(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/scholarships?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

export function upsertScholarship(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/scholarships`,
    data,
    "POST"
  );
}

export function deleteScholarship(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/scholarships/${id}`,
    {},
    "DELETE"
  );
}

export function getDSpecialDetails(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/specials/statuses`,
    data,
    "POST"
  );
}

export function getScholarshipLog(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/scholarships/logs?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Layout from "@/components/Layout";
import SchemeDetail from "@/pages/Preparation/SchemeDetail";
import AppMaintenance from "@/pages/AppRecords/AppMaintenance/AppMaintenance";
import AppVerification from "@/pages/AppRecords/AppVerification/AppVerification";
import AppEnquiry from "@/pages/AppRecords/AppEnquiry/AppEnquiry";
import AppHomes from "@/pages/AppRecords/AppHomes/AppHomes";
import AwardDetail from "@/pages/Preparation/AwardDetail";
import ConstituencyCode from "@/pages/Preparation/SetupCspc/ConstituencyCode";
import PostalCode from "@/pages/Preparation/SetupCspc/PostalCode";
import StatusReason from "@/pages/Preparation/SetupCspc/StatusReason";
import SetupTimeline from "@/pages/Preparation/SetupTimeline";
import ComcareDetail from "@/pages/Preparation/Comcare/Detail";
import PaRemarks from "@/pages/Preparation/Remarks/PaRemarks";
import NypRemarks from "@/pages/Preparation/Remarks/NypRemarks";
import Occupation from "@/pages/Preparation/Occupation";
import Dialect from "@/pages/Preparation/RelationshipStatus/Dialect";
import MaritalStatus from "@/pages/Preparation/RelationshipStatus/MaritalStatus";
import Relationship from "@/pages/Preparation/RelationshipStatus/Relationship";
import HousingType from "@/pages/Preparation/RelationshipStatus/HousingType";
import Criteria from "@/pages/Processing/Criteria";
import UnmatchedCspc from "@/pages/Processing/UnmatchedCspc";
import Download from "@/pages/Processing/Download";
import Application from "@/pages/Processing/Application";
import BatchUpdate from "@/pages/Processing/BatchUpdate";
import PrintApplicationDetail from "@/pages/Reports/PrintApplicationDetail";
import Sample from "@/pages/Reports/Sample";
import SecondSample from "@/pages/Reports/SecondSample";
import ThirdSample from "@/pages/Reports/ThirdSample";
import PrintShortlistedApplicants from "@/pages/Reports/PrintShortlistedApplicants";
import PrintApplicationSummary from "@/pages/Reports/PrintApplicationSummary";
import PrintRecommendedApplicants from "@/pages/Reports/PrintRecommendedApplicants";
import Individual from "@/pages/Utilities/UpdateScholarship/Individual";
import EnquiryStudentParticulars from "@/pages/Utilities/EnquiryStudentParticulars";
import SystemAdministration from "@/pages/Utilities/SystemAdministration";
import Batch from "@/pages/Utilities/UpdateScholarship/Batch";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Add from "@/pages/Utilities/UpdateScholarship/Add";
import { loginRequest } from "./authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { SessionProvider } from "@/context/sessionContext";

import UploadDocument from "@/pages/Utilities/UploadDocument";



import { getCallingAPEXAPI } from "@/apis/sims/api";
import { getStaffDetail } from "@/apis/utilities/api";

export default function App() {

  const { instance, inProgress, accounts } = useMsal();

  const [roleList, setRoleList] = useState(
    JSON.parse(sessionStorage.getItem("roles")) || []
  );

  // const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

  const isAuthenticated = useIsAuthenticated();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance
      .loginRedirect(loginRequest)
      .then((resp) => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const clickToCheckNYPLink = () => {
    getCallingAPEXAPI();
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      if (accounts.length > 0) {
        const account = accounts[0];
        getStaffDetail(account?.username).then((response) => {
          if (response?.data?.data) {
            const responseData = response?.data?.data;
            const roleListResponse = responseData?.[0]?.roleList;
            if (roleListResponse) {
              const roles = roleListResponse?.map((item) => item.role);
              if (roles?.length > 0) {
                setRoleList(roles);
                sessionStorage.setItem("roles", JSON.stringify(roles));
                sessionStorage.setItem("role", roles[0]);
                sessionStorage.setItem("staffId", responseData?.[0]?.staffId);
              }
            }
          }
        });
      }
    }
  }, [inProgress, accounts]);

  return (
    <div className="App ">
      {/* <ToastContainer autoClose={10000} /> */}
      <button className="d-none" onClick={() => clickToCheckNYPLink()}>
        Click to NYP
      </button>

      {isAuthenticated ? (
        <SessionProvider>
          <Routes>
            <Route path="/" element={<Layout />}></Route>

            {roleList.includes("FAAS_ADM") && (
              <Route path={"/preparation"}>
                <Route index element={<SchemeDetail />} />
                <Route
                  path={"scheme-detail"}
                  element={<SchemeDetail />}
                ></Route>
                <Route path={"award-detail"} element={<AwardDetail />}></Route>
                <Route path={"setup-cspc"}>
                  <Route index element={<ConstituencyCode />} />
                  <Route path={"postal-code"} element={<PostalCode />}></Route>
                  <Route
                    path={"status-reason"}
                    element={<StatusReason />}
                  ></Route>
                </Route>
                <Route path={"remarks"}>
                  <Route index element={<PaRemarks />} />
                  <Route path={"pa"} element={<PaRemarks />}></Route>
                  <Route path={"nyp"} element={<NypRemarks />}></Route>
                </Route>
                <Route
                  path={"setup-occupation"}
                  element={<Occupation />}
                ></Route>
                <Route path={"setup-relationship"}>
                  <Route index element={<Dialect />} />
                  <Route path={"dialect"} element={<Dialect />}></Route>
                  <Route
                    path={"marital-status"}
                    element={<MaritalStatus />}
                  ></Route>
                  <Route
                    path={"relationship"}
                    element={<Relationship />}
                  ></Route>
                  <Route
                    path={"housing-type"}
                    element={<HousingType />}
                  ></Route>
                  <Route
                    path={"status-reason"}
                    element={<StatusReason />}
                  ></Route>
                </Route>
                <Route
                  path={"setup-timeline"}
                  element={<SetupTimeline />}
                ></Route>
                <Route path={"comcare"}>
                  <Route index element={<ComcareDetail />} />
                </Route>
              </Route>
            )}

            {(roleList.includes("FAAS_ADM") ||
              roleList.includes("FAAS_TEMP") ||
              roleList.includes("FAAS_TEMPA") ||
              roleList.includes("FAAS_VIEW")) && (
              <Route path={"/app-records"}>
                {!roleList.includes("FAAS_VIEW") && (
                  <>
                    <Route index element={<AppMaintenance />} />
                    <Route
                      path={"app-maintenance"}
                      element={<AppMaintenance />}
                    />
                    <Route
                      path={"app-verification"}
                      element={<AppVerification />}
                    ></Route>
                  </>
                )}
                <Route path={"app-enquiry"} element={<AppEnquiry />}></Route>
                <Route path={"app-homes"} element={<AppHomes />}></Route>
              </Route>
            )}

            {roleList.includes("FAAS_ADM") && (
              <Route path={"/processing"}>
                <Route index element={<Criteria />} />
                <Route path={"criteria"} element={<Criteria />}></Route>
                <Route path={"application"} element={<Application />}></Route>
                <Route
                  path={"unmatched-cspc"}
                  element={<UnmatchedCspc />}
                ></Route>
                <Route path={"download"} element={<Download />}></Route>
                <Route path={"batch-update"} element={<BatchUpdate />}></Route>
              </Route>
            )}

            {(roleList.includes("FAAS_ADM") ||
              roleList.includes("FAAS_VIEW")) && (
              <Route path={"/reports"}>
                <Route index element={<PrintApplicationDetail />} />
                <Route
                  path={"print-application-detail"}
                  element={<PrintApplicationDetail />}
                ></Route>
                <Route
                  path={"print-shortlisted-applicants"}
                  element={<PrintShortlistedApplicants />}
                ></Route>
                <Route
                  path={"print-application-summary"}
                  element={<PrintApplicationSummary />}
                ></Route>
                <Route
                  path={"print-recommended-applicants"}
                  element={<PrintRecommendedApplicants />}
                ></Route>
                <Route path={"sample"} element={<Sample />}></Route>
                <Route
                  path={"second-sample"}
                  element={<SecondSample />}
                ></Route>
                <Route path={"third-sample"} element={<ThirdSample />}></Route>
              </Route>
            )}

            {(roleList.includes("FAAS_ADM") ||
              roleList.includes("FAAS_TEMP") ||
              roleList.includes("FAAS_TEMPA") ||
              roleList.includes("FAAS_SU")) && (
              <Route path={"utilities"}>
                {/*<Route index element={<Individual/>}/>*/}
                {roleList.includes("FAAS_ADM") && (
                  <Route path={"update-scholarship"}>
                    {/*<Route index element={<Individual/>}/>*/}
                    <Route path={"individual"} element={<Individual />}></Route>
                    <Route path={"batch"} element={<Batch />}></Route>
                    <Route path={"add"} element={<Add />}></Route>
                  </Route>
                )}

                {(roleList.includes("FAAS_ADM") ||
                  roleList.includes("FAAS_TEMP") ||
                  roleList.includes("FAAS_TEMPA")) && (
                  <Route
                    path={"enquire-student-personal"}
                    element={<EnquiryStudentParticulars />}
                  ></Route>
                )}

                {roleList.includes("FAAS_SU") && (
                  <Route
                    path={"system-administration"}
                    element={<SystemAdministration />}
                  ></Route>
                )}

                <Route path={"upload"} element={<UploadDocument />}></Route>
                {/* <Route path={"credential"} element={<Credential />}></Route> */}
              </Route>
            )}
            {/* <Route path="*" element={<p>Route not found</p>}></Route> */}
          </Routes>
        </SessionProvider>
      ) : (
        "Wait a moment.. Auth in progress..."
      )}
    </div>
  );
}

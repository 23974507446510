import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { utils, writeFile } from "xlsx";
import {
  TableBody,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
  TableRow} from "@govtechsg/sgds-react";

const generatePDF = () => {
  const doc = new jsPDF();
  doc.text("Financial Assistance Application - Family Details", 20, 10);
  doc.autoTable({ html: "#family-details-table" });
  doc.save("family-details.pdf");
};

const generateExcel = () => {
  const wb = utils.book_new();
  const ws = utils.table_to_sheet(
    document.getElementById("family-details-table")
  );
  utils.book_append_sheet(wb, ws, "Family Details");
  writeFile(wb, "family-details.xlsx");
};

function SecondSample() {
  return (
    <Container>
      <Row>
      <Col xs={12} className={`text-center`}>
          <h3>Shortlisted Applicants Report</h3>
        </Col>
        <Col xs={12} className={``}>
          <p><b><u>Shortlisted Students for CDC/CCC-POLY BURSARY TIER 1 for AY16/17</u></b><br/>
          Quantum: 2350<br/>
          Eligibility Criteria: -
          </p>
        </Col>
        <Col xs={12} className={``}>
          <p>Notes <br/>
          MPCI: Monthly Per Capita Income (Gross Mthly Income / No of Dependents in Household)<br/>
          FPCI: Family Per Capita Income (Gross Mthly Income * 12 / No of Dependents in Household)<br/>
          A*: Distinction
          </p>
        </Col>
        <Col xs={12} className={``}>
        <table className="table">
          <thead>
            <tr>
            <th>PARTICULARS</th>
            <th>FINANCIAL SITUATION</th>
            <th>ACADEMIC PERFORMANCE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
              <div className="row">
                <div className="col-6">
                <p>Name: </p>
                <p>Course Code: </p>
                <p>Study Stage: </p>
                <p>Address: </p>
                <p>Tel: </p>
                <p>Pager: </p>
                <p>HP: </p>
                </div>
                <div className="col-6">
                <p><b>NAME OF 13361L</b></p>
                <p><b>DNDF03</b></p>
                <p><b>1</b></p>
                <p><b>TERM OF ADDR1 OF 13361L</b></p>
                <p><b>TERM OF TEL</b></p>
                <p><b>PAGER</b></p>
                <p><b>HANDPHONE</b></p>
                </div>
              </div>
            </td>
            <td>
            <div className="row">
                <div className="col-6">
                <p>Gross Mthly Income: </p>
                <p>Gross Household Income (CCC Income): </p>
                <p>No. of Dependents, make up of (age): </p>
                <p>MPCI: </p>
                <p>FPCI: </p>
                <p>Special Details: </p>
                </div>
                <div className="col-6">
                <p><b>$0</b></p>
                <p><b>$0</b></p>
                <p><b>2, Mother(49), Applicant (18)</b></p>
                <p><b>$850</b></p>
                <p><b>$0</b></p>
                <p><b>Father, divorced</b></p>
                </div>
              </div>

            </td>
            <td>
              <div className="row">
                <div className="col-6">
                  <p>Entry Qual: </p>
                  <p>GCE O Level Aggregate: </p>
                  <p>CCA Grade (Points): </p>
                </div>
                <div className="col-6">
                  <p><b>GCOL</b></p>
                  <p><b>16</b></p>
                  <p><b>2 (2)</b></p>
                </div>
              </div>
              <table className="table table-bordered table-striped table-responsive">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Year</th>
                    <th>Grade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>English</td>
                    <td>2016</td>
                    <td>A*</td>
                  </tr>
                  <tr>
                    <td>Math</td>
                    <td>2016</td>
                    <td>A</td>
                  </tr>
                  <tr>
                    <td>Science</td>
                    <td>2016</td>
                    <td>A</td>
                  </tr>
                  <tr>
                    <td>Chinese</td>
                    <td>2016</td>
                    <td>A</td>
                  </tr>
                </tbody>
              </table>

            </td>
            </tr>
          </tbody>
        </table>
        </Col>
      </Row>
    </Container>
  );
}

export default SecondSample;

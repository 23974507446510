import { apiCall } from "@/utils/apiCall";

export function getAcademyYear() {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines/distincts/acadyears`,
    {},
    "GET"
  );
}

export function getExercise(params) {
  let url =
    process.env.REACT_APP_API_URL +
    "/applications/timelines/distincts/exercises?acad_yr=" +
    params?.acad_yr;

  if (params?.acad_yr == null) {
    url =
      process.env.REACT_APP_API_URL +
      "/applications/timelines/distincts/exercises";
  }

  return apiCall(url, {}, "GET");
}

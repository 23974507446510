import { apexAxiosCall, apiCall } from "@/utils/apiCall";

export async function getAdmNo(ACAD_YR, EXERCISE) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications?acad_yr=${ACAD_YR}&exercise=${EXERCISE}`,
    {},
    "GET"
  );
}

export async function getApplication(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  VERIFY_STATUS,
  ORDERBYFIELD = "",
  IS_CHECKED_NOT_ENROLLED = false,
  page = 1,
  limit = 20,
  orderDirection = 'ASC'
) {
  let URL = `${process.env.REACT_APP_API_URL}/applications?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}&isAnalytic=true&verify_status=${VERIFY_STATUS}&page=${page}&limit=${limit}`;

  if (ORDERBYFIELD !== "") {
    URL = `${process.env.REACT_APP_API_URL}/applications?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}&isAnalytic=true&verify_status=${VERIFY_STATUS}&orderByField=${ORDERBYFIELD}&orderByDirection=${orderDirection}&page=${page}&limit=20`;
  }

  URL += "&appln_status_not_equals=D";
  if (IS_CHECKED_NOT_ENROLLED) {
  }

  return apiCall(URL, {}, "GET");
}

export async function postStudentList(student_ids, is_not_enrolled = false, sort = "id_no") {
  let studStatus = "E";
  if (is_not_enrolled) {
    studStatus = "";
  }

  return apexAxiosCall(
    process.env.REACT_APP_NYP_SIMS_API_URL + "/resources/sp/getStudentList",
    {
      student_id: student_ids,
      studstatus: studStatus,
      course_tag: "Y",
      transfer_tag: "Y",
      page: 1,
      limit: 20,
      search: "",
      sort: sort,
    }
  );
}

export async function patchApplication(applicationData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    {
      data: Array.isArray(applicationData)
        ? applicationData
        : [applicationData],
    },
    "PATCH"
  );
}

export async function deleteApplication(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "DELETE"
  );
}

import { apiCall } from "@/utils/apiCall";

// {
//     "AWARD_TYPE": "MMM",
//     "AWARD": "singaporeI",
//     "QUANTUM": 20000
// }

export async function getAwardList(limit = 20, page = 1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details?limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export async function getAwardListWithParams(awardType, award) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details?award_type=${awardType}&award=${award}`,
    {},
    "GET"
  );
}

export function postAward(awardData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details`,
    awardData,
    "POST"
  );
}

export function putAward(awardID, awardData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details/${awardID}`,
    awardData,
    "PUT"
  );
}

export function deleteAward(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/awards/details/${selectedId}`,
    {},
    "DELETE"
  );
}

import { apiCall } from "@/utils/apiCall";

export async function getListAcadYears() {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/timelines/distincts/acadyears`,
    {},
    "GET"
  );
}

export async function getListExercise(year) {
  let url =
    process.env.REACT_APP_API_URL +
    "/applications/timelines/distincts/exercises?acad_yr=" +
    year;

  if (year == null) {
    url =
      process.env.REACT_APP_API_URL +
      "/applications/timelines/distincts/exercises";
  }
  return apiCall(url, {}, "GET");
}

import React, {useEffect, useState} from "react";

import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getCodeList} from "@/apis/preparation/setup-dialect-etc/api";
import {LivingAllowance} from "@/apis/app-records/maintenance/status";

const statusOptions = [
    {
        label: "Living in the same household",
        value: "L",
    },
    {
        label: "Not living in the same household",
        value: "NL",
    },
    {
        label: "Deceased",
        value: "DE",
    },
    {
        label: "Divorced. Living with applicant",
        value: "DL",
    },
    {
        label: "Divorced. Not living with applicant",
        value: "DN",
    },
    {
        label: "In-Jail",
        value: "IJ",
    },
];

function Sibling({
                     listSibling,
                     setListSibling,
                     data,
                     studentDetail,
                     setShowFormulaModal,
                     isDisabled,
                     handleSiblingChange,
                     statusOccupationList,
                     getTotalSumIncome,
                     handleDeleteSiblings,
                     isReadOnly = false,
                 }) {
    const handleAddNewSibling = () => {
        // setAddNewSiblingCount([...siblingCount, 1]);
        setListSibling([
            ...listSibling,
            {
                acad_yr: data?.acad_yr,
                exercise: data?.exercise,
                adm_no: data?.adm_no,
                name: "",
                birthdate: "",
                age: 0,
                faas_occupations: "",
                gross_mthly_income: "",
                id_no: "",
                last_upd_id: sessionStorage.getItem("role")
                    ? sessionStorage.getItem("role")
                    : process.env.REACT_APP_LAST_UPD_ID,
                marital_status: "SINGLE",
                relationship: "P",
                remark: "",
                occupations: "",
                isIncomecontribute: "Y",
                isRemarkRequired: "Y",
            },
        ]);
    };

    const handleRemoveSiblingCount = (index) => {
        const siblingDetail = listSibling[index];
        handleDeleteSiblings(siblingDetail?.id);
        const updatedSiblingCount = listSibling.filter((_, i) => i !== index);
        setListSibling(updatedSiblingCount);
    };

    const [maritalList, setMaritalList] = useState([])
    const [relationshipList, setRelationshipList] = useState([])

    const handleGetMaritalStatus = () => {
        getCodeList("MS", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            label: `${item?.description}`,
                        });
                    });
                    setMaritalList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleGetRelationshipStatus = () => {
        getCodeList("R", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            label: `${item?.description}`,
                        });
                    });
                    setRelationshipList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        handleGetMaritalStatus()
        handleGetRelationshipStatus()
    }, [])

    useEffect(() => {
    }, [listSibling])

    return (
        <React.Fragment>
            {isReadOnly ? (
                ""
            ) : (
                <div>
                    <Button
                        type="button"
                        variant="btn btn-link ps-0 text-decoration-none"
                        onClick={() => handleAddNewSibling()}
                    >
                        <i class="bi bi-plus-circle"></i> {"  "} Add sibling
                    </Button>
                </div>
            )}
            {listSibling.map((sibling, index) => {
                return (
                    <div className="mt-3" key={index}>
                        <div>
                            <h5>
                                #{index + 1} Sibling / Relatives
                                {isReadOnly ? (
                                    ""
                                ) : (
                                    <button
                                        className={`btn-clear`}
                                        type={"button"}
                                        disabled={isDisabled}
                                        onClick={() => {
                                            if (
                                                confirm(
                                                    `Are you sure want to delete ${sibling?.name}!`
                                                ) === true
                                            ) {
                                                handleRemoveSiblingCount(index);
                                            }
                                        }}
                                    >
                                        <i className={`bi bi-trash`}></i>
                                    </button>
                                )}
                            </h5>
                        </div>
                        <Row>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={isReadOnly ? true : false}
                                        value={sibling?.name}
                                        onChange={(e) => {
                                            handleSiblingChange("name", e.target.value, index);
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>NRIC / Passport No</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={isReadOnly ? true : false}
                                        value={sibling?.id_no}
                                        onChange={(e) =>
                                            handleSiblingChange("id_no", e.target.value, index)
                                        }
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label>Birth date</Form.Label>
                                    <DatePicker
                                        className={`form-control`}
                                        selected={sibling?.birthdate}
                                        onChange={(date) => {
                                            if (date == null) {
                                                handleSiblingChange("birthdate", "", index);
                                                handleSiblingChange("age", 0, index);
                                            } else {
                                                const choosenDate = moment(date).format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                );

                                                handleSiblingChange("birthdate", choosenDate, index);
                                                handleSiblingChange(
                                                    "age",
                                                    moment().diff(choosenDate, "years"),
                                                    index
                                                );
                                            }
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        maxDate={new Date()}
                                        disabled={isReadOnly ? true : false}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Age</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={true}
                                        value={sibling?.age}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Relationship</Form.Label>
                                    <Select options={relationshipList} isDisabled={isDisabled}
                                            value={
                                                {
                                                    label: relationshipList.find(
                                                        (item) => item.value === sibling?.relationship
                                                    )?.label || "-",
                                                    value: sibling?.relationship
                                                }
                                            }
                                            onChange={(selectedOption) => {
                                                handleSiblingChange(
                                                    "relationship",
                                                    selectedOption.value,
                                                    index
                                                );
                                            }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Marital Status</Form.Label>
                                    <Select options={maritalList} isDisabled={isDisabled}
                                            value={
                                                {
                                                    label: maritalList.find(
                                                        (item) => item.value === sibling?.marital_status
                                                    )?.label || "-",
                                                    value: sibling?.marital_status
                                                }
                                                
                                            }
                                            onChange={(selectedOption) => {
                                                handleSiblingChange(
                                                    "marital_status",
                                                    selectedOption.value,
                                                    index
                                                );
                                            }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="">
                                <Form.Group>
                                    <Form.Label>Occupation</Form.Label>
                                    <Select
                                        options={statusOccupationList}
                                        isDisabled={isReadOnly ? true : false}
                                        value={sibling?.occupations}
                                        onChange={(selectedOption) => {
                                            handleSiblingChange(
                                                "faas_occupations",
                                                selectedOption.value,
                                                index
                                            );

                                            handleSiblingChange(
                                                "occupations",
                                                {
                                                    label: selectedOption.label,
                                                    value: selectedOption.value,
                                                    isIncomeContribute:
                                                    selectedOption.is_income_contribute,
                                                    isRemarkRequired: selectedOption.is_remark_required,
                                                },
                                                index
                                            );

                                            if (selectedOption.is_income_contribute !== "Y") {
                                                handleSiblingChange("gross_mthly_income", 0, index);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {/*
              <Col xs={6} className="">
                <Form.Group>
                  <Form.Label>Other Income</Form.Label>
                  <Form.Control
                    type={`text`}
                    disabled={isDisabled}
                    value={listSibling[currentIndex]?.OTHER_INCOME}
                    onChange={(e) => {
                      handleSiblingChange(
                        "OTHER_INCOME",
                        e.target.value,
                        currentIndex
                      );
                    }}
                  ></Form.Control>
                </Form.Group>
              </Col>

              */}
                            <Col xs={12} className="">
                                <Form.Group>
                                    <Form.Label>Gross Monthly Income</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={
                                            isReadOnly ? true :
                                                sibling?.occupations?.isIncomeContribute !== "Y"
                                        }
                                        value={sibling?.gross_mthly_income}
                                        onChange={(e) => {
                                            handleSiblingChange(
                                                "gross_mthly_income",
                                                e.target.value,
                                                index
                                            );
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col xs={12} className="">
                                <Form.Group>
                                    <Form.Label>Remark</Form.Label>
                                    <Form.Control
                                        type={`text`}
                                        disabled={
                                            isReadOnly ? true :
                                                sibling?.occupations?.isRemarkRequired !== "Y"
                                        }
                                        value={sibling?.remark}
                                        onChange={(e) => {
                                            handleSiblingChange("remark", e.target.value, index);
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                );
            })}
        </React.Fragment>
    );
}

export default Sibling;

import {Accordion, Button, Col, Form, Row,} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {ApplicationStatus, DataFlagStatus, VerificationStatus,} from "@/apis/app-records/maintenance/status";

import {getCodeTypeSasList} from "@/apis/sims/api";
import moment from "moment/moment";

const Application = ({
                         applicationDetail,
                         setApplicationDetail,
                         studentDetail,
                         selectedListExercise,
                         selectedListAdmNo,
                         options,
                         isDisabled,
                         handleClickEdit,
                         academicYear,
                         handleAcademicYearChange,
                         maritalStatus,
                         setMaritalStatus,
                         dialectGroup,
                         setDialectGroup,
                         housingType,
                         setHousingType,
                         handleChange,
                         listMTCRequest,
                         selectedAwardType,
                         selectedAwardCode,
                         setSelectedAwardType,
                         setSelectedAwardCode,
                         setShowTransferToAnotherExerciseModal,
                         nypRemarks,
                         paRemarks,
                         activeKeys
                     }) => {
    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardCodeList, setAwardCodeList] = useState([]);
    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];
    const [selectedNYPOption, setSelectedNYPOption] = useState({
        label: applicationDetail?.remark_desc,
        value: applicationDetail?.remark_desc,
    });
    const [selectedPAOption, setSelectedPAOption] = useState({
        label: applicationDetail?.approval_status_reason,
        value: applicationDetail?.approval_status_reason,
    });

    useEffect(() => {
        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.codetype,
                    code: content.code,
                });

                if (applicationDetail?.rec_award_type == content.codetype) {
                    setSelectedAwardType({
                        label: content.description,
                        value: content.codetype,
                        code: content.code,
                    });
                }
            });
            setAwardTypeList(contentArray);
        });

        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                });
                if (applicationDetail?.rec_award_code == content.code) {
                    setSelectedAwardCode({
                        label: content.description,
                        value: content.code,
                    });
                }
            });
            setAwardCodeList(contentArray);
        });
    }, []);

    const onChangeAwardType = () => {
        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                });
            });
            setAwardCodeList(contentArray);
        });
    };

    useEffect(() => {
        onChangeAwardType();
    }, [selectedAwardType]);

    return (
        <>
            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Application
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Academic Year</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.acad_yr}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Exercise</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.exercise}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Ack No.</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.adm_no}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Latest File Upload Date / Time</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={studentDetail?.dateUploaded}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col
                                        xs={12}
                                        className="mt-4"
                                        onClick={() => {
                                            setShowTransferToAnotherExerciseModal(true);
                                        }}
                                    >
                                        <Button variant="primary">
                                            Transfer to another exercise
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion activeKey={activeKeys}>
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Application Verification & Status
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>Application Date</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.appln_date ? moment(applicationDetail?.appln_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>App. Status Update Date</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.updated_at ? moment(applicationDetail?.updated_at).format("YYYY-MM-DD HH:mm:ss") : ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4} className="">
                                        <Form.Group>
                                            <Form.Label>First Verified by Date/Time</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={applicationDetail?.verify_status_date}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Application Status</Form.Label>
                                            <Select
                                                options={ApplicationStatus()}
                                                isDisabled={isDisabled}
                                                value={ApplicationStatus().find(option => option.value === applicationDetail?.appln_status)}
                                                // value={{
                                                //     value: applicationDetail?.APPLN_STATUS,
                                                //     label: applicationDetail?.APPLN_STATUS,
                                                // }}
                                                onChange={(selectedOption) => {
                                                    handleChange("appln_status", selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Verification Status</Form.Label>
                                            <Select
                                                options={VerificationStatus()}
                                                isDisabled={isDisabled}
                                                value={VerificationStatus().find(option => option.value === applicationDetail?.verify_status)}
                                                // value={{
                                                //     value: applicationDetail?.VERIFY_STATUS,
                                                //     label: applicationDetail?.VERIFY_STATUS,
                                                // }}
                                                onChange={(selectedOption) => {
                                                    handleChange("verify_status", selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Reason for Change</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={isDisabled}
                                                value={applicationDetail?.reason_for_changes}
                                                onChange={(e) => {
                                                    handleChange("reason_for_changes", e.target.value);
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Data Flag</Form.Label>
                                            <Select
                                                options={DataFlagStatus()}
                                                isDisabled={isDisabled}
                                                value={DataFlagStatus().find(option => option.value === applicationDetail?.data_flag)}
                                                // value={{
                                                //     value: applicationDetail?.DATA_FLAG,
                                                //     label: applicationDetail?.DATA_FLAG,
                                                // }}
                                                onChange={(selectedOption) => {
                                                    handleChange("data_flag", selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Approval Status Remarks (PA)</Form.Label>
                                            <Select
                                                options={paRemarks}
                                                value={selectedPAOption}
                                                onChange={(option) => {
                                                    setSelectedPAOption(option);
                                                    handleChange("approval_status_reason", option.value);
                                                }}
                                                isDisabled={
                                                    roleList.includes("FAAS_ADM") ? isDisabled : true
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Approval Status Remarks (NYP)</Form.Label>
                                            <Select
                                                options={nypRemarks}
                                                value={selectedNYPOption}
                                                onChange={(option) => {
                                                    setSelectedNYPOption(option);
                                                    handleChange("remark_desc", option.value);
                                                }}
                                                isDisabled={
                                                    roleList.includes("FAAS_ADM") ? isDisabled : true
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="">
                                        <Form.Group>
                                            <Form.Label>Special Details</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={isDisabled}
                                                value={applicationDetail?.special_details}
                                                onChange={(e) => {
                                                    handleChange("special_details", e.target.value);
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="">
                                        <Form.Group>
                                            <Form.Label>Approval Status Special Remarks</Form.Label>
                                            <Form.Control
                                                as={`textarea`}
                                                disabled={isDisabled}
                                                value={applicationDetail?.special_note}
                                                onChange={(e) => {
                                                    handleChange("special_note", e.target.value);
                                                }}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion activeKey={activeKeys}>
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Recommendation Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Recommend Award Type</Form.Label>
                                            <Select
                                                options={awardTypeList}
                                                isDisabled={isDisabled}
                                                value={selectedAwardType}
                                                onChange={(selectedOption) => {
                                                    setSelectedAwardType({
                                                        label: selectedOption.label,
                                                        value: selectedOption.value,
                                                        code: selectedOption.code,
                                                    });
                                                    handleChange("rec_award_type", selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Recommend Award</Form.Label>
                                            <Select
                                                options={awardCodeList}
                                                isDisabled={isDisabled}
                                                value={selectedAwardCode}
                                                onChange={(selectedOption) => {
                                                    setSelectedAwardCode({
                                                        label: selectedOption.label,
                                                        value: selectedOption.value,
                                                    });
                                                    handleChange("rec_award_code", selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion activeKey={activeKeys}>
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Award Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Award Type</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={selectedAwardType.label}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Award</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={selectedAwardCode.label}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Year Awarded</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={academicYear}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} className="">
                                        <Form.Group>
                                            <Form.Label>Amount Awarded</Form.Label>
                                            <Form.Control
                                                type={`text`}
                                                disabled={true}
                                                value={"0"}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="">
                <Accordion activeKey={activeKeys}>
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Mean Test Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col xs={6} className="">
                                    <Form.Group>
                                        <Form.Label>Request for Mean Test</Form.Label>
                                        <Select
                                            options={[
                                                {label: "Y", value: "Y"},
                                                {label: "N", value: ""},
                                            ]}
                                            isDisabled={isDisabled}
                                            value={{
                                                value: applicationDetail?.mtc_appeal_remark,
                                                label: applicationDetail?.mtc_appeal_remark,
                                            }}
                                            onChange={(selectedOption) => {
                                                handleChange("mtc_appeal_remark", selectedOption.value);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6} className="">
                                    <Form.Group>
                                        <Form.Label>Mean Test Date</Form.Label>
                                        <Form.Control
                                            type={`text`}
                                            disabled={true}
                                            value={academicYear}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={6} className="">
                                    <Form.Group>
                                        <Form.Label>Request Status</Form.Label>
                                        <Form.Control
                                            type={`text`}
                                            disabled={true}
                                            value={"-"}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default Application;

import React, {useCallback, useEffect, useState} from "react";
import Layout from "@/components/Layout";
import {useDropzone} from "react-dropzone";
import {Button} from "@govtechsg/sgds-react";
import Select from "react-select";
import * as XLSX from "xlsx";
import {getCodeTypeSasList, saveStudentFinassists} from "@/apis/sims/api";
import {postUploadErrorLogs} from "@/apis/upload-error-logs/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";
import CustomModal from "@/components/CustomModal";

export default function Batch() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [retrieve, setRetrieve] = useState(false);
    const [awardTypeList, setAwardTypeList] = useState([]);
    const [selectedAwardType, setSelectedAwardType] = useState(null);
    const [awardList, setAwardList] = useState([]);
    const [selectedAward, setSelectedAward] = useState(null);
    const [inputStudentFinassists, setinputStudentFinassists] = useState([]);
    const [response, setResponse] = useState(null);
    const [showModalConfirmation, setShowModalConfirmation] = useState(false);
    const [errorReportDownload, setErrorReportDownload] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, {type: "array"});

                const sheet = workbook.Sheets[workbook.SheetNames[0]];

                const jsonData = XLSX.utils.sheet_to_json(sheet);
                setinputStudentFinassists(jsonData);
            };
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const {acceptedFiles, fileRejections, getRootProps, getInputProps} =
        useDropzone({
            maxFiles: 1,
            onDrop,
            accept: {
                "application/vnd.ms-excel": [],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
                "text/csv": [],
                "application/csv": [],
            },
        });

    const handleGetListAwardType = () => {
        getCodeTypeSasList("AWARDTYPECODE").then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardTypeList(
                    data.map((item) => {
                        return {
                            label: item.description,
                            value: item.code,
                            id: item.codetype,
                        };
                    })
                );
            }
        });
    };

    const handleGetListAward = (code) => {
        getCodeTypeSasList(code).then((res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let data = res?.data?.data || [];
                setAwardList(
                    data.map((item) => {
                        return {label: item.description, value: item.code};
                    })
                );
            }
        });
    };

    const downloadTemplate = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        sheet1.push(["adm_no", "year_awarded", "amount_awarded", "award_code"]);

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        //Export
        XLSX.writeFile(wb, "import_template.xlsx");
    };

    const createErrorExcelTemplate = () => {
        const wb = XLSX.utils.book_new();

        const ws = XLSX.utils.aoa_to_sheet(errorReportDownload);
        XLSX.utils.book_append_sheet(wb, ws, "Template");
        XLSX.writeFile(wb, "error_import_template.xlsx");
    };

    const handleSave = async () => {
        saveStudentFinassists(
            inputStudentFinassists.map((item) => {
                const {amount_awarded, award_code, year_awarded, ..._item} = item;
                return {
                    ..._item,
                    year_awarded: String(year_awarded),
                    award: award_code,
                    amt_awarded: String(amount_awarded),
                    award_type: selectedAwardType.value,
                    action: "I",
                    last_upd_id: sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID,
                };
            })
        ).then(async (res) => {
            if (res?.response?.data?.message) {
                if (
                    res?.response?.data?.message?.length > 0 &&
                    Array.isArray(res?.response?.data?.message)
                ) {
                    res?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(res?.response?.data?.message);
                }
            } else if (res?.response?.status === 404) {
                toast.error("Data not found");
            } else {
                let status = res?.data?.status;
                let details = res?.data?.details;

                console.log(res?.data)
                setResponse({
                    status,
                    total_success: details?.filter((item) => item?.statuscode == 0)
                        ?.length,
                    total_error: details?.filter((item) => item?.statuscode !== 0)?.length,
                });

                let tempVar = [];
                tempVar.push(["adm_no", "award_code", "year", "quantum", "reason"]);
                details?.map((content, index) => {
                    const datas = content?.data?.split("|");

                    const errData = [
                        datas[0],
                        datas[1],
                        datas[3],
                        datas[4],
                        content?.error,
                    ];
                    tempVar.push(errData);
                });

                setErrorReportDownload(tempVar);

                const response = await postUploadErrorLogs({
                    topic: "scholarship",
                    description: "upload scholarship",
                    error_data: {
                        total_records: inputStudentFinassists?.length,
                        error_records: details?.filter((item) => item?.statuscode != 0)
                            .length,
                        success_records: details?.filter((item) => item?.statuscode == 0)
                            .length,
                    },
                    raw_data: inputStudentFinassists?.map((item) => {
                        const {amount_awarded, award_code, ..._item} = item;
                        return {
                            ..._item,
                            award: award_code,
                            amt_awarded: amount_awarded,
                            award_type: selectedAwardType.value,
                            last_upd_id: sessionStorage.getItem("staffId")
                                ? sessionStorage.getItem("staffId")
                                : process.env.REACT_APP_LAST_UPD_ID,
                        };
                    }),
                    user_id: sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID,
                });

                setShowModalConfirmation(false);
            }
        });
    };

    useEffect(function () {
        handleGetListAwardType();
    }, []);

    return (
        <Layout currentNav={"utilities"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Utilities</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Update Student Scholarship Detail
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Update Student Scholarship Detail</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="row">
                    <div className="col-md-10">
                        <Select
                            placeholder="Award Type"
                            options={awardTypeList}
                            value={selectedAwardType}
                            onChange={(e) => {
                                // handleGetListAward(e.value);
                                setSelectedAwardType(e);
                                setSelectedAward(null);
                                setRetrieve(false);
                                setinputStudentFinassists([]);
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <Button
                            type="button"
                            className="btn btn-primary w-100"
                            disabled={selectedAwardType == null ? true : false}
                            onClick={() => {
                                if (selectedAwardType != null) {
                                    setRetrieve(true);
                                    setSelectedFile(null);
                                    setResponse(null);
                                    acceptedFiles?.pop();
                                }
                            }}
                        >
                            Retrieve
                        </Button>
                    </div>
                </div>
                {retrieve && (
                    <div className={`p-3 bg-white shadow-sm`}>
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className={`text-blue`}>
                                    Upload Student Scholarship Detail
                                </h4>
                            </div>
                            <div className="col-md-6 text-end">
                                <Button variant={"primary"} onClick={downloadTemplate}>
                                    Download Template
                                </Button>
                            </div>
                        </div>

                        <div className="mx-8 my-3">
                            <div {...getRootProps({className: "drop"})}>
                                <input {...getInputProps()} />
                                <i
                                    className={`bi bi-cloud-upload`}
                                    style={{fontSize: "30px"}}
                                ></i>
                                <div>Drag and drop or choose a file to upload</div>

                                <div
                                    className={`btn btn-primary mt-3 d-flex gap-2 align-items-center`}
                                >
                                    <i
                                        className={`bi bi-upload`}
                                        style={{fontSize: "20px"}}
                                    ></i>
                                    Choose a File
                                </div>
                            </div>

                            <div className={`text-muted fst-italic mt-3`}>
                                File content should contains Adm No, Amount Awarded, Year
                                Awarded
                            </div>
                            {fileRejections.map(({file, errors}) => {
                                return (
                                    <li key={file.path}>
                                        {file.path} - {file.size} bytes
                                        <ul>
                                            {errors.map((e) => (
                                                <li key={e.code}>
                                                    {" "}
                                                    Incorrect file format uploaded. Please upload a
                                                    XLS/XLSX file with the correct table format.
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                );
                            })}

                            {acceptedFiles.length > 0 && (
                                <>
                                    <div className="d-flex gap-2 align-items-center">
                                        <i className={`bi bi-check-lg text-green-500`}></i>
                                        {acceptedFiles[0].path}
                                        <button className={`btn-clear`}>
                                            <i className={`bi bi-x-circle text-red-500`}></i>
                                        </button>
                                    </div>

                                    <div className="row my-3 p-2 bg-yellow-100">
                                        <div className="col-12">
                                            <h6>Uploaded detail</h6>
                                        </div>
                                        <div className="col-3">Total Records in Input File</div>
                                        <div className="col-9 fw-bold">
                                            : {inputStudentFinassists.length}
                                        </div>
                                        {/* <div className="col-3">Non-enrolled Status Records</div>
                    <div className="col-9 fw-bold">: 5</div> */}
                                        {response !== null && (
                                            <>
                                                <div className="col-3">Error Records</div>
                                                <div className="col-9 fw-bold">
                                                    : {response.total_error}
                                                </div>
                                                <div className="col-3">
                                                    Successfully updated Records
                                                </div>
                                                <div className="col-9 fw-bold">
                                                    : {response.total_success}
                                                </div>
                                                <div>
                                                    <a
                                                        href="#"
                                                        onClick={() => {
                                                            createErrorExcelTemplate();
                                                        }}
                                                    >
                                                        Download File
                                                    </a>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    
                                        <div className="d-flex gap-3">
                                            <Button
                                                variant="outline-dark"
                                                onClick={() => setSelectedFile(null)}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={() => setShowModalConfirmation(true)}
                                            >
                                                Append
                                            </Button>
                                        </div>
                                    
                                </>
                            )}
                        </div>
                    </div>
                )}

                <CustomModal
                    show={showModalConfirmation}
                    handleClose={() => setShowModalConfirmation(false)}
                    title="Upload new record"
                    description={
                        "Uploading a new record will replace the existing record. Do you want to proceed?"
                    }
                    action={handleSave}
                    submitButtonText={"Proceed"}
                />
            </sgds-content-body>
        </Layout>
    );
}

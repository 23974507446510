import { apiCall } from "@/utils/apiCall";

/*

Code Type:
* DG = Dialect
* HT = Housing Type
* MS = Marital Status
* RS = RelationShip
* */

// {
//     "CODE_TYPE": "DG",
//     "CODE": "HA",
//     "DESCRIPTION": "hakka",
//     "CONTENT1": null,
//     "INFO1": null,
//     "INFO2": null
// }

export async function getCodeList(codeType, limit = 20, page=1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes?code_type=${codeType}&limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export function postCode(CodeData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes`,
    CodeData,
    "POST"
  );
}

export function putCode(CodeID, CodeData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes/${CodeID}`,
    CodeData,
    "PUT"
  );
}

export function deleteCode(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes/${selectedId}`,
    {},
    "DELETE"
  );
}

export function postOverwriteCode(CodeData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes/overwrite`,
    CodeData,
    "POST"
  );
}

export function postAppendCode(CodeData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/codes/append`,
    CodeData,
    "POST"
  );
}

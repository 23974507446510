import Layout from "@/components/Layout";
import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {
    deleteTimeline,
    deleteTimelineADM,
    deleteTimelineDT,
    getStudentList,
    getTimeline,
    getTimelineADM,
    getTimelineDT,
    postCheckOverlapTimeline,
    postTimeline,
} from "@/apis/preparation/setup-timeline/api";

import moment from "moment";
import {toast} from "react-toastify";
import Select from "react-select";
import {postStudentList} from "@/apis/app-records/verification/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import CustomModal from "@/components/CustomModal";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import CreatableSelect from "react-select/creatable";
import * as XLSX from "xlsx";
import {sortExerciseList} from "@/components/utils/utils";
import UploadModal from "@/components/UploadModal";

export default function SetupTimeline() {
    const [academicYear, setAcademicYear] = useState();

    const [exercise, setExercise] = useState(null);

    const [id, setId] = useState("");

    const [isRetrieved, setIsRetrieved] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [dataAcademyYear, setDataAcademyYear] = useState([]);

    const [dataExercise, setDataExercise] = useState([]);

    const [selectedAcadYearOption, setSelectedAcadYearOption] = useState();

    const [selectedExerciseOption, setSelectedExerciseOption] = useState(null);

    const [initialStudentOptions, setInitialStudentOptions] = useState([]);

    const [showUpload, setShowUpload] = useState(false);

    const [data, setData] = useState({
        id: "",
        acad_yr: "",
        exercise: "",
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        vstatus_end_date: null,
        multiple_award_per_ay: null,
        fasop_tag: null,
        allow_sponsor_tag: null,
        homes_tag: null,
        sgcitizen_tag: null,
        sgpr_tag: null,
        nonsg_pr_tag: null,
        cutoff_income: "",
        mpci_income: "",
        remark: "",
        adm_tag: "",
        timeline_dt: [
            {
                acad_yr: "",
                exercise: "",
                course_code: "",
            },
        ],
        timeline_adm: [],
    });

    const [courses, setCourses] = useState([
        {
            acad_yr: academicYear,
            exercise: exercise,
            course_code: "",
            options: [],
        },
    ]);

    const [students, setStudents] = useState([
        {
            acad_yr: academicYear,
            exercise: exercise,
            adm_no: "",
            options: initialStudentOptions,
        },
    ]);

    const [editable, setEditable] = useState({
        applicationDisabled: true,
        courseDisabled: true,
        eligibleDisable: true,
        applicantDisable: true,
        cutoffDisable: true,
        remarksDisable: true,
    });

    const [selectedFile, setSelectedFile] = useState(null);

    const onChangeFile = (file) => {
        setSelectedFile(file);
        setShowUpload(false)
    };

    const handleEdit = (name) => {
        setEditable((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const handleChange = (field, value) => {
        setData({
            ...data,
            [field]: value,
        });
    };

    const handleGetListAcademyYear = async () => {
        try {
            setAcademicYear(null);

            const response = await getAcademyYear();

            setDataAcademyYear(
                response?.data?.data?.map((item) => ({
                    label: item?.acad_yr,
                    value: item?.acad_yr,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academicYear,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleSubmit = () => {
        if (!academicYear || !exercise) {
            toast.error(
                "Please enter the academic year and exercise before proceeding!"
            );
            return false;
        }

        if (!data?.start_date || !data?.end_date || !academicYear || !exercise) {
            toast.error("Please fill up the required fields");
            return false;
        }

        // if (courses.some((course) => course.course_code === "")) {
        //     toast.error("Please fill up the required fields");
        //     return false;
        // }

        if (data.adm_tag === "E" || data.adm_tag === "A") {
            if (students.some((student) => student.adm_no === "")) {
                toast.error("Please fill up the required fields");
                return false;
            }
        }

        let mergedData = {};

        if (id) {
            mergedData = {
                id: data?.id,
                acad_yr: academicYear,
                adm_tag: data?.adm_tag,
                exercise: exercise,
                start_date: moment(data?.start_date).format("YYYY-MM-DD hh:mm:ss"),
                start_time: data?.start_time,
                end_date: data?.end_date
                    ? moment(data?.end_date).format("YYYY-MM-DD hh:mm:ss")
                    : null,
                end_time: data?.end_time,
                vstatus_end_date: data?.vstatus_end_date
                    ? moment(data?.vstatus_end_date).format("YYYY-MM-DD")
                    : null,
                multiple_award_per_ay: data?.multiple_award_per_ay,
                fasop_tag: data?.fasop_tag,
                allow_sponsor_tag: data?.allow_sponsor_tag,
                homes_tag: data?.homes_tag,
                sgcitizen_tag: data?.sgcitizen_tag,
                sgpr_tag: data?.sgpr_tag,
                nonsg_pr_tag: data?.nonsg_pr_tag,
                cutoff_income: data?.cutoff_income,
                mpci_income: data?.mpci_income,
                remark: data?.remark,
                timeline_dt: [],
                timeline_adm: []
            };

            if (courses?.length > 0) {
                mergedData = {
                    ...mergedData,
                    timeline_dt: courses.map((course) => ({
                        ...course,
                        acad_yr: academicYear,
                        exercise: exercise,
                    })),
                }
            }
            if (data?.adm_tag !== null && students?.length > 0) {
                mergedData = {
                    ...mergedData,
                    timeline_adm: students.map((student) => ({
                        ...student,
                        acad_yr: academicYear,
                        exercise: exercise,
                    })),
                }
            }
        } else {
            mergedData = {
                acad_yr: academicYear,
                adm_tag: data?.adm_tag,
                exercise: exercise,
                start_date: moment(data?.start_date).format("YYYY-MM-DD hh:mm:ss"),
                start_time: data?.start_time,
                end_date: moment(data?.end_date).format("YYYY-MM-DD hh:mm:ss"),
                end_time: data?.end_time,
                vstatus_end_date: moment(data?.vstatus_end_date).format("YYYY-MM-DD"),
                multiple_award_per_ay: data?.multiple_award_per_ay,
                fasop_tag: data?.fasop_tag,
                allow_sponsor_tag: data?.allow_sponsor_tag,
                homes_tag: data?.homes_tag,
                sgcitizen_tag: data?.sgcitizen_tag,
                sgpr_tag: data?.sgpr_tag,
                nonsg_pr_tag: data?.nonsg_pr_tag,
                cutoff_income: data?.cutoff_income,
                mpci_income: data?.mpci_income,
                remark: data?.remark,
                timeline_dt: [],
                timeline_adm: []
            };

            if (courses?.length > 0) {
                mergedData = {
                    ...mergedData,
                    timeline_dt: courses.map((course) => ({
                        ...course,
                        acad_yr: academicYear,
                        exercise: exercise,
                    })),
                }
            }
            if (data?.adm_tag !== null && students?.length > 0) {
                mergedData = {
                    ...mergedData,
                    timeline_adm: students.map((student) => ({
                        ...student,
                        acad_yr: academicYear,
                        exercise: exercise,
                    })),
                }
            }
        }

        postTimeline(mergedData)
            .then((response) => {
                if (response?.response?.data?.message) {
                    if (
                        response?.response?.data?.message?.length > 0 &&
                        Array.isArray(response?.response?.data?.message)
                    ) {
                        response?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(response?.response?.data?.message);
                    }
                } else if (response?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success(`Changes saved successfully`);
                    handleEdit("applicationDisabled");
                    handleGetTimeline();
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    const handleGetTimeline = () => {
        getTimeline(academicYear, exercise).then((response) => {
            if (response?.data?.data?.length > 0) {
                setData(response?.data?.data[0]);
                setId(response?.data?.data[0].id);
            } else {
                setId("");

                setCourses([
                    {
                        acad_yr: academicYear,
                        exercise: exercise,
                        course_code: "",
                        options: [],
                    },
                ]);

                setStudents([
                    {
                        acad_yr: academicYear,
                        exercise: exercise,
                        adm_no: "",
                        options: initialStudentOptions,
                    },
                ]);

                setData({
                    acad_yr: "",
                    exercise: "",
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null,
                    vstatus_end_date: null,
                    multiple_award_per_ay: null,
                    fasop_tag: null,
                    allow_sponsor_tag: null,
                    homes_tag: null,
                    sgcitizen_tag: null,
                    sgpr_tag: null,
                    nonsg_pr_tag: null,
                    cutoff_income: "",
                    mpci_income: "",
                    remark: "",
                    adm_tag: "",
                    timeline_dt: [
                        {
                            acad_yr: "",
                            exercise: "",
                            course_code: "",
                        },
                    ],
                    timeline_adm: [
                        {
                            acad_yr: "",
                            exercise: "",
                            adm_no: "",
                        },
                    ],
                });

                toast.warning("Data does not exist, this will create new one");
            }
        });

        getTimelineDT(academicYear, exercise, "").then((response) => {
            if (response?.data?.data?.length > 0) {
                setCourses(response?.data?.data);
            }
        });

        getTimelineADM(academicYear, exercise).then(async (response) => {
            if (response?.data?.data?.length > 0) {
                const modifiedItems = response?.data?.data?.map((item) => ({
                    ...item,
                    name: "",
                }));

                setStudents(modifiedItems);

                const itemsMap = modifiedItems.reduce((map, item) => {
                    map[item.adm_no] = item;
                    return map;
                }, {});

                const students_id = response?.data?.data
                    ?.map((application) => application.adm_no)
                    .join(",");

                if (students_id) {
                    postStudentList(students_id, true).then((response2) => {
                        response2?.data?.data?.forEach((student) => {
                            const item = itemsMap[student.admNo];
                            if (item) {
                                setStudents((prevItems) =>
                                    prevItems.map((prevItem) => {
                                        if (prevItem.adm_no === item.adm_no) {
                                            return {
                                                ...prevItem,
                                                name: student.name,
                                            };
                                        }
                                        return prevItem;
                                    })
                                );
                            }
                        });
                    });
                }
            }
        });
    };

    const handleRetrieveData = () => {
        if (academicYear && exercise) {
            handleGetTimeline();
        } else {
            setId("");
            setCourses([
                {
                    acad_yr: academicYear,
                    exercise: exercise,
                    course_code: "",
                    options: [],
                },
            ]);

            setStudents([
                {
                    acad_yr: academicYear,
                    exercise: exercise,
                    adm_no: "",
                    options: initialStudentOptions,
                },
            ]);
            setData({
                id: "",
                acad_yr: "",
                exercise: "",
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
                vstatus_end_date: null,
                multiple_award_per_ay: null,
                fasop_tag: null,
                allow_sponsor_tag: null,
                homes_tag: null,
                sgcitizen_tag: null,
                sgpr_tag: null,
                nonsg_pr_tag: null,
                cutoff_income: "",
                mpci_income: "",
                remark: "",
                adm_tag: "",
                timeline_dt: [
                    {
                        acad_yr: "",
                        exercise: "",
                        course_code: "",
                    },
                ],
                timeline_adm: [
                    {
                        acad_yr: "",
                        exercise: "",
                        adm_no: "",
                    },
                ],
            });

            toast.warning("Data does not exist, this will create new one");
        }

        setIsRetrieved(true);
    };

    const handleAddCourseRow = () => {
        const newCourse = {
            acad_yr: academicYear,
            exercise: exercise,
            course_code: "",
            options: [],
        };

        setCourses([...courses, newCourse]);
    };

    const handleChangeCourseInput = (input, index) => {
        setTimeout(() => {
            getTimelineDT(academicYear, exercise, input).then((response) => {
                if (response?.data?.data?.length > 0) {
                    const options = response?.data?.data?.map((course) => ({
                        value: course.course_code,
                        label: course.course_code,
                    }));

                    setCourses((prevCourses) => {
                        const updatedCourses = [...prevCourses];
                        updatedCourses[index].options = options;
                        return updatedCourses;
                    });
                }
            });
        }, 500);
    };

    const handleChangeCourseCode = (index, option) => {
        const updatedCourses = [...courses];
        updatedCourses[index].course_code = option;
        // updatedCourses[index].course_code = option.value;
        setCourses(updatedCourses);
    };

    const handleRemoveCourse = (index) => {
        const updatedCourses = courses.filter((course, i) => {
            if (i === index) {
                //remove
                deleteTimelineDT(course?.id).then((e) => {
                    toast.success("Course code has been removed");
                });
            }
            return i !== index;
        });
        setCourses(updatedCourses);
    };

    const handleCreateCourse = (inputValue, index) => {
        const newOption = createOption(inputValue);

        setCourses((prevCourses) => {
            const updatedCourses = [...prevCourses];
            updatedCourses[index].options = [
                ...(updatedCourses[index].options || []),
                newOption,
            ];
            updatedCourses[index].course_code = newOption.value;

            return updatedCourses;
        });
    };

    const handleAddStudentRow = () => {
        const newStudent = {
            acad_yr: academicYear,
            exercise: exercise,
            adm_no: "",
            options: initialStudentOptions,
        };

        setStudents([...students, newStudent]);
    };

    const handleChangeStudentInput = (input, index) => {
        if (input) {
            setTimeout(() => {
                getStudentList(input).then((response) => {
                    const options = response?.data?.data?.map((student) => ({
                        value: student.admNo,
                        label: student.admNo,
                        name: student.name,
                    }));

                    setStudents((prevStudents) => {
                        const updatedStudents = [...prevStudents];
                        updatedStudents[index].options = options;
                        return updatedStudents;
                    });
                });
            }, 500);
        }
    };

    const handleChangeStudent = (index, selectedOption) => {
        console.log(selectedOption);
        const updatedStudents = [...students];
        updatedStudents[index].adm_no = selectedOption.value;
        setStudents(updatedStudents);
    };

    const handleDelete = () => {
        if (!data.id) {
            toast.error("ID not found!");
            return;
        }

        deleteTimeline(data?.id)
            .then((response) => {
                if (response?.response?.data?.message) {
                    if (
                        response?.response?.data?.message?.length > 0 &&
                        Array.isArray(response?.response?.data?.message)
                    ) {
                        response?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(response?.response?.data?.message);
                    }
                } else if (response?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success("Record deleted successfully");

                    setData({
                        id: "",
                        acad_yr: "",
                        exercise: "",
                        start_date: "",
                        start_time: "",
                        end_date: "",
                        end_time: "",
                        vstatus_end_date: "",
                        multiple_award_per_ay: "",
                        fasop_tag: null,
                        allow_sponsor_tag: null,
                        homes_tag: null,
                        sgcitizen_tag: null,
                        sgpr_tag: null,
                        nonsg_pr_tag: null,
                        cutoff_income: null,
                        mpci_income: null,
                        remark: "",
                        adm_tag: null,
                        timeline_dt: [
                            {
                                acad_yr: "",
                                exercise: "",
                                course_code: "",
                            },
                        ],
                        timeline_adm: [
                            {
                                acad_yr: "",
                                exercise: "",
                                adm_no: "",
                            },
                        ],
                    });
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleRemoveStudent = (index) => {
        const updatedStudents = students.filter((student, i) => {
            if (i == index) {
                deleteTimelineADM(student?.id).then((e) => {
                    toast.success(
                        `Student ${student?.name} with ${student?.adm_no} has been removed`
                    );
                });
            }
            return i !== index;
        });
        setStudents(updatedStudents);
    };

    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ""),
    });

    const handleCreateAcadYear = (inputValue) => {
        const newOption = createOption(inputValue);
        setDataAcademyYear((prev) => [...prev, newOption]);
        setSelectedAcadYearOption(newOption);
        setAcademicYear(newOption.value);
    };

    const handleCreateExercise = (inputValue) => {
        const newOption = createOption(inputValue);
        setDataExercise((prev) => [...prev, newOption]);
        setSelectedExerciseOption(newOption);
        setExercise(newOption.value);
    };

    const convertTimeStringToDate = (timeString) => {
        if (!timeString || timeString.length !== 4) return "";

        const hours = parseInt(timeString.slice(0, 2), 10);
        const minutes = parseInt(timeString.slice(2), 10);

        const now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hours,
            minutes
        );
    };

    const handleDownloadTemplate = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        sheet1.push(["adm_no"]);
        sheet1.push(["141922A"]);

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "import_student_adm_no_application_timeline.xlsx");

    }

    const handleCheckOverlapTimeline = () => {
        postCheckOverlapTimeline({
            "acad_yr": academicYear,
            "exercise": exercise,
            "start_date": moment(data?.start_date).format("YYYY-MM-DD hh:mm:ss"),
            "end_date": moment(data?.end_date).format("YYYY-MM-DD hh:mm:ss"),
            "fasop_tag": data?.fasop_tag,
            "homes_tag": data?.homes_tag,
        })
            .then((response) => {
                if (response?.data?.success) {

                } else {
                    if (response?.data?.message == "overlap detected") {
                        toast.warning(
                        <>
                            {response?.data?.message} with these information: <br />
                            acad_yr: {response?.data?.data?.acad_yr} <br />
                            exercise: {response?.data?.data?.exercise} <br />
                            start date: {response?.data?.data?.start_date} <br />
                            end date: {response?.data?.data?.end_date} <br />
                            fasop tag: {response?.data?.data?.fasop_tag == null ? "N" : response?.data?.data?.fasop_tag} <br />
                            homes tag: {response?.data?.data?.homes_tag == null ? "N" : response?.data?.data?.homes_tag}
                          </>
                          );

                    } else {
                        toast.error(response?.data?.message);
                    }
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    }

    useEffect(() => {
        handleGetListAcademyYear();
    }, []);

    useEffect(() => {
        const file = selectedFile;

        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const existingAdmNos = new Set(students.map((student) => student.adm_no));

            const filteredData = jsonData.filter(
                (row) => !existingAdmNos.has(row.adm_no)
            );

            const students_id = filteredData.map((row) => row.adm_no).join(",");

            postStudentList(students_id).then((response) => {
                const newStudents = response?.data?.data || [];
                const updatedData = filteredData.map((row) => {
                    const matchingStudent = newStudents.find(
                        (student) => student.admNo === row.adm_no
                    );
                    return {
                        ...row,
                        name: matchingStudent
                            ? matchingStudent.name
                            : "Student name not found",
                    };
                });

                setStudents((prevStudents) => [...prevStudents, ...updatedData]);
                setSelectedFile(null)
            });
        };
        reader.readAsArrayBuffer(file);
    }, [selectedFile]);

    useEffect(() => {
        handleGetListExercise();
    }, [academicYear]);

    useEffect(() => {
        if(data.start_date && data.end_date){
            handleCheckOverlapTimeline()
        }
    }, [data.start_date, data.end_date, data?.fasop_tag, data?.homes_tag]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Application Timeline</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <div className="d-flex gap-3">
                    <h2>Setup Application Timeline</h2>

                    {/*<button*/}
                    {/*    className={`btn-clear text-blue d-flex align-items-center gap-1`}*/}
                    {/*    type={"button"}*/}
                    {/*>*/}
                    {/*    <i className="bi bi-box-arrow-up-right"></i>*/}
                    {/*    Export*/}
                    {/*</button>*/}
                </div>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Row className={`align-items-end`}>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>
                                Academic Year
                                <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <CreatableSelect
                                onCreateOption={handleCreateAcadYear}
                                onChange={(option) => {
                                    setSelectedAcadYearOption(option);
                                    setAcademicYear(option.value);
                                }}
                                options={dataAcademyYear}
                                value={selectedAcadYearOption}
                            />
                            {/*<Form.Control*/}
                            {/*    type={"number"}*/}
                            {/*    onChange={(e) => setAcademicYear(e.target.value)}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>

                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>
                                Exercise
                                <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <CreatableSelect
                                onCreateOption={handleCreateExercise}
                                onChange={(option) => {
                                    setSelectedExerciseOption(option);
                                    setExercise(option.value);
                                }}
                                options={dataExercise}
                                value={selectedExerciseOption}
                            />
                            {/*<Form.Control*/}
                            {/*    type={"number"}*/}
                            {/*    onChange={(e) => setExercise(e.target.value)}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Button
                            className={`btn-primary`}
                            type={"button"}
                            onClick={handleRetrieveData}
                        >
                            Retrieve
                        </Button>
                    </Col>
                </Row>
            </sgds-content-area-bottom>

            <sgds-content-body>
                {isRetrieved && (
                    <Row className={`gap-3`}>
                        <Col>
                            <div className={`d-flex gap-1`}>
                                {!editable.applicationDisabled ? (
                                    <div className={`d-flex gap-1`}>
                                        <button
                                            className={`btn-clear text-green-500 d-flex gap-1`}
                                            onClick={handleSubmit}
                                        >
                                            <i className={`bi bi-check-lg`}></i>
                                            Save
                                        </button>
                                        <button
                                            className={`btn-clear text-red-500 d-flex gap-1`}
                                            onClick={() => handleEdit("applicationDisabled")}
                                        >
                                            <i className={`bi bi-x-circle`}></i>
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className={`btn-clear`}
                                        type={"button"}
                                        onClick={() => handleEdit("applicationDisabled")}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {` `}Edit
                                    </button>
                                )}
                                {data?.id ? (
                                    <button
                                        className={`btn-clear `}
                                        type={"button"}
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        <i className={`bi bi-trash`}></i>
                                        {` `}Delete
                                    </button>
                                ) : null}
                            </div>
                        </Col>

                        <Col xs={12}>
                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Application</h3>
                                </div>

                                <div className={`d-flex flex-column gap-3`}>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    Start Date
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.start_date}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("start_date", null);
                                                        } else {
                                                            handleChange(
                                                                "start_date",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            )
                                                            // handleChange("end_date", "")
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    Start Time
                                                    <span className={`text-danger`}> *</span>
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={convertTimeStringToDate(data?.start_time)}
                                                    onChange={(date) => {
                                                        if (!date) return "0000";
                                                        const hours = date.getHours();
                                                        const minutes = date.getMinutes();
                                                        const formattedHours = hours
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedMinutes = minutes
                                                            .toString()
                                                            .padStart(2, "0");
                                                        handleChange(
                                                            "start_time",
                                                            `${formattedHours}${formattedMinutes}`
                                                        );
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>End Date

                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.end_date}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("end_date", null);
                                                        } else {
                                                            handleChange(
                                                                "end_date",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            );
                                                        }
                                                    }}
                                                    minDate={data?.start_date}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={
                                                        editable.applicationDisabled || !data?.start_date
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>End Time</Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={convertTimeStringToDate(data?.end_time)}
                                                    onChange={(date) => {
                                                        if (!date) return "0000";
                                                        const hours = date.getHours();
                                                        const minutes = date.getMinutes();
                                                        const formattedHours = hours
                                                            .toString()
                                                            .padStart(2, "0");
                                                        const formattedMinutes = minutes
                                                            .toString()
                                                            .padStart(2, "0");
                                                        handleChange(
                                                            "end_time",
                                                            `${formattedHours}${formattedMinutes}`
                                                        );
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dateFormat="HH:mm"
                                                    disabled={editable.applicationDisabled}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group className={`d-flex flex-column`}>
                                                <Form.Label>
                                                    View Application Status End Date{" "}
                                                </Form.Label>
                                                <DatePicker
                                                    className={`form-control`}
                                                    selected={data?.vstatus_end_date}
                                                    onChange={(date) => {
                                                        if (date == null) {
                                                            handleChange("vstatus_end_date", null);
                                                        } else {
                                                            handleChange(
                                                                "vstatus_end_date",
                                                                moment(date).format("YYYY-MM-DD HH:mm:ss")
                                                            );
                                                        }
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    disabled={editable.applicationDisabled}
                                                    required={true}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.multiple_award_per_ay === "Y"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "multiple_award_per_ay",
                                                            e.target.checked ? "Y" : null
                                                        )
                                                    }
                                                    id="app-1cb"
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-1cb">
                                                    Multiple award per ACAD year
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.allow_sponsor_tag === "Y"}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "allow_sponsor_tag",
                                                            e.target.checked ? "Y" : null
                                                        )
                                                    }
                                                    id="app-2cb"
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-2cb">
                                                    Applicants with sponsorship
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.fasop_tag === "Y"}
                                                    id="app-3cb"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "fasop_tag",
                                                            e.target.checked ? "Y" : null
                                                        )
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-3cb">
                                                    FASOP application
                                                </label>
                                            </div>
                                        </Col>

                                        <Col xs={6}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={data?.homes_tag === "Y"}
                                                    id="app-4cb"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "homes_tag",
                                                            e.target.checked ? "Y" : null
                                                        )
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                />
                                                <label className="form-check-label" htmlFor="app-4cb">
                                                    HOMES application
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>
                                        Applicant citizenship status
                                    </h3>
                                </div>

                                <div className={`d-flex flex-column`}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad1"
                                            checked={data?.sgcitizen_tag === "Y"}
                                            onChange={(e) => {
                                                handleChange(
                                                    "sgcitizen_tag",
                                                    e.target.checked ? "Y" : null
                                                );
                                                // handleChange("sgpr_tag", "");
                                                // handleChange("nonsg_pr_tag", "");
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad1">
                                            SG Citizen
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad2"
                                            checked={data?.sgpr_tag === "Y"}
                                            onChange={(e) => {
                                                // handleChange("sgcitizen_tag", "");
                                                handleChange("sgpr_tag", e.target.checked ? "Y" : null);
                                                // handleChange("nonsg_pr_tag", "");
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad2">
                                            SG PR
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="applicantRad"
                                            id="applicantRad3"
                                            checked={data?.nonsg_pr_tag === "Y"}
                                            onChange={(e) => {
                                                // handleChange("sgcitizen_tag", "");
                                                // handleChange("sgpr_tag", "");
                                                handleChange(
                                                    "nonsg_pr_tag",
                                                    e.target.checked ? "Y" : null
                                                );
                                            }}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="applicantRad3">
                                            Non SG Citizen / PR
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Cutoff Income</h3>
                                </div>

                                <div className={`d-flex flex-column gap-3`}>
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>GHI</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Gross Household Income
                                                </Form.Text>
                                                <Form.Control
                                                    type={`number`}
                                                    value={data?.cutoff_income}
                                                    onChange={(e) =>
                                                        handleChange("cutoff_income", e.target.value)
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={6}>
                                            <Form.Group>
                                                <Form.Label>MPCI</Form.Label>
                                                <Form.Text className="text-muted">
                                                    Monthly Per Capita Income
                                                </Form.Text>
                                                <Form.Control
                                                    type={`number`}
                                                    value={data?.mpci_income}
                                                    onChange={(e) =>
                                                        handleChange("mpci_income", e.target.value)
                                                    }
                                                    disabled={editable.applicationDisabled}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12}>
                                            <div className={`text-muted fst-italic mt-3`}>
                                                Meeting either one of the conditions qualifies the
                                                student for eligibility
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <div className={`d-flex justify-content-between`}>
                                    <h3 className={`text-blue fw-bold`}>Remarks</h3>
                                </div>

                                <div className={`d-flex flex-column`}>
                                    <Form.Group>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={data?.remark}
                                            onChange={(e) => handleChange("remark", e.target.value)}
                                            placeholder="write something"
                                            disabled={editable.applicationDisabled}
                                        />
                                    </Form.Group>

                                    <div className={`text-muted fst-italic mt-3`}>
                                        Remarks will be shown in Student Portal
                                    </div>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <h3 className={`text-blue fw-bold`}>
                                    Course
                                </h3>

                                <Form.Text>e.g. %DF% or %UF%</Form.Text>
                                <button
                                    className={`btn-clear d-flex align-items-center gap-1 ${
                                        editable.applicationDisabled
                                            ? "text-black-50"
                                            : "text-blue"
                                    }`}
                                    disabled={editable.applicationDisabled}
                                    onClick={() => handleAddCourseRow()}
                                >
                                    <i className={`bi bi-plus-circle`}></i>
                                    add course
                                </button>


                                <div className={`d-flex flex-column gap-3`}>
                                    <Row
                                        className={`align-items-center`}
                                        style={{rowGap: "10px"}}
                                    >
                                        {courses?.length > 0 &&
                                            courses.map((course, index) => (
                                                <React.Fragment key={index}>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type={"text"}
                                                                value={course?.course_code}
                                                                onChange={(e) =>
                                                                    handleChangeCourseCode(index, e.target.value)
                                                                }
                                                                disabled={editable.applicationDisabled}
                                                            />
                                                            {/*<CreatableSelect*/}
                                                            {/*    value={{*/}
                                                            {/*        value: course.course_code,*/}
                                                            {/*        label: course.course_code,*/}
                                                            {/*    }}*/}
                                                            {/*    options={course.options}*/}
                                                            {/*    isDisabled={editable.applicationDisabled}*/}
                                                            {/*    onCreateOption={(input) => handleCreateCourse(input, index)}*/}
                                                            {/*    onInputChange={(input) =>*/}
                                                            {/*        handleChangeCourseInput(input, index)*/}
                                                            {/*    }*/}
                                                            {/*    onChange={(selectedOption) =>*/}
                                                            {/*        handleChangeCourseCode(index, selectedOption)*/}
                                                            {/*    }*/}
                                                            {/*/>*/}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6} className={`d-flex gap-3`}>
                                                        <button
                                                            className={`btn-clear d-flex align-items-center gap-1 ${
                                                                editable.applicationDisabled
                                                                    ? "text-black-50"
                                                                    : "text-red-500"
                                                            }`}
                                                            disabled={editable.applicationDisabled}
                                                            onClick={() => handleRemoveCourse(index)}
                                                        >
                                                            <i className={`bi bi-dash-circle`}></i>
                                                            remove course
                                                        </button>
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                    </Row>
                                </div>
                            </div>

                            <div className={`p-3 bg-white shadow-sm`}>
                                <h3 className={`text-blue fw-bold`}>Eligible Student</h3>

                                <div className={`d-flex flex-column`}>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad1"
                                            checked={data?.adm_tag === null ?? false}
                                            onChange={() => handleChange("adm_tag", null)}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad1">
                                            All Student
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad3"
                                            checked={data?.adm_tag === "A" ?? false}
                                            onChange={() => handleChange("adm_tag", "A")}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad3">
                                            Student with scholarship
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="studentRad"
                                            id="studentRad2"
                                            checked={data?.adm_tag === "E" ?? false}
                                            onChange={() => handleChange("adm_tag", "E")}
                                            disabled={editable.applicationDisabled}
                                        />
                                        <label className="form-check-label" htmlFor="studentRad2">
                                            Eligible student listed
                                            <span className={`text-danger`}> *</span>
                                        </label>
                                    </div>

                                    {data?.adm_tag ? (
                                        <div className={`d-flex flex-column gap-2`}>
                                            <div className={`bg-gray p-3`}>
                                                {students?.length > 0 &&
                                                    students?.map((student, index) => (
                                                        <React.Fragment key={index}>
                                                            <Row>
                                                                <Col xs={3}>
                                                                    <Select
                                                                        value={{
                                                                            value: student.adm_no,
                                                                            label: student.adm_no,
                                                                        }}
                                                                        options={student.options}
                                                                        isDisabled={editable.applicationDisabled}
                                                                        onInputChange={(input) =>
                                                                            handleChangeStudentInput(input, index)
                                                                        }
                                                                        onChange={(selectedOption) =>
                                                                            handleChangeStudent(index, selectedOption)
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        placeholder={"Student name"}
                                                                        value={
                                                                            student?.options?.find(
                                                                                (option) =>
                                                                                    option.value === student.adm_no
                                                                            )?.name ||
                                                                            (student.name ?? "-")
                                                                        }
                                                                        disabled={editable.applicationDisabled}
                                                                        readOnly={true}
                                                                    ></Form.Control>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <button
                                                                        className={`btn-clear d-flex align-items-center gap-1 ${
                                                                            editable.applicationDisabled
                                                                                ? "text-black-50"
                                                                                : "text-red-500"
                                                                        }`}
                                                                        disabled={editable.applicationDisabled}
                                                                        onClick={() => handleRemoveStudent(index)}
                                                                    >
                                                                        <i className={`bi bi-dash-circle`}></i>
                                                                        remove student
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    ))}
                                            </div>

                                            <button
                                                className={`btn-clear d-flex align-items-center gap-1 ${
                                                    editable.applicationDisabled
                                                        ? "text-black-50"
                                                        : "text-blue"
                                                }`}
                                                disabled={editable.applicationDisabled}
                                                onClick={handleAddStudentRow}
                                            >
                                                <i className={`bi bi-plus-circle`}></i>
                                                add student
                                            </button>

                                            <div>
                                                <button type="button" className="btn btn-primary sgds" onClick={(e) => {
                                                    setShowUpload(true)
                                                }}>
                                                    <i className="bi bi-upload me-2"></i>Choose a file
                                                </button>
                                            </div>

                                            {/*
                                            <FileUpload
                                                controlId="fileupload1"
                                                onChangeFile={onChangeFile}
                                                selectedFile={selectedFile}
                                                disabled={editable.applicationDisabled}
                                            >
                                                <i className="bi bi-upload me-2"></i>Choose a file
                                            </FileUpload>

                                            */}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </sgds-content-body>
            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Student`}
                onFileSelect={onChangeFile}
                selectedFile={selectedFile}
                handleDownloadTemplate={handleDownloadTemplate}
            />
            <CustomModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                action={handleDelete}
                title={`Delete Timeline`}
                description={`Do you wish to proceed?`}
            />
        </Layout>
    );
}

import React, { useEffect, useState } from "react";
import { Col, Form, FormCheck, Row } from "@govtechsg/sgds-react";
import { Accordion } from "@govtechsg/sgds-react/Accordion";
import moment from "moment";
import { getCodeList } from "@/apis/preparation/setup-dialect-etc/api";
import { LivingAllowance } from "@/apis/app-records/maintenance/status";
import Select from "react-select";
import DatePicker from "react-datepicker";

function OriginalDetails({
  isDisabled,
  details,
  parentLog,
  siblingLog,
  studentDetail,
  handleShowFormulaModal,
  listScholarshipLog,
  activeKeys
}) {
  const parentStatus = [
    {
      label: "Living in the same household",
      value: "L",
    },
    {
      label: "Not living in the same household",
      value: "NL",
    },
    {
      label: "Deceased",
      value: "DE",
    },
    {
      label: "Divorced. Living with applicant",
      value: "DL",
    },
    {
      label: "Divorced. Not living with applicant",
      value: "DN",
    },
    {
      label: "In-Jail",
      value: "IJ",
    },
  ];

  const [relationshipList, setRelationshipList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);
  const [housingList, setHousingList] = useState([]);
  const [dialectList, setDialectList] = useState([]);

  const handleGetRelationshipStatus = () => {
    getCodeList("R", 0)
      .then((response) => {
        if (response.status == 200) {
          let listContent = [];
          response?.data?.data?.map((item) => {
            listContent.push({
              value: item?.code,
              description: `${item?.description}`,
            });
          });
          setRelationshipList(listContent);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetMaritalStatus = () => {
    getCodeList("MS", 0)
      .then((response) => {
        if (response.status == 200) {
          let listContent = [];
          response?.data?.data?.map((item) => {
            listContent.push({
              value: item?.code,
              description: `${item?.description}`,
            });
          });
          setMaritalList(listContent);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetHousingStatus = () => {
    getCodeList("H", 0)
      .then((response) => {
        if (response.status == 200) {
          let listContent = [];
          response?.data?.data?.map((item) => {
            listContent.push({
              value: item?.code,
              description: `${item?.description}`,
            });
          });
          setHousingList(listContent);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleGetDialectStatus = () => {
    getCodeList("DG", 0)
      .then((response) => {
        if (response.status == 200) {
          let listContent = [];
          response?.data?.data?.map((item) => {
            listContent.push({
              value: item?.code,
              description: `${item?.description}`,
            });
          });
          setDialectList(listContent);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    handleGetRelationshipStatus();
    handleGetMaritalStatus();
    handleGetHousingStatus();
    handleGetDialectStatus();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Last Update ID</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={details?.last_upd_id}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Last Update Date</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={
                  details?.updated_at
                    ? moment(details?.updated_at).format("DD/MM/YYYY hh:mm:ss")
                    : ""
                }
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Father
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.name
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentStatus.find(
                            (item) =>
                              item.value ===
                              parentLog?.find(
                                (parent) => parent.relationship === "F"
                              )?.status
                          )?.label || "-"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>NRIC / Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.id_no
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={moment(
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.birthdate
                        ).format("DD/MM/YYYY")}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.age
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                      <Form.Group>
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control
                              type="text"
                              disabled={true}
                              value={parentLog?.find(
                                (parent) => parent.relationship === "F"
                              )?.occupation ?? "-"}
                          ></Form.Control>
                      </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.other_income ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.mthly_income_contrib ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Child Support</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.child_support ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "F"
                          )?.remark ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Mother
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.name
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentStatus.find(
                            (item) =>
                              item.value ===
                              parentLog?.find(
                                (parent) => parent.relationship === "M"
                              )?.status
                          )?.label || "-"
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>NRIC / Passport No</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.id_no
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Birth Date</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={moment(
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.birthdate
                        ).format("DD/MM/YYYY")}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.age
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                      <Form.Group>
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control
                              type="text"
                              disabled={true}
                              value={parentLog?.find(
                                (parent) => parent.relationship === "M"
                              )?.occupation ?? "-"}
                          ></Form.Control>
                      </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Label>Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.other_income ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.mthly_income_contrib ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Child Support</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.child_support ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          parentLog?.find(
                            (parent) => parent.relationship === "M"
                          )?.remark ?? "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Sibling / Relatives
            </Accordion.Header>
            <Accordion.Body>
              {siblingLog?.map((sibling, index) => (
                <React.Fragment>
                  <div className={`mt-3`}>
                    <h4>#{index + 1} Sibling / Relatives</h4>
                  </div>
                  <div>
                    <Row>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.name}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>NRIC / Passport No</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.id_no}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Birth Date</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={moment(sibling?.birthdate).format(
                              "DD/MM/YYYY"
                            )}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Age</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.age}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Relationship</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={
                              relationshipList.find(
                                (item) => item.value === sibling?.relationship
                              )?.description || "-"
                            }
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label>Marital Status</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={
                              maritalList.find(
                                (item) => item.value === sibling?.marital_status
                              )?.description || "-"
                            }
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                          <Form.Group>
                              <Form.Label>Occupation</Form.Label>
                              <Form.Control
                                  type="text"
                                  disabled={true}
                                  value={sibling?.occupation}
                              ></Form.Control>
                          </Form.Group>
                      </Col>
                      {/*<Col xs={6}>*/}
                      {/*    <Form.Group>*/}
                      {/*        <Form.Label>Other Income</Form.Label>*/}
                      {/*        <Form.Control*/}
                      {/*            type="text"*/}
                      {/*            disabled={true}*/}
                      {/*            value={sibling?.OTHER_INCOME}*/}
                      {/*        ></Form.Control>*/}
                      {/*    </Form.Group>*/}
                      {/*</Col>*/}
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Monthly Contribution</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.mthly_contrib}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Remark</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            value={sibling?.remark}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Applicant
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Marital Status</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          maritalList.find(
                            (item) => item.value === details?.marital_status
                          )?.description || "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Dialect Group</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          dialectList.find(
                            (item) => item.value === details?.dialect_grp
                          )?.description || "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group>
                      <Form.Label>Housing Type</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={
                          housingList.find(
                            (item) => item.value === details?.homehousetype
                          )?.description || "-"
                        }
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Applicant's Gross Monthly Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.mthly_income}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Applicant's Other Income</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.other_income}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>GHI</Form.Label>
                      <Form.Text className="text-muted">
                        Gross Household Income
                      </Form.Text>

                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.gross_income}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>MPCI</Form.Label>
                      <Form.Text className="text-muted">
                        Monthly Per Capital Income
                      </Form.Text>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.mpci}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Number of Family</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={details?.family_members}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <button
                      className={"btn btn-clear"}
                      onClick={() => handleShowFormulaModal()}
                    >
                      <u>Check formula note</u>
                    </button>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Charged-in-court
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <FormCheck
                        id="Charged_in_a_court_of_law_in_any_country"
                        label="Charged in a court of law in any country"
                        name="Charged_in_a_court_of_law_in_any_country "
                        type="checkbox"
                        checked={details?.charge_in_court === "Y"}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Sponsorship / Gov Bursary
            </Accordion.Header>
            <Accordion.Body>
              <div className="mt-3">
                <h3>Scholarship</h3>
                {listScholarshipLog?.map((item, index) => {
                  if (item?.rec_tag !== "B") {
                    return (
                      <Row>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>
                              Name of Scholarship / Sponsorship
                            </Form.Label>
                            <Form.Control
                              type={`text`}
                              disabled={true}
                              value={item?.scholar_sponsor}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>
                          <Form.Group>
                            <FormCheck
                              id={`tuition_fee${index}`}
                              label="Tuition Fees"
                              name="tuition_fee "
                              type="checkbox"
                              checked={item?.tutfee_c_tag === "Y"}
                              disabled={true}
                            />
                            <FormCheck
                              id={`living_allowance${index}`}
                              label="Living Allowance"
                              name="living_allowance"
                              type="checkbox"
                              checked={item?.l_allowance_c_tag === "Y"}
                              disabled={true}
                            />
                            {item?.l_allowance_c_tag === "Y" && (
                              <Row className="ms-md-3">
                                <Col xs={6}>
                                  <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                      type="number"
                                      disabled={true}
                                      min={0}
                                      value={item?.l_allowance_c_details}
                                    ></Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col xs={6}>
                                  <Form.Group>
                                    <Form.Label>Type</Form.Label>
                                    <Select
                                      options={LivingAllowance()}
                                      isDisabled={true}
                                      value={LivingAllowance().find(
                                        (option) =>
                                          option.value ===
                                          item?.l_allowance_c_type
                                      )}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            )}
                            <FormCheck
                              id={`others${index}`}
                              label="Others (please specify)"
                              name="others "
                              type="checkbox"
                              checked={item?.others_c_tag === "Y"}
                              disabled={true}
                            />

                            {item?.others_c_tag === "Y" && (
                              <Row className="ms-md-3">
                                <Col xs={12}>
                                  <Form.Group>
                                    <Form.Label>
                                      Other coverage details
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={item?.others_c_details}
                                      disabled={true}
                                    ></Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  }
                })}
              </div>

              <div className="mt-3">
                <h3>Gov Bursary</h3>
                {listScholarshipLog?.map((item, index) => {
                  if (item?.rec_tag == "B") {
                    return (
                      <div className="mt-3" key={index}>
                        <Row>
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>
                                Name of Government Bursary
                              </Form.Label>
                              <Form.Control
                                type={`text`}
                                disabled={true}
                                value={item?.scholar_sponsor}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group>
                              {" "}
                              <Form.Label>Quantum</Form.Label>
                              <Form.Control
                                type={`text`}
                                disabled={true}
                                value={item?.govbur_quantum}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={6}>
                            <Form.Group className={`d-flex flex-column`}>
                              {" "}
                              <Form.Label>Period</Form.Label>
                              <DatePicker
                                selected={
                                  item?.govbur_period
                                    ? moment(
                                        item.govbur_period,
                                        "MM/YYYY"
                                      ).toDate()
                                    : null
                                }
                                className={`form-control`}
                                dateFormat="MM/YYYY"
                                showMonthDropdown
                                showYearDropdown
                                disabled={true}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="mt-4">
        <Accordion activeKey={activeKeys} className="">
          <Accordion.Item eventKey="0" className="shadow border-0">
            <Accordion.Header className="header-text text-blue">
              Educational Information
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <FormCheck
                        id="Paying_moe_subsidised_fee"
                        label="Paying MOE subsidised fee"
                        name="Paying_moe_subsidised_fee "
                        type="checkbox"
                        disabled={true}
                        checked={details?.moe_subsidised === "Y"}
                      />
                      <FormCheck
                        id="Company_sponsored"
                        label="Company Sponsored"
                        name="company_sponsored "
                        type="checkbox"
                        disabled={true}
                        checked={details?.comp_sponsor_tag === "Y"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </React.Fragment>
  );
}

export default OriginalDetails;

import {Button, Col, Dropdown, Form, FormCheck, Modal, Row,} from "@govtechsg/sgds-react";
import Select, {components} from "react-select";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList, getStudentList} from "@/apis/sims/api";
import {getRecommendList, patchApplications,} from "@/apis/applications/api";
import CustomModal from "@/components/CustomModal";
import {deleteApplication} from "@/apis/app-records/verification/api";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {getSchemeList} from "@/apis/preparation/setup-scheme/api";
import {sortExerciseList} from "@/components/utils/utils";
import {ApplicationStatus} from "@/apis/app-records/maintenance/status";
import {getRemarkNYP} from "@/apis/preparation/setup-remarks/api";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import { appRecommendationOrderBy } from "@/apis/app-records/maintenance/status";

export default function Application() {
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataAdminNo, setDataAdminNo] = useState([]);
    const [dataApplications, setDataApplications] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [dataExercise, setDataExercise] = useState([]);
    const [dataName, setDataName] = useState([]);

    const [academyYear, setAcademyYear] = useState(null);
    const [adminNo, setAdminNo] = useState(null);
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [award, setAward] = useState(null);
    const [awardType, setAwardType] = useState(null);
    const [exercise, setExercise] = useState(null);
    const [name, setName] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState(null);
    const [recommended, setRecommended] = useState({
        applicationStatus: null,
        remarks: "",
        specialDetails: "",
    });

    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
    const [loadingListAward, setLoadingListAward] = useState(false);

    const [isOpen, setIsOpen] = useState(true);
    const [showDeleteApplication, setShowDeleteApplication] = useState(false);

    const contentRef = useRef(null);
    const refTimeoutSearch = useRef(null);
    const [nypRemarks, setNypRemarks] = useState([])
    const [selectedNypRemark, setSelectedNypRemark] = useState(null);

    const [groupEdit, setGroupEdit] = useState({name: "", age: ""});

    const [selectAll, setSelectAll] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const [show, setShow] = useState(false);

    const [isNotEnrolledChecked, setIsNotEnrolledChecked] = useState(false);

    const [schemeOptions, setSchemeOptions] = useState([]);

    const [selectedSchemeOption, setSelectedSchemeOption] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const [totalData, setTotalData] = useState(0)

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    const handleDeleteApplication = () => {
        if (selectedItem) {
            deleteApplication(
                selectedItem?.acad_yr,
                selectedItem?.exercise,
                selectedItem?.adm_no
            )
                .then(() => {
                    handleGetListApplications();
                    setShowDeleteApplication(false);
                    setSelectedItem(null);
                    toast.success("Record deleted successfully");
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();

            setDataAcademyYear(
                response?.data?.data?.map((item) => ({
                    label: item?.acad_yr,
                    value: item?.acad_yr,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };

    const handleGetListAdminNo = async (search = "") => {
        try {
            setAdminNo(null);
            setDataAdminNo([]);

            const params = {
                student_id: "",
                studstatus: "",
                transfer_tag: "N",
                page: 1,
                limit: 20,
                search: search,
                sort: "id_no",
            };

            const response = await getStudentList(params);

            setDataAdminNo(
                response?.data?.data?.map((item) => ({
                    label: item?.admNo,
                    value: item?.admNo,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListApplications = async (e) => {
        e?.preventDefault();

        try {
            const params = {
                appln_status: applicationStatus?.value,
                acad_yr: academyYear?.value,
                exercise: exercise?.value,
                adm_no: adminNo?.value,
                award_type: awardType?.value,
                award: award?.value,
                orderByField: orderBy?.value,
                orderByDirection: orderDirection?.value,
            };

            const response = await getRecommendList(params);

            setCurrentPage(response?.data?.currentPage);
            setTotalPages(response?.data?.totalPage);
            setIsNextPage(response?.data?.nextPage);
            setTotalData(response?.data?.totalData)

            if (response.response) {
                if (!response?.response?.data?.success) {
                    toast.error(response?.response?.data?.message);
                    return;
                }
            }

            if (response?.data?.data?.length === 0) {
                setItems([])
                toast.warning("Application data is empty");
                return;
            }

            console.log(response.data.data)
            setItems(response?.data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetListAward = async (codeType = "") => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType(null);

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);
            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListName = async (search = "") => {
        try {
            setName(null);
            setDataName([]);

            const params = {
                student_id: "",
                studstatus: "",
                transfer_tag: "N",
                page: 1,
                limit: 20,
                search: search,
                sort: "id_no",
            };

            const response = await getStudentList(params);

            setDataName(
                response?.data?.data?.map((item) => ({
                    label: item?.name,
                    value: item?.name,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleSearch = (searchFunction = null, value = "") => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) setItems([]);
        else setItems(dataApplications);

        setSelectAll((prevState) => !prevState);
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems?.length === 0) {
            setGroupEdit({name: "", age: "", role: ""});
            return;
        }
        const name = selectedItems.map((item) => item.name).join(", ");
        const age = selectedItems.map((item) => item.age).join(", ");
        const role = selectedItems.map((item) => item.role).join(", ");
        setGroupEdit({name, age, role});
    };

    const [selectedRows, setSelectedRows] = useState([])

    const handleSelectItem = (row) => {
        setSelectedRows(row.selectedRows)
    };

    const handleRecommended = async () => {
        try {
            const params = {
                data: [
                    ...selectedRows.map((data) => ({
                        acad_yr: data?.acad_yr ?? academyYear?.value,
                        exercise: data?.exercise ?? exercise?.value,
                        adm_no: data?.adm_no ?? adminNo?.value,
                        appln_status: recommended?.applicationStatus?.value,
                        remark: recommended?.remarks.value,
                        special_details: recommended?.specialDetails,
                        last_upd_id: sessionStorage.getItem("staffId")
                            ? sessionStorage.getItem("staffId")
                            : process.env.REACT_APP_LAST_UPD_ID,
                    })),
                ],
            };

            const resp = await patchApplications(params);

            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Record updated successfully")
                }
            }

            setItems([]);
            handleGetListApplications();
            setRecommended({
                applicationStatus: null,
                remarks: "",
                specialDetails: "",
            });

            setShow(false);
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetSchemes = () => {
        getSchemeList()
            .then((response) => {
                setSchemeOptions(
                    response?.data?.data.map((option) => ({
                        value: option?.scheme_id,
                        label: option?.institution,
                        institute_id: option?.institute_id,
                    }))
                );
            })
            .then((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    const customOption = (props) => (
        <div>
            <components.Option {...props}>
                <input
                    type={"checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );

    const toggleColumnVisibility = (key) => {
        setColumnVisibilityOptions(
            columnVisibilityOptions.map((column) =>
                column.value === key ? {...column, visible: true} : column
            )
        );
    };

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {value: "age", label: "SNo", visible: true, columnEditable: false},
        {
            value: "adm_no",
            label: "Admin No.",
            visible: true,
            columnEditable: false,
        },
        {
            value: "idNo",
            label: "ID No.",
            visible: true,
            columnEditable: false,
        },
        {value: "name", label: "Name", visible: true, columnEditable: false},
        {
            value: "courseName",
            label: "Course",
            visible: true,
            columnEditable: false,
        },
        {
            value: "studyStage",
            label: "Study Stage",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mthly_income",
            label: "Monthly Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "family_members",
            label: "Members",
            visible: true,
            columnEditable: false,
        },
        {
            value: "dependent",
            label: "Dependant",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mpci",
            label: "MPCI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gpa",
            label: "GPA",
            visible: true,
            columnEditable: false,
        },
        {
            value: "citizen",
            label: "Citizen",
            visible: true,
            columnEditable: false,
        },
        {value: "pr", label: "PR", visible: true, columnEditable: false},
        {
            value: "homehousetype",
            label: "Housing",
            visible: true,
            columnEditable: false,
        },
        {
            value: "other_income",
            label: "GHI Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gross_income",
            label: "Gross Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "award",
            label: "Received Awards",
            visible: true,
            columnEditable: false,
        },
        {value: "gpa", label: "AGG/GPA", visible: true, columnEditable: false},
        {value: "cumWghtAvg", label: "CWA", visible: true, columnEditable: false},
        {
            value: "recommended_date",
            label: "Recommended",
            visible: true,
            columnEditable: false,
        },
        {
            value: "remark",
            label: "Application Status Remarks",
            visible: true,
            columnEditable: false,
        },
        {
            value: "special_details",
            label: "Special Details",
            visible: true,
            columnEditable: false,
        },
    ]);

    const columns = [
        // {value: "age", label: "SNo", name: "SNo", selector: row => row.age, sortable: true},
        {
            value: "adm_no", label: "Admin No.", name: "Admin No.", selector: row => row.adm_no, sortable: true,
            cell: row =>
                <Link
                    to={`/app-records/app-maintenance?academicYearParams=${row.acad_yr}&exerciseParams=${row.exercise}&admNoParams=${row.adm_no}`}
                    target={"_blank"}>
                    {row.adm_no}
                </Link>
        },
        {value: "idNo", label: "ID No.", name: "ID No.", selector: row => row.idNo, sortable: true},
        {value: "name", label: "Name", name: "Name", selector: row => row.name, sortable: true},
        {
            value: "courseName",
            label: "Course",
            name: "Course",
            cell: row => row?.courseList?.[0]?.courseName,
            sortable: true
        },
        {
            value: "studyStage",
            label: "Study Stage",
            name: "Study Stage",
            cell: row => row.courseList?.[0]?.studyStage,
            sortable: true
        },
        {
            value: "mthly_income",
            label: "Monthly Income",
            name: "Monthly Income",
            selector: row => row.mthly_income,
            sortable: true
        },
        {
            value: "family_members",
            label: "Members",
            name: "Members",
            selector: row => row.family_members,
            sortable: true
        },
        {value: "dependent", label: "Dependant", name: "Dependant", selector: row => row.dependent, sortable: true},
        {value: "mpci", label: "MPCI", name: "MPCI", selector: row => row.mpci, sortable: true},
        {value: "gpa", label: "GPA", name: "GPA", cell: row => row.courseList?.[0]?.gpa, sortable: true},
        {value: "citizen", label: "Citizen", name: "Citizen", selector: row => row.citizen, sortable: true},
        {value: "pr", label: "PR", name: "PR", selector: row => row.pr, sortable: true},
        {value: "homehousetype", label: "Housing", name: "Housing", selector: row => row.homehousetype, sortable: true},
        {
            value: "other_income",
            label: "GHI Income",
            name: "GHI Income",
            selector: row => row.other_income,
            sortable: true
        },
        {
            value: "gross_income",
            label: "Gross Income",
            name: "Gross Income",
            selector: row => row.gross_income,
            sortable: true
        },
        {
            value: "award",
            label: "Received Awards",
            name: "Received Awards",
            cell: row => row.awardCriteria?.[0]?.award,
            sortable: true
        },
        {value: "cumWghtAvg", label: "CWA", name: "CWA", cell: row => row.courseList?.[0]?.cumWghtAvg, sortable: true},
        {
            value: "recommended_date", label: "Recommended", name: "Recommended", selector: row => row.recommended_date,
            cell: row => <FormCheck checked={row.appln_status === "R"} disabled={true}/>,
            sortable: true
        },
        {
            value: "remark",
            label: "Application Status Remarks",
            name: "Application Status Remarks",
            selector: row => row.remark,
            sortable: true,
            cell: row => nypRemarks.find(option => option.value === row?.remark)?.label || '',
        },
        {
            value: "special_details",
            label: "Special Details",
            name: "Special Details",
            selector: row => row.special_details,
            sortable: true
        },
        {
            name: "",
            cell: row =>
                <button
                    className="btn-clear"
                    onClick={() => {
                        handleSelectItem({
                            selectedRows: [row]
                        });
                        setShow(true);
                    }}
                >
                    <i className="bi bi-pencil"></i>
                </button>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const [selectedColumns, setSelectedColumns] = useState(columns.map(col => col.value))

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectedVisibilityOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions);

        const updatedOptions = columnVisibilityOptions.map((option) => ({
            ...option,
            visible: selectedOptions.some(
                (selected) => selected.value === option.value
            ),
        }));

        setColumnVisibilityOptions(updatedOptions);
    };

    const handleColumnChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedColumns(selectedValues);
    };

    const filteredColumns = columns.filter(column => selectedColumns.includes(column.value));

    const handleGetNYPRemarks = () => {
        getRemarkNYP(0, 0)
            .then((response) => {
                const options = response?.data?.data?.map((data) => ({
                    label: data?.remark,
                    value: data?.code
                }));

                setNypRemarks(options)
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    }

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAdminNo();
        handleGetListAwardType();
        handleGetListName();
        handleGetSchemes();
        handleGetNYPRemarks();

        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );
    }, []);

    return (
        <Layout currentNav={"processing"} toc={false}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Recommendation</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Application Recommendation</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}
                            onSubmit={handleGetListApplications}
                        >
                            <Row>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Academic Year</Form.Label>
                                        <Select
                                            onChange={(value) => setAcademyYear(value)}
                                            options={dataAcademyYear}
                                            isClearable={true}
                                            placeholder={`Academic Year`}
                                            value={academyYear}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Exercise</Form.Label>
                                        <Select
                                            isDisabled={!academyYear?.value}
                                            isClearable={true}
                                            onChange={(value) => setExercise(value)}
                                            options={dataExercise}
                                            value={exercise}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Adm. No.</Form.Label>
                                        <Select
                                            onChange={(value) => setAdminNo(value)}
                                            isClearable={true}
                                            options={dataAdminNo}
                                            onInputChange={(value) =>
                                                handleSearch(handleGetListAdminNo, value)
                                            }
                                            value={adminNo}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Application Status</Form.Label>
                                        <Select
                                            backspaceRemovesValue={true}
                                            isClearable={true}
                                            onChange={(value) => setApplicationStatus(value)}
                                            options={ApplicationStatus()}
                                            placeholder={`Application Status`}
                                            value={applicationStatus}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            isClearable={true}
                                            onChange={(value) => setAwardType(value)}
                                            options={dataAwardType}
                                            value={awardType}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            isClearable={true}
                                            isDisabled={!awardType?.value}
                                            onChange={(value) => setAward(value)}
                                            options={dataAward}
                                            value={award}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Order By</Form.Label>
                                        <Select
                                            isClearable={true}
                                            onChange={(value) => setOrderBy(value)}
                                            options={appRecommendationOrderBy()}
                                            value={orderBy}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Order Direction</Form.Label>
                                        <Select
                                            isClearable={true}
                                            onChange={(value) => setOrderDirection(value)}
                                            options={[{label:"ASC", value:"ASC"}, {label:"DESC", value:"DESC"}]}
                                            value={orderDirection}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={3}>
                                    <Button type="submit" className="btn btn-primary">
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                </div>

                {items?.length > 0 ? (
                    <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Columns visibility</Form.Label>
                                    <Select
                                        isMulti
                                        options={columns.slice(0, -1)}
                                        onChange={handleColumnChange}
                                        defaultValue={columns.slice(0, -1)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={6}
                                className="d-flex justify-content-end align-items-end gap-3"
                            >
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className={`btn btn-outline-dark`}
                                        disabled={items?.length <= 0}
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                        Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setShow(true)}>
                                            Recommend
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <button type="button" className="btn btn-primary">
                                    Export
                                </button> */}
                            </Col>
                            <Col xs={12}>
                                <DataTable
                                    columns={filteredColumns}
                                    data={items}
                                    onSelectedRowsChange={handleSelectItem}
                                    selectableRows
                                    pagination
                                    paginationRowsPerPageOptions={[20]}
                                    onChangePage={page => setCurrentPage(page)}
                                    paginationTotalRows={totalData}
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </sgds-content-body>

            <Modal
                size={"lg"}
                show={show}
                onHide={() => setShow(false)}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Action - Recommend</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={`justify-content-center`}>
                        <Col xs={8} className={`bg-yellow-100 p-2 text-center`}>
                            Selected{" "}
                            {selectedRows.map(indv => indv.name || indv.adm_no).join(', ')}
                        </Col>
                    </Row>

                    <div className={`d-flex flex-column gap-3 mt-3`}>
                        <h5 className={`fw-bold mb-0`}>Add remarks</h5>

                        <Row>
                            <Col xs={4}>
                                <label>Application Status</label>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    backspaceRemovesValue={true}
                                    onChange={(value) =>
                                        setRecommended((prevState) => ({
                                            ...prevState,
                                            applicationStatus: value,
                                        }))
                                    }
                                    options={ApplicationStatus()}
                                    placeholder={`Application Status`}
                                    value={recommended?.applicationStatus}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={4}>
                                <label>Application Status Remarks</label>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    backspaceRemovesValue={true}
                                    onChange={(value) =>
                                        setRecommended((prevState) => ({
                                            ...prevState,
                                            remarks: value,
                                        }))
                                    }
                                    options={nypRemarks}
                                    placeholder={`NYP Remarks`}
                                    value={recommended?.remarks}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={4}>
                                <label>Special Details</label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control
                                    type={"text"}
                                    onChange={(e) =>
                                        setRecommended((prevState) => ({
                                            ...prevState,
                                            specialDetails: e?.target?.value,
                                        }))
                                    }
                                    value={recommended?.specialDetails}
                                ></Form.Control>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`gap-2`}>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRecommended}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomModal
                show={showDeleteApplication}
                handleClose={() => setShowDeleteApplication(false)}
                action={handleDeleteApplication}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
        </Layout>
    );
}

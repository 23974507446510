import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, Row, Tab, Table, Tabs,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {Accordion} from "@govtechsg/sgds-react/Accordion";
import FormulaModal from "@/components/FormulaModal";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import * as XLSX from "xlsx";
import {getOccupationList} from "@/apis/preparation/setup-occupation/api";
import moment from "moment-timezone";

import {
    deleteScholarship,
    editApplicationDetail,
    getApplicationDetail,
    getApplicationList,
    getApplicationParentLog,
    getApplicationSiblingLog,
    getDetailApplicationLog,
    getDSpecialDetails,
    getScholarship,
    getScholarshipLog,
    transferApplicationExercise,
    upsertScholarship,
} from "@/apis/app-records/maintenance/api";
import {getListAcadYears, getListExercise,} from "@/apis/preparation/app-timeline/api";

import {getStudentDetail, saveStudentFinassists} from "@/apis/sims/api";

import {getParents, updateParents} from "@/apis/app-records/parents/api";
import {deleteSiblings, getSiblings, updateSiblings,} from "@/apis/app-records/siblings/api";

import PersonalData from "@/components/AppRecords/Maintenance/PersonalParticulars/PersonalData";
import ApplicationDetail from "@/components/AppRecords/Maintenance/PersonalParticulars/ApplicationDetail";
import Awarded from "@/components/AppRecords/Maintenance/PersonalParticulars/Awarded";

import Father from "@/components/AppRecords/Maintenance/FamilyDetails/Father";
import Mother from "@/components/AppRecords/Maintenance/FamilyDetails/Mother";
import Sibling from "@/components/AppRecords/Maintenance/FamilyDetails/Sibling";
import Applicant from "@/components/AppRecords/Maintenance/FamilyDetails/Applicant";
import OtherScholarshipBeneficiary from "@/components/AppRecords/Maintenance/FamilyDetails/OtherScholarshipBeneficiary";
import GovernmentBursary from "@/components/AppRecords/Maintenance/FamilyDetails/GovernmentBursary";

import ResultDetails from "@/components/AppRecords/Maintenance/ResultDetails/ResultDetails";
import OtherDetails from "@/components/AppRecords/Maintenance/OtherDetails/OtherDetails";
import OriginalDetails from "@/components/AppRecords/Maintenance/OriginalDetails/OriginalDetails";
import {getRemarkNYP, getRemarkPA,} from "@/apis/preparation/setup-remarks/api";

import {useSearchParams} from "react-router-dom";
import {sortExerciseList, validateNRICFIN} from "@/components/utils/utils";

import { getAwardListWithParams } from '@/apis/preparation/setup-award/api'
  
function AppMaintenance() {
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);
    const firstTImeSubmitApplication = useRef(false);
    const [searchParams] = useSearchParams(); // useSearchParams hook
    const academicYearParams = searchParams.get("academicYearParams");
    const exerciseParams = searchParams.get("exerciseParams");
    const admNoParams = searchParams.get("admNoParams");
    const [retrieved, setRetrieved] = useState(false);

    // variable selected
    const [academicYear, setAcademicYear] = useState();
    const [selectedListExercise, setSelectedListExercise] = useState(null);
    const [selectedListAdmNo, setSelectedListAdmNo] = useState(null);

    // variable list
    const [listAcadYears, setListAcadYears] = useState([]);
    const [listExercise, setListExercise] = useState([]);
    const [listAdmNo, setListAdmNo] = useState([]);
    const [listParent, setListParent] = useState([]);
    const [listSibling, setListSibling] = useState([]);
    const [listScholarship, setListScholarship] = useState([]);
    const [listScholarshipLog, setListScholarshipLog] = useState([]);

    const [isDisabled, setIsDisabled] = useState(true);
    const [showFormulaModal, setShowFormulaModal] = useState(false);
    const [showCCAModal, setShowCCAModal] = useState(false);

    const [applicationDetail, setApplicationDetail] = useState({});
    const [studentDetail, setStudentDetail] = useState({});
    const [studentAwardDetail, setStudentAwardDetail] = useState({});
    const [
        showTransferToAnotherExerciseModal,
        setShowTransferToAnotherExerciseModal,
    ] = useState(false);

    const [originalDetail, setOriginalDetail] = useState({});
    const [parentLog, setParentLog] = useState([]);
    const [siblingLog, setSiblingLog] = useState([]);

    const [PARemarks, setPARemarks] = useState([]);
    const [NYPRemarks, setNYPRemarks] = useState([]);

    // variable to submit
    // personal particulars
    const [maritalStatus, setMaritalStatus] = useState({label: "", value: ""});
    const [dialectGroup, setDialectGroup] = useState({label: "", value: ""});
    const [housingType, setHousingType] = useState({label: "", value: ""});
    const [viewCCAList, setViewCCAList] = useState([]);
    const [transferToAnotherExerciseValue, setTransferToAnotherExerciseValue] =
        useState(0);

    const [successApplication, setSuccessApplication] = useState(false);

    // application details
    const [selectedVerificationStatus, setSelectedVerificationStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedApplicationStatus, setSelectedApplicationStatus] = useState({
        label: "",
        value: "",
    });
    const [selectedDataFlag, setSelectedDataFlag] = useState({
        label: "",
        value: "",
    });

    const [selectedAwardType, setSelectedAwardType] = useState({
        label: "",
        value: "",
        code: "",
    });
    const [selectedAwardCode, setSelectedAwardCode] = useState({
        label: "",
        value: "",
    });

    const [textReasonForChanges, setTextReasonForChanges] = useState("");
    const [textRemarkS1, setTextRemarkS1] = useState("");
    const [textApprovalRemarks, setTextApprovalRemarks] = useState("");
    const [
        textApprovalStatusSpecialRemarks,
        setTextApprovalStatusSpecialRemarks,
    ] = useState("");
    const [textSpecialDetailRemarks, setTextSpecialDetailRemarks] = useState("");

    const [tobeDeletedScholarship, setTobeDeletedScholarship] = useState([]);

    const [statusOccupationList, setStatusOccupationList] = useState([]);

    const [paRemark, setPaRemark] = useState("");
    const [nypRemark, setNypRemark] = useState("");
    const [textDSpecialDetailRemarks, setTextDSpecialDetailRemarks] =
        useState("");

    const [isExpandAll, setIsExpandAll] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);

    const accordions = ["0", "1", "2", "3", "4", "5", "6", "7", "8"]

    const handleToggleAll = () => {
        if (isExpandAll) {
            setActiveKeys([]);
        } else {
            setActiveKeys(accordions.map(accordion => accordion));
        }
        setIsExpandAll(!isExpandAll);
    };

    const [fatherIndex, setFatherIndex] = useState(0)
    const [motherIndex, setMotherIndex] = useState(1)

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleClickEdit = () => {
        if (!retrieved) {
            toast.warning("No Data");
            return;
        }
        setIsDisabled(!isDisabled);
    };

    const getDetailofApplicationMaintenance = () => {
        getApplicationDetail(
            academicYear,
            selectedListExercise,
            // TEMP_EXERCISE,
            selectedListAdmNo
            // "240657P"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                    setRetrieved(false);
                } else {
                    toast.error(resp?.response?.data?.message);
                    setRetrieved(false);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
                setRetrieved(false);
            } else {
                if (resp.status == 200) {
                    if (Object.keys(resp?.data?.data).length > 0) {
                        setApplicationDetail(resp?.data?.data);

                        getDetailofStudentInformation();
                        setTextReasonForChanges(resp?.data?.data?.reason_for_changes);
                        setRetrieved(true);
                    } else {
                        toast.warning("Data not Found")
                    }
                }
            }

            // setApplicationDetail(content);
        });
    };

    const getListOfAcadYears = () => {
        getListAcadYears().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listYears = [];
                    resp?.data?.data?.map((item) => {
                        listYears.push({value: item?.acad_yr, label: item?.acad_yr});
                    });
                    setListAcadYears(listYears);
                }
            }
        });
    };

    const getListOfExercise = (year) => {
        getListExercise(year).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let listContent = [];
                    sortExerciseList(resp?.data?.data)?.map((item) => {
                        listContent.push({value: item?.exercise, label: item?.exercise});
                    });
                    setListExercise(listContent);
                }
            }
        });
    };

    const getListOfApplicationList = (params) => {
        getApplicationList({acad_yr: academicYear, exercise: params}).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        let listContent = [];
                        resp?.data?.data?.map((item) => {
                            if (listContent.some((e) => e?.value === item?.adm_no)) {
                            } else {
                                listContent.push({value: item?.adm_no, label: item?.adm_no});
                            }
                        });
                        setListAdmNo(listContent);
                    }
                }
            }
        );
    };

    const getListOfParents = () => {
        getParents(
            academicYear,
            // TEMP_EXERCISE,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data?.length > 0) {
                        resp?.data?.data?.map((content, index) => {
                            if (content?.relationship == "F") {
                                setFatherIndex(index)
                            }
                            if (content?.relationship == "M") {
                                setMotherIndex(index)
                            }
                        })
                        setListParent(resp?.data?.data);
                    } else {
                        toast.warning("Parent Data is empty");
                        setFatherIndex(0)
                        setMotherIndex(1)
                        setListParent([
                            {
                                relationship: "F",
                                id: "",
                                name: "",
                                status: "",
                                id_no: "",
                                mthly_income_contrib: "",
                                remark: "",
                                child_support: "",
                                birthdate: "",
                                other_income: null,
                                faas_occupations: "",
                            },
                            {
                                relationship: "M",
                                id: "",
                                name: "",
                                status: "",
                                id_no: "",
                                mthly_income_contrib: "",
                                remark: "",
                                child_support: "",
                                birthdate: "",
                                other_income: null,
                                faas_occupations: "",
                            },
                        ]);
                    }
                }
            }
        });
    };

    const getListOfSiblings = () => {
        getSiblings(
            academicYear,
            //   TEMP_EXERCISE,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    let contentSibling = resp?.data?.data;

                    if (contentSibling?.length > 0) {
                        contentSibling?.map((item, index) => {
                            statusOccupationList.map((content) => {
                                if (
                                    contentSibling[index]?.faas_occupations?.hasOwnProperty("id")
                                ) {
                                    if (
                                        contentSibling[index]?.faas_occupations?.id ===
                                        content?.value
                                    ) {
                                        contentSibling[index].occupations = {
                                            label: content.label,
                                            value: content.value,
                                            isIncomeContribute: content.is_income_contribute,
                                            isRemarkRequired: content.is_remark_required,
                                        };
                                    }
                                } else {
                                    if (
                                        contentSibling[index]?.faas_occupations == content?.value
                                    ) {
                                        contentSibling[index].occupations = {
                                            label: content.label,
                                            value: content.value,
                                            isIncomeContribute: content.is_income_contribute,
                                            isRemarkRequired: content.is_remark_required,
                                        };
                                    }
                                }
                            });
                        });
                    }

                    setListSibling(contentSibling);
                }
            }
        });
    };

    const handleDeleteSiblings = (id) => {
        deleteSiblings(id).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    return true;
                }
            }
        });
    };

    const handleAcademicYearChange = (e, isGetList = true) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
            if (isGetList) {
                getListOfExercise(newValue);
            }
        }
    };

    const handleListExerciseChange = (e, isGetAdmNo = true) => {
        const newValue = e.value;
        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
            setSelectedListExercise(newValue);
        }
        if (isGetAdmNo) {
            getListOfApplicationList(e.value);
        }
    };

    const handleListAdmNoChange = (e) => {
        const newValue = e.value;
        setSelectedListAdmNo(newValue);
    };

    const getDetailofStudentInformation = () => {
        getStudentDetail(selectedListAdmNo, academicYear).then((resp) => {

            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setStudentDetail(resp?.data?.data[0]);
                        setViewCCAList(resp?.data?.data[0]?.CCAList)
                    }
                }
            }
        });

        getStudentDetail(selectedListAdmNo, academicYear, {
            fa_rec_yr: academicYear,
            fa_rec_award: applicationDetail?.rec_award_code ?? "",
            fa_rec_award_type: applicationDetail?.rec_award_type ?? ""
        }).then((resp) => {

            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setStudentAwardDetail(resp?.data?.data[0]);
                    }
                }
            }
        });
    };

    const getLogs = () => {
        getDetailApplicationLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setOriginalDetail(resp?.data?.data);
                }
            }
        });

        getApplicationParentLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setParentLog(resp?.data?.data);
                }
            }
        });

        getApplicationSiblingLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setSiblingLog(resp?.data?.data);
                }
            }
        });
    };

    const getScholarships = () => {
        getScholarshipLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setListScholarshipLog(resp?.data?.data);
                }
            }
        });
        getScholarship(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        const scholarshipDatas = resp?.data?.data;
                        let counterForBursary = 0;
                        let counterForScholarship = 0;
                        const newScholarship = scholarshipDatas?.map((content, index) => {
                            if (content?.rec_tag == "B") {
                                return {
                                    ...content,
                                    counter: counterForBursary++
                                }

                            } else {
                                return {
                                    ...content,
                                    counter: counterForScholarship++
                                }


                            }

                        })
                        setListScholarship(newScholarship);
                    }
                }
            }
        );
    };

    const getDSpecialDetailRemarks = () => {
        getDSpecialDetails({
            father_status:
                listParent?.length > 0
                    ? listParent?.filter((content) => content?.relationship == "F")
                        ?.status
                    : "",
            mother_status:
                listParent?.length > 0
                    ? listParent?.filter((content) => content?.relationship == "M")
                        ?.status
                    : "",
            medical_history: applicationDetail?.medical_history
                ? applicationDetail?.medical_history
                : "",
            intact_family_tag: applicationDetail?.intact_family_tag
                ? applicationDetail?.intact_family_tag
                : "",
            mendaski_subsidy: studentDetail?.mendakiSubsidy
                ? studentDetail?.mendakiSubsidy
                : "",
            tutfee_loan: studentDetail?.tutFeeLoan ? studentDetail?.tutFeeLoan : "",
            cpf_approved_tag: applicationDetail?.cpf_approved_tag
                ? applicationDetail?.cpf_approved_tag
                : "",
            physical_status: applicationDetail?.physical_status
                ? applicationDetail?.physical_status
                : "",
            charge_in_court: applicationDetail?.charge_in_court
                ? applicationDetail?.charge_in_court
                : "",
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setTextDSpecialDetailRemarks(resp?.data?.data?.details);
                }
            }
        });
    };

    const handleSubmit = () => {
        getDetailofApplicationMaintenance();
        getListOfParents();
        getListOfSiblings();
        getLogs();
        getScholarships();
        getDSpecialDetailRemarks();
    };

    const submitToApplicationDetail = () => {
        //If the application status is ‘R’, the Recommended Award Type & Award fields are mandatory.
        if (
            applicationDetail?.appln_status === "R" &&
            !selectedAwardType.value &&
            !selectedAwardCode.value
        ) {
            toast.warning("Recommended Award Type and Award must be filled");
            return false;
        }
        
        if (
            applicationDetail?.appln_status === "A" &&
            !selectedAwardType.value &&
            !selectedAwardCode.value
        ) {
            toast.warning("Recommended Award Type and Award must be filled");
            return false;
        }

        //Approval Status Remark (PA)’ or ‘Approval Status Remark (NYP)’ field is mandatory if the application status is ‘N’.
        if (
            applicationDetail?.appln_status === "N" &&
            !textApprovalStatusSpecialRemarks &&
            nypRemark
        ) {
            toast.warning(
                "Approval Status Remark NYP and Approval Status Remark PA must be filled"
            );
            return false;
        }

        let variableToBeSubmitted = {
            data: [
                {
                    id: applicationDetail?.id,
                    acad_yr: academicYear,
                    exercise: selectedListExercise,
                    //   EXERCISE: TEMP_EXERCISE,
                    adm_no: selectedListAdmNo,
                    // DEPENDENT: "1",

                    independ_mthly_contrib: applicationDetail?.independ_mthly_contrib,
                    gross_income: applicationDetail?.gross_income,
                    ccc_income: applicationDetail?.ccc_income,
                    mpci: applicationDetail?.mpci,
                    family_members: applicationDetail?.family_members,

                    // FPCI: "300.00",
                    // PHYSICAL_STATUS: null,
                    // CHARGE_IN_COURT: "Y",
                    // PS_CIC_DETAILS: "Y",
                    verify_status: selectedVerificationStatus.value,
                    appln_status: selectedApplicationStatus.value,
                    data_flag: selectedDataFlag.value,

                    reason_for_changes: textReasonForChanges,
                    // Approval status remarks (NYP)
                    // REMARK_DESC: nypRemark,
                    remark: nypRemark,

                    // Approval status remarks (PA)
                    approval_status_reason: paRemark,
                    // Special details == Special Details Remark
                    special_details: textSpecialDetailRemarks,
                    // Special Note == Approval Status Special Remarks
                    special_note: textApprovalStatusSpecialRemarks,
                    // Application REMARK
                    // REMARKS1: textApprovalRemarks,

                    // REMARK_DESC: nypRemark,
                    // Application Detail Remark
                    remarks1: applicationDetail?.remarks1,

                    rec_award_type: selectedAwardType.value,
                    rec_award_code: selectedAwardCode.value,

                    comp_sponsor_tag: applicationDetail?.comp_sponsor_tag,
                    charge_in_court: applicationDetail?.charge_in_court,
                    moe_subsidised: applicationDetail?.moe_subsidised,
                    intact_family_tag: applicationDetail?.intact_family_tag,
                    cpf_approved_tag: applicationDetail?.cpf_approved_tag,

                    // PRINT_APPLN_FORM: null,
                    // DOWNLOAD_DATE: null,
                    last_upd_id: sessionStorage.getItem("staffId")
                    ? sessionStorage.getItem("staffId")
                    : process.env.REACT_APP_LAST_UPD_ID,
                    // MEDICAL_HISTORY: null,
                    // INTACT_FAMILY_TAG: null,
                    // CPF_APPROVED_TAG: null,
                    // RECOMMENDED_DATE: null,
                    // FORM_PRINTED_DATE: null,

                    // SCHOLAR_SPONSOR_TAG: "Y",
                    // MTHLY_INCOME: "150.00",
                    marital_status: maritalStatus.value,
                    // REMARKS1: textRemarkS1,
                    dialect_grp: dialectGroup.value,
                    homehousetype: housingType.value,
                    // COMP_SPONSOR_TAG: "Y",
                    // MOE_SUBSIDISED: "Y",
                    other_income: applicationDetail?.other_income,
                    // SPECIAL_CIRCUMSTANCES: null,
                    verify_status_by:
                        applicationDetail?.verify_status == null && 
                        selectedVerificationStatus.value == "I"
                            ? sessionStorage.getItem("staffId")
                                ? sessionStorage.getItem("staffId")
                                : process.env.REACT_APP_LAST_UPD_ID
                            : applicationDetail?.verify_status_by,
                    verify_status_date:
                        applicationDetail?.verify_status == null && 
                        selectedVerificationStatus.value == "I"
                            ? moment(Date.now())
                                .tz("Asia/Singapore")
                                .format("yyyy-MM-DD HH:mm:ss")
                            : applicationDetail?.verify_status_date,
                    // COMCARE: null,
                    // MTC_APPEAL_REMARK: null,
                    // SPECIAL_CASE: null,
                    // GOVBUR_TAG: null,
                },
            ],
        };

        editApplicationDetail(variableToBeSubmitted).then((resp) => {
            handleClickEdit();
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                    setSuccessApplication(!successApplication)
                } else {
                    toast.error(resp?.response?.data?.message);
                    setSuccessApplication(!successApplication)
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
                setSuccessApplication(!successApplication)
            } else {
                if (resp.status == 200) {
                    const appln_status = selectedApplicationStatus.value;
                    if (appln_status == "A" || appln_status == "R") {
                        // get amount awarded
                        getAwardListWithParams(selectedAwardType.value, selectedAwardCode.value).then((resp) => {
                            if (resp.status == 200) {
                                const quantumValue = resp?.data?.data?.[0]?.quantum;
            
                                // submit awarded to finanists
                                saveStudentFinassists([{
                                    adm_no: selectedListAdmNo,
                                    award: selectedAwardCode.value,
                                    award_type: selectedAwardType.value,
                                    year_awarded: academicYear,
                                    amt_awarded: quantumValue,
                                    action: "I",
                                    last_upd_id: sessionStorage.getItem("staffId")
                                    ? sessionStorage.getItem("staffId")
                                    : process.env.REACT_APP_LAST_UPD_ID
                               },])


                            }
                        })
                    }


                    toast.success("Application has been saved");
                }
            }
        });
    };

    const submitToParentList = () => {
        
        let canSubmit = true;
        listParent.forEach(function (v) {
            const validChars = ["S", "T", "F", "G", "M"];
            if (validChars.includes(v.id_no.charAt(0))) {
                if (!validateNRICFIN(v.id_no)) {
                    canSubmit = false;
                    toast.error(v.name + " parent is not a valid NRIC/Passport");
                    return false;
                }
            }
            delete v.occupation;
        });

        if (canSubmit) {
            updateParents({data: listParent}).then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        toast.success("Parent data has been saved");
                    }
                }
            });
        }
    };

    const submitToSiblingList = () => {
        let canSubmit = true;
        listSibling.forEach(function (v) {
            const validChars = ["S", "T", "F", "G", "M"];
            if (validChars.includes(v.id_no.charAt(0))) {
                if (!validateNRICFIN(v.id_no)) {
                    canSubmit = false;
                    toast.error(v.NAME + " sibling is not a valid NRIC/Passport");
                    return;
                }
            }
            delete v.occupations;
            delete v.occupation;
            delete v.isIncomeContribute;
            delete v.isRemarkRequired;
        });

        if (canSubmit) {
            updateSiblings({data: listSibling}).then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        toast.success("Sibling data has been saved");
                    }
                }
            });
        }
    };

    const submitToScholarship = () => {
        let otherParams = {
            acad_yr: academicYear,
            adm_no: selectedListAdmNo,
            exercise: selectedListExercise,
        };

        listScholarship.map((scholarship) => {
            delete scholarship.counter
        })

        upsertScholarship({
            data: listScholarship.map((scholarship) => ({
                ...scholarship,
                ...otherParams,
            })),
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    handleSubmit()
                    toast.success("Scholarship data has been saved");
                }
            }
        });

        if (tobeDeletedScholarship.length > 0) {
            tobeDeletedScholarship.forEach((id) => {
                deleteScholarship(id).then(() => {
                    console.log("Deleted");
                });
            });
        }
    };

    const handleSubmitApplicationForm = () => {
        submitToApplicationDetail();
        submitToParentList();
        submitToSiblingList();
        submitToScholarship();
    };

    const submitTransferToAnotherExercise = () => {
        transferApplicationExercise({
            acad_yr: academicYear,
            adm_no: selectedListAdmNo,
            exercise: selectedListExercise,
            new_exercise: transferToAnotherExerciseValue,
            staff_id: process.env.REACT_APP_LAST_UPD_ID,
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Success transfer to another exercise");
                }
            }
        });
    };

    const handleParentChange = (field, value, index) => {
        setListParent((prevState) => {
            return prevState.map((item, i) => {
                return i === index ? {...item, [field]: value} : item;
            });
        });
    };
    const handleSiblingChange = (field, value, index) => {
        setListSibling((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleApplicationChange = (field, value) => {
        setApplicationDetail((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChangeListScholarship = (field, value, index) => {
        setListScholarship((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleDataChange = (updatedData) => {
        setApplicationDetail(updatedData);
    };

    const handleExportCCA = () => {
        const wsData = [
            [
                "S/N",
                "CCA Point",
                "Course Code",
                "Classification",
                "CCA Activity",
                "Involve Date",
                "Endorse Status",
            ],
            ...viewCCAList.map((data, index) => [
                index + 1,
                data.ccaPoint,
                data.courseCode,
                data.classification,
                data.ccaActivity,
                data.involveDate,
                data.endorseStatus,
            ]),
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CCA Data");

        XLSX.writeFile(wb, "cca_data.xlsx");
    };

    const getListOfRemarks = () => {
        getRemarkNYP().then((response) => {
            setNYPRemarks(response?.data?.data);
        });

        getRemarkPA().then((response) => {
            setPARemarks(response?.data?.data);
        });
    };

    const getTotalSumIncome = (monthlyIncome = null, otherIncome = null) => {
        let countMember = 1;
        const sumIncomeSibling = listSibling?.reduce((accumulator, item) => {
            countMember++;
            return accumulator + Number(item?.gross_mthly_income);
        }, 0);

        const sumIncomeParent = listParent?.reduce((accumulator, item) => {
            countMember++;
            return (
                accumulator +
                Number(item?.mthly_income_contrib) +
                Number(item?.other_income)
            );
        }, 0);

        let monthlyIncomeCalc = 0;
        if (monthlyIncome == null) {
            monthlyIncomeCalc = Number(
                applicationDetail?.mthly_income == undefined
                    ? 0
                    : applicationDetail?.mthly_income
            );
        } else {
            monthlyIncomeCalc = Number(monthlyIncome);
        }

        let otherIncomeCalc = 0;
        if (otherIncome == null) {
            otherIncomeCalc = Number(
                applicationDetail?.other_income == undefined
                    ? 0
                    : applicationDetail?.other_income
            );
        } else {
            otherIncomeCalc = Number(otherIncome);
        }

        const sumIncomeApplicant = monthlyIncomeCalc + otherIncomeCalc;

        handleApplicationChange(
            "ccc_income",
            (sumIncomeSibling + sumIncomeParent + sumIncomeApplicant).toFixed(2)
        );
        handleApplicationChange("family_members", Number(countMember));
        handleApplicationChange(
            "mpci",
            sumIncomeSibling + sumIncomeParent + sumIncomeApplicant == 0
                ? 0
                : Number(
                    (sumIncomeSibling + sumIncomeParent + sumIncomeApplicant) /
                    countMember
                ).toFixed(2)
        );
    };

    const handleToggleAccordion = (num) => {
        if (activeKeys.includes(num)) {
            setActiveKeys(activeKeys.filter(key => key !== num));
        } else {
            setActiveKeys([...activeKeys, num]);
        }
    };

    useEffect(() => {
        getTotalSumIncome();
    }, [listSibling, listParent]);

    useEffect(() => {
        // getDetailofApplicationMaintenance();
        // getListOfExercise();
        // getListOfParents();
        getListOfAcadYears();
        // getListOfApplicationList();
        getListOfRemarks();
        getOccupationList().then((resp) => {
            if (resp?.status == 200) {
                let contentArray = [];
                resp?.data?.data?.map((content) => {
                    if (content?.obsolete_flag != "Y") {
                        contentArray.push({
                            label: content.occupation,
                            value: content.id,
                            is_income_contribute: content.income_contribution,
                            is_remark_required: content.remark,
                        });
                    }
                });
                setStatusOccupationList(contentArray);
            }
        });
    }, []);

    useEffect(() => {
        if (academicYearParams) {
            setAcademicYear(academicYearParams);
        }
        if (exerciseParams) {
            setSelectedListExercise(exerciseParams);
        }
        if (admNoParams) {
            setSelectedListAdmNo(admNoParams);
        }
    }, [searchParams]);

    useEffect(() => {
        if (
            academicYear &&
            selectedListExercise &&
            selectedListAdmNo &&
            !firstTImeSubmitApplication.current &&
            academicYearParams &&
            exerciseParams &&
            admNoParams
        ) {
            handleSubmit();
            firstTImeSubmitApplication.current = true;
        }
    }, [academicYear, selectedListExercise, selectedListAdmNo]);

    return (
        <Layout currentNav={"app-records"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Administer Application Records</Breadcrumb.Item>
                <Breadcrumb.Item active>Application Maintenance</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Application Maintenance</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        ref={contentRef}
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        style={{
                            overflow: isOpen ? "unset" : "hidden",
                            maxHeight: isOpen
                                ? `${
                                    contentRef?.current?.scrollHeight == null
                                        ? "100%"
                                        : contentRef?.current?.scrollHeight
                                }px`
                                : "0px",
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        value={{label: academicYear, value: academicYear}}
                                        options={listAcadYears}
                                        onChange={(e) => {
                                            handleAcademicYearChange(e);
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        value={{
                                            label: selectedListExercise,
                                            value: selectedListExercise,
                                        }}
                                        options={listExercise}
                                        onChange={(e) => handleListExerciseChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Adm. No</Form.Label>
                                    <Select
                                        value={{
                                            label: selectedListAdmNo,
                                            value: selectedListAdmNo,
                                        }}
                                        options={listAdmNo}
                                        onChange={(e) => handleListAdmNoChange(e)}
                                    />
                                </Form.Group>
                            </Col>

                            {/**
                             *
                             <Col xs={3}>
                             <Form.Group>
                             <Form.Label>ID No.</Form.Label>
                             <Select options={options} />
                             </Form.Group>
                             </Col>
                             <Col xs={12} className="mt-3">
                             <Form.Group>
                             <Form.Label>Name</Form.Label>
                             <Form.Control type={`text`} disabled={false}></Form.Control>
                             </Form.Group>
                             </Col>
                             *
                             *
                             */}
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button type="submit" className="btn btn-primary">
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>
                <div>
                    <h3>{studentDetail?.name}</h3>
                </div>
                <div>
                    <Tabs
                        defaultActiveKey="personal_particulars"
                        id="uncontrolled-tab-example2"
                        variant="tabs-basic-toggle"
                        className="nav-fill"
                    >
                        <Tab eventKey="personal_particulars" title="Personal Particulars">
                            <div className="d-flex justify-content-between">
                                {isDisabled ? (
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 "
                                        onClick={handleClickEdit}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {"  "}Edit
                                    </button>
                                ) : (
                                    <div className={`d-flex gap-3 flex-row`}>
                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-green-500"
                                            onClick={handleSubmitApplicationForm}
                                        >
                                            <i className={`bi bi-check-lg`}></i>
                                            {"  "}Save
                                        </button>

                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-red-500"
                                            onClick={handleClickEdit}
                                        >
                                            <i className={`bi bi-x`}></i>
                                            {"  "}Cancel
                                        </button>
                                    </div>
                                )}

                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>
                            </div>

                            <div className="mt-4">
                                <Accordion
                                    key={"0"}
                                    className=""
                                    activeKey={activeKeys}
                                    alwaysOpen
                                >
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("0")} className="header-text text-blue">
                                            Personal Data
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <PersonalData
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                setShowCCAModal={setShowCCAModal}
                                                isDisabled={isDisabled}
                                                maritalStatus={maritalStatus}
                                                setMaritalStatus={setMaritalStatus}
                                                dialectGroup={dialectGroup}
                                                setDialectGroup={setDialectGroup}
                                                housingType={housingType}
                                                setHousingType={setHousingType}
                                                setShowTransferToAnotherExerciseModal={
                                                    setShowTransferToAnotherExerciseModal
                                                }
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"1"}
                                           className=""
                                           activeKey={activeKeys}
                                           alwaysOpen>
                                    <Accordion.Item eventKey="1" className="shadow border-0">
                                        <Accordion.Header  onClick={() => handleToggleAccordion("1")} className="header-text text-blue">
                                            Application Details
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ApplicationDetail
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                isDisabled={isDisabled}
                                                selectedVerificationStatus={selectedVerificationStatus}
                                                setSelectedVerificationStatus={
                                                    setSelectedVerificationStatus
                                                }
                                                selectedApplicationStatus={selectedApplicationStatus}
                                                setSelectedApplicationStatus={
                                                    setSelectedApplicationStatus
                                                }
                                                selectedDataFlag={selectedDataFlag}
                                                setSelectedDataFlag={setSelectedDataFlag}
                                                selectedAwardType={selectedAwardType}
                                                setSelectedAwardType={setSelectedAwardType}
                                                selectedAwardCode={selectedAwardCode}
                                                setSelectedAwardCode={setSelectedAwardCode}
                                                textReasonForChanges={textReasonForChanges}
                                                setTextReasonForChanges={setTextReasonForChanges}
                                                textRemarkS1={textRemarkS1}
                                                setTextRemarkS1={setTextRemarkS1}
                                                textApprovalRemarks={textApprovalRemarks}
                                                setTextApprovalRemarks={setTextApprovalRemarks}
                                                textApprovalStatusSpecialRemarks={
                                                    textApprovalStatusSpecialRemarks
                                                }
                                                setTextApprovalStatusSpecialRemarks={
                                                    setTextApprovalStatusSpecialRemarks
                                                }
                                                textSpecialDetailRemarks={textSpecialDetailRemarks}
                                                setTextSpecialDetailRemarks={
                                                    setTextSpecialDetailRemarks
                                                }
                                                isReadOnly={false}
                                                PARemarks={PARemarks}
                                                NYPRemarks={NYPRemarks}
                                                setPaRemark={setPaRemark}
                                                setNypRemark={setNypRemark}
                                                textDSpecialDetailRemarks={textDSpecialDetailRemarks}
                                                handleApplicationChange={handleApplicationChange}
                                                successApplication={successApplication}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion key={"2"}
                                           className=""
                                           activeKey={activeKeys}
                                           alwaysOpen>
                                    <Accordion.Item eventKey="2" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("2")} className="header-text text-blue">
                                            Awarded
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Awarded
                                                data={applicationDetail}
                                                studentDetail={studentDetail}
                                                studentAwardDetail={studentAwardDetail}
                                                isDisabled={isDisabled}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="family_details" title="Family Details">
                            <div className="d-flex justify-content-between">
                                <div className={`d-flex gap-3 flex-row`}>
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 text-green-500"
                                        onClick={handleSubmitApplicationForm}
                                    >
                                        <i className={`bi bi-check-lg`}></i>
                                        {"  "}Save
                                    </button>

                                </div>
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>

                            </div>

                            <div className="mt-4">
                                <Accordion
                                    className=""
                                    key={"3"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="3" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("3")} className="header-text text-blue">
                                            Father
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Father
                                                handleParentChange={handleParentChange}
                                                listParent={listParent}
                                                setListParent={setListParent}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={false}
                                                handleApplicationChange={handleApplicationChange}
                                                getTotalSumIncome={getTotalSumIncome}
                                                fatherIndex={fatherIndex}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion
                                    key={"4"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="4" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("4")} className="header-text text-blue">
                                            Mother
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Mother
                                                handleParentChange={handleParentChange}
                                                listParent={listParent}
                                                setListParent={setListParent}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={false}
                                                getTotalSumIncome={getTotalSumIncome}
                                                motherIndex={motherIndex}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion
                                    key={"5"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="5" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("5")} className="header-text text-blue">
                                            Siblings / Relatives
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Sibling
                                                handleSiblingChange={handleSiblingChange}
                                                listSibling={listSibling}
                                                setListSibling={setListSibling}
                                                getTotalSumIncome={getTotalSumIncome}
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={false}
                                                statusOccupationList={statusOccupationList}
                                                handleDeleteSiblings={handleDeleteSiblings}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <Accordion
                                    key={"6"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="6" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("6")} className="header-text text-blue">
                                            Applicant
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Applicant
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={false}
                                                handleApplicationChange={handleApplicationChange}
                                                getTotalSumIncome={getTotalSumIncome}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="result_details" title="Result Details">
                            <div className="d-flex justify-content-between">
                                {isDisabled ? (
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 rounded-2 "
                                        onClick={handleClickEdit}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {"  "}Edit
                                    </button>
                                ) : (

                                    <div className={`d-flex gap-3 flex-row`}>
                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-green-500"
                                            onClick={handleSubmitApplicationForm}
                                        >
                                            <i className={`bi bi-check-lg`}></i>
                                            {"  "}Save
                                        </button>

                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-red-500"
                                            onClick={handleClickEdit}
                                        >
                                            <i className={`bi bi-x`}></i>
                                            {"  "}Cancel
                                        </button>
                                    </div>
                                )}
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>

                            </div>

                            <ResultDetails
                                data={applicationDetail}
                                studentDetail={studentDetail}
                                isDisabled={isDisabled}
                                activeKeys={activeKeys}
                            />
                        </Tab>
                        <Tab eventKey="other_details" title="Other Details">
                            <div className="d-flex justify-content-between">
                                {isDisabled ? (
                                    <button
                                        className="bg-white border-1 px-3 rounded-2"
                                        onClick={handleClickEdit}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {"  "}Edit
                                    </button>
                                ) : (
                                    <div className={`d-flex gap-3 flex-row`}>
                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-green-500"
                                            onClick={handleSubmitApplicationForm}
                                        >
                                            <i className={`bi bi-check-lg`}></i>
                                            {"  "}Save
                                        </button>

                                        <button
                                            className="bg-white border-1 px-3 rounded-2 text-red-500"
                                            onClick={handleClickEdit}
                                        >
                                            <i className={`bi bi-x`}></i>
                                            {"  "}Cancel
                                        </button>
                                    </div>
                                )}
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>

                            </div>

                            <OtherDetails
                                isDisabled={isDisabled}
                                details={applicationDetail}
                                studentDetail={studentDetail}
                                onDataChange={handleDataChange}
                                handleApplicationChange={handleApplicationChange}
                                activeKeys={activeKeys}
                            />

                            <div className="mt-4">
                                <Accordion
                                    key={"0"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="0" className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("7")} className="header-text text-blue">
                                            Scholarship / Sponsorship
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <OtherScholarshipBeneficiary
                                                scholarshipDetail={listScholarship}
                                                setListScholarship={setListScholarship}
                                                handleChangeListScholarship={
                                                    handleChangeListScholarship
                                                }
                                                tobeDeletedScholar={tobeDeletedScholarship}
                                                handleChangeTobeDeleted={(ids) =>
                                                    setTobeDeletedScholarship(ids)
                                                }
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="mt-4">
                                <Accordion
                                    key={"8"}
                                    activeKey={activeKeys}>
                                    <Accordion.Item eventKey="0"  className="shadow border-0">
                                        <Accordion.Header onClick={() => handleToggleAccordion("8")} className="header-text text-blue">
                                            Government Bursary
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <GovernmentBursary
                                                scholarshipDetail={listScholarship}
                                                setListScholarship={setListScholarship}
                                                handleChangeListScholarship={
                                                    handleChangeListScholarship
                                                }
                                                tobeDeletedScholar={tobeDeletedScholarship}
                                                handleChangeTobeDeleted={(ids) =>
                                                    setTobeDeletedScholarship(ids)
                                                }
                                                data={applicationDetail}
                                                studentDetail={setShowFormulaModal}
                                                setShowFormulaModal={setShowFormulaModal}
                                                isDisabled={isDisabled}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Tab>
                        <Tab eventKey="original_details" title="Original Details">
                            <div className="d-flex justify-content-between">
                                <div className=""></div>
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleToggleAll}
                                >
                                    {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                    {" "}
                                    {!isExpandAll ? "Expand All" : "Collapse All"}
                                </button>
                            </div>

                            <OriginalDetails
                                isDisabled={isDisabled}
                                details={originalDetail}
                                parentLog={parentLog}
                                siblingLog={siblingLog}
                                studentDetail={studentDetail}
                                handleShowFormulaModal={() => setShowFormulaModal(true)}
                                listScholarshipLog={listScholarshipLog}
                                activeKeys={activeKeys}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </sgds-content-body>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
            <CustomModal
                show={showCCAModal}
                handleClose={() => setShowCCAModal(!showCCAModal)}
                title="View CCA"
                description={
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>S/N</Table.HeaderCell>
                                <Table.HeaderCell>CCA Point</Table.HeaderCell>
                                <Table.HeaderCell>Course Code</Table.HeaderCell>
                                <Table.HeaderCell>Classification</Table.HeaderCell>
                                <Table.HeaderCell>CCA Activity</Table.HeaderCell>
                                <Table.HeaderCell>Involve Date</Table.HeaderCell>
                                <Table.HeaderCell>Endorse Status</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {viewCCAList?.map((data, index) => {
                                return (
                                    <Table.Row>
                                        <Table.DataCell>{index + 1}</Table.DataCell>
                                        <Table.DataCell>{data?.ccaPoint}</Table.DataCell>
                                        <Table.DataCell>{data?.courseCode}</Table.DataCell>
                                        <Table.DataCell>{data?.classification}</Table.DataCell>
                                        <Table.DataCell>{data?.ccaActivity}</Table.DataCell>
                                        <Table.DataCell>{data?.involveDate}</Table.DataCell>
                                        <Table.DataCell>{data?.endorseStatus}</Table.DataCell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                }
                action={handleExportCCA}
                submitButtonText={"Export to Excel"}
                // hideSubmitButton={true}
            />
            <CustomModal
                show={showTransferToAnotherExerciseModal}
                handleClose={() =>
                    setShowTransferToAnotherExerciseModal(
                        !showTransferToAnotherExerciseModal
                    )
                }
                title="Transfer To Another Exercise"
                description={
                    <>
                        <div>
                            <p>Current Info:</p>
                            <div>
                                Academy Year: {academicYear}
                                <br/>
                                Exercise: {selectedListExercise}
                                <br/>
                                Adm. No.: {selectedListAdmNo}
                            </div>
                        </div>
                        <Form.Group>
                            <Form.Label>Transfer to Exercise</Form.Label>
                            <Form.Control
                                className=""
                                type={`text`}
                                // disabled={true}
                                value={transferToAnotherExerciseValue}
                                onChange={(e) =>
                                    setTransferToAnotherExerciseValue(e.target.value)
                                }
                            ></Form.Control>
                        </Form.Group>
                    </>
                }
                action={() => submitTransferToAnotherExercise()}
                submitButtonText={"Submit"}
            />
        </Layout>
    );
}

export default AppMaintenance;

export function ApplicationStatus() {
  return [
    { label: "", value: null },
    { label: "R - Recommended", value: "R" },
    { label: "P - Pending Approved", value: "P" },
    { label: "C - Pending Approved with Changes", value: "C" },
    { label: "A - Approved", value: "A" },
    { label: "N - Not Successful", value: "N" },
    { label: "E - Rescind", value: "E" },
  ];
}

export function VerificationStatus() {
  return [
    { label: "", value: null },
    { label: "I (Include)", value: "I" },
    { label: "E (Exclude)", value: "E" },
    { label: "P (Pending Document)", value: "P" },
  ];
}

export function DataFlagStatus() {
  return [
    { label: "", value: null },
    { label: "W - Withdrawn", value: "W" },
    { label: "H - Withhold", value: "H" },
    { label: "R - Regular", value: "R" },
    { label: "U - Update", value: "U" },
  ];
}

export function appVerificationOrderBy() {
  return [
    { label: "", value: null },
    { label: "Adm No.", value: "adm_no" },
    { label: "CCC Income ", value: "ccc_income" },
    { label: "MPCI", value: "mpci" },
    // { label: "FPCI & Name", value: "fpci" },
    // { label: "GHI & Name", value: "gross_income" },
  ];
}

export function specialMeansStatus() {
  return [
    { label: "", value: null },
    { label: "None", value: "NO" },
    { label: "Dual Income", value: "DI" },
    { label: "Change In Income", value: "CI" },
    { label: "Loss of Income", value: "LI" },
    { label: "Odd-Job Worker", value: "OJ" },
    { label: "MHA Invest", value: "MI" },
    { label: "Casual Workers", value: "CW" },
    { label: "Others", value: "OT" },
  ];
}

export function appRecommendationOrderBy() {
  return [
    { label: "", value: null },
    { label: "CCC Income", value: "ccc_income" },
    { label: "Gross Income", value: "gross_income" },
    { label: "MPCI", value: "mpci" },
    { label: "MPCI, Family Members", value: "mpci,family_members" },
    // { label: "MPCI, GPA, Family Members & Name", value: "mpci,family_members"},
    // { label: "CWA, Study Stage & Name", value: "adm_no" },
    // { label: "GPA, Study Stage & Name", value: "ADM_NO" },
    // { label: "Entryqual, Agg/GPA", value: "adm_no" },
    { label: "Adm. No.", value: "adm_no" },
    // { label: "MPCI, Housing Type, Family Member", value: "MPCI,FAMILY_MEMBERS"},
  ];
}

export function LivingAllowance() {
  return [
    { label: "", value: null },
    { label: "M (Monthly)", value: "M" },
    { label: "A (Annual)", value: "A" },
    { label: "O (One-off)", value: "O" },
  ];
}

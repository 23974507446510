import { createUrlWithParams } from "@/components/utils/utils";
import { apiCall } from "@/utils/apiCall";

export async function GetApplications(
  ACAD_YR = "",
  EXERCISE = "",
  ADM_NO = "",
  APPLN_STATUS = "",
  VERIFY_STATUS = "",
  REC_AWARD_TYPE = "",
  REC_AWARD_CODE = "",
  orderByField = "",
  orderByDirection = "DESC"
) {
  let params = {};
  if (ACAD_YR !== null) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null) {
    params = {
      ...params,
      exercise: EXERCISE,
    };
  }
  if (ADM_NO !== null) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }
  if (APPLN_STATUS !== null) {
    params = {
      ...params,
      appln_status: APPLN_STATUS,
    };
  }
  if (VERIFY_STATUS !== null) {
    params = {
      ...params,
      verify_status: VERIFY_STATUS,
    };
  }
  if (REC_AWARD_TYPE !== null) {
    params = {
      ...params,
      rec_award_type: REC_AWARD_TYPE,
    };
  }
  if (REC_AWARD_CODE !== null) {
    params = {
      ...params,
      rec_award_code: REC_AWARD_CODE,
    };
  }

  if(orderByField !== null && orderByField !== "") {
    params = {
      ...params,
      orderByField: orderByField,
    };
  }

  params = {
    ...params,
    orderByDirection: orderByDirection,
    appln_status_not_equals: "D",
  };

  let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(
    url,
    {},
    "GET"
  );
}

export async function GetShortlistApplicants(
  ACAD_YR = 2025,
  EXERCISE = 11,
  REC_AWARD_TYPE = "",
  REC_AWARD_CODE = "",
  course_tag = "Y"
) {
  let params = {};
  if (ACAD_YR !== null) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }
  if (EXERCISE !== null) {
    params = {
      ...params,
      exercise: EXERCISE,
    };
  }
  if (REC_AWARD_TYPE !== null) {
    params = {
      ...params,
      rec_award_type: REC_AWARD_TYPE,
    };
  }
  if (REC_AWARD_CODE !== null) {
    params = {
      ...params,
      rec_award_code: REC_AWARD_CODE,
    };
  }
  if (course_tag !== null) {
    params = {
      ...params,
      course_tag: course_tag,
    };
  }

  params = {
    ...params,
    appln_status: "R",
    appln_status_not_equals: "D"
  };

  let baseUrl = process.env.REACT_APP_API_URL + `/applications`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(
    url,
    {},
    "GET"
  );
}

export async function getRecommendedApplicant(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  REC_AWARD_TYPE,
  REC_AWARD_CODE,
  RECOMMENDED_DATE_FROM,
  RECOMMENDED_DATE_TO,
  FORM_PRINTED_DATE_FROM,
  FORM_PRINTED_DATE_TO,
  ORDER_BY_FIELD = "CREATED_AT",
  ORDER_BY_DIRECTION = "DESC"
) {
  let params = {};
  if (ACAD_YR !== null && ACAD_YR !== undefined) {
    params = {
      ...params,
      acad_yr: ACAD_YR,
    };
  }

  if (EXERCISE !== null && EXERCISE !== undefined) {
    params = {
      ...params,
      exercise: EXERCISE,
    };
  }
  if (ADM_NO !== null && ADM_NO !== undefined) {
    params = {
      ...params,
      adm_no: ADM_NO,
    };
  }

  if (REC_AWARD_TYPE !== null && REC_AWARD_TYPE !== undefined) {
    params = {
      ...params,
      rec_award_type: REC_AWARD_TYPE,
    };
  }

  if (REC_AWARD_CODE !== null && REC_AWARD_CODE !== undefined) {
    params = {
      ...params,
      rec_award_code: REC_AWARD_CODE,
    };
  }
  if (RECOMMENDED_DATE_FROM !== null) {
    params = {
      ...params,
      recommended_date_from: RECOMMENDED_DATE_FROM,
    };
  }
  if (RECOMMENDED_DATE_TO !== null) {
    params = {
      ...params,
      recommended_date_to: RECOMMENDED_DATE_TO,
    };
  }
  if (FORM_PRINTED_DATE_FROM !== null) {
    params = {
      ...params,
      form_printed_date_from: FORM_PRINTED_DATE_FROM,
    };
  }
  if (FORM_PRINTED_DATE_TO !== null) {
    params = {
      ...params,
      form_printed_date_to: FORM_PRINTED_DATE_TO,
    };
  }

  if (ORDER_BY_FIELD !== null) {
    params = {
      ...params,
      orderByField: ORDER_BY_FIELD,
    };
  }

  if (ORDER_BY_DIRECTION !== null) {
    params = {
      ...params,
      orderByDirection: ORDER_BY_DIRECTION,
    };
  }
  let baseUrl =
    process.env.REACT_APP_API_URL +
    `/applications/reports/recommends/applicants`;
  const url = createUrlWithParams(baseUrl, params);
  return apiCall(
    url,
    {},
    "GET"
  );
}

export async function getApplicantSummary(ACAD_YR, EXERCISE) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/reports/applicants/summaries?acad_yr=${ACAD_YR}&exercise=${EXERCISE}`,
    {},
    "GET"
  );
}

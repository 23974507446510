import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import {upsertScholarship,} from "@/apis/app-records/maintenance/api";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export default function ScholarshipDetails({
                                               isDisabled,
                                               applicationDetail,
                                               listMTCRequestResult,
                                               listMTCDetails,
                                               listMTIncomes,
                                               listMTConsents,
                                               listMTCases,
                                               listMTCaseNotes,
                                               listOriginalDetails,
                                               studentDetail,
                                               handleClickEdit,
                                               handleChange,
                                               handleChangeListScholarship,
                                               scholarshipDetail,
                                               setScholarshipDetail,
                                               submitToScholarships,
                                               activeKeys
                                           }) {
    const [appDetail, setAppDetail] = useState({});
    const [schoDetail, setSchoDetail] = useState({});
    const [applyingScholar, setApplyingScholar] = useState(false);
    const [applyingGovBursary, setApplyingGovBursary] = useState(false);

    const handleChangeApp = (stateName, field, value) => {
        if (stateName === "appDetail") {
            setAppDetail((prev) => ({
                ...prev,
                [field]: value,
            }));
        } else if (stateName === "schoDetail") {
            setSchoDetail((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    const handleAddNewScholarship = (rec_tag) => {
        setScholarshipDetail([
            ...scholarshipDetail,
            {
                scholar_sponsor: "",
                tutfee_c_tag: "",
                l_allowance_c_tag: "",
                l_allowance_c_details: "0",
                l_allowance_c_type: "",
                others_c_tag: "",
                others_c_details: "",
                govbur_period: "",
                govbur_quantum: "",
                rec_tag: rec_tag,
            },
        ]);
    };

    const handleSubmitForm = () => {
        const dataApplication = {
            id: appDetail.id,
            charge_in_court: appDetail.charge_in_court,
            remarks1: appDetail.remarks1,
            remark: appDetail.remark,
            cpf_approved_tag: appDetail.cpf_approved_tag,
            intact_family_tag: appDetail.intact_family_tag,
            comp_sponsor_tag: appDetail.comp_sponsor_tag,
            moe_subsidised: appDetail.moe_subsidised,
        };

        const dataScholarship = {
            id: schoDetail.id,
            scholar_sponsor: schoDetail.scholar_sponsor,
            tutfee_c_tag: schoDetail.tutfee_c_tag,
            l_allowance_c_tag: schoDetail.l_allowance_c_tag,
            l_allowance_c_details: schoDetail.l_allowance_c_details,
            l_allowance_c_type: schoDetail.l_allowance_c_type,
            others_c_tag: schoDetail.others_c_tag,
            others_c_details: schoDetail.others_c_details,
        };

        /*
        submitApplicationDetail(dataApplication)
          .then((resp) => {
            if (resp?.response?.data?.message) {
              if (
                resp?.response?.data?.message?.length > 0 &&
                Array.isArray(resp?.response?.data?.message)
              ) {
                resp?.response?.data?.message?.map((contentErr) => {
                  toast.error(contentErr?.msg);
                });
              } else {
                toast.error(resp?.response?.data?.message);
              }
            } else if (resp?.response?.status == 404) {
              toast.error("Data not found");
            } else {
              toast.success("Record updated successfully");
            }
          })
          .catch((error) => {
            console.log(error);
          });
          */

        upsertScholarship(dataScholarship)
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success("Record updated successfully");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setSchoDetail(scholarshipDetail);

        const hasScholarshipDetail = scholarshipDetail?.length > 0;

        const hasGovbur = scholarshipDetail?.some(item => item?.rec_tag === "B");
        const hasScholarship = scholarshipDetail?.some(item => item?.rec_tag === "" || item?.rec_tag === null);

        if (hasScholarshipDetail) {
            if (hasGovbur) {
                setApplyingGovBursary(true)
            }

            if (hasScholarship) {
                setApplyingScholar(true);
            }
        } else {
            setApplyingGovBursary(false)
            setApplyingScholar(false);
        }
    }, [applicationDetail, scholarshipDetail]);

    return (
        <>
            {/*
      <div>
        {isDisabled ? (
          <button
            className="bg-white border-1 px-3 rounded-2 "
            onClick={handleClickEdit}
          >
            <i className={`bi bi-pencil`}></i>
            {"  "}Edit
          </button>
        ) : (
          <button
            className="bg-white border-1 px-3 rounded-2 text-green-500"
            onClick={handleSubmitForm}
          >
            <i className={`bi bi-check-lg`}></i>
            {"  "}Save
          </button>
        )}
      </div>

    */}

            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Scholarship
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Sponsor (NYP)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.sponsorNypDesc}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Tuition Fees</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.tutFeeLoan}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Mendaki tertiary Fees Subsidiary</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={studentDetail?.mendakiSubsidy}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Text>
                                                I am applying / receiving a scholarship or sponsorship
                                                in the current academic year
                                            </Form.Text>
                                            <Form.Check
                                                id="option-1"
                                                label="Yes"
                                                disabled={isDisabled}
                                                checked={applyingScholar}
                                                onChange={() => setApplyingScholar(true)}
                                            ></Form.Check>
                                            <Form.Check
                                                id="option-2"
                                                label="No"
                                                disabled={isDisabled}
                                                checked={!applyingScholar}
                                                onChange={() => setApplyingScholar(false)}
                                            ></Form.Check>
                                        </Form.Group>
                                    </Col>
                                    {applyingScholar ? (
                                        <>
                                            <div>
                                                <Button
                                                    type="button"
                                                    variant="btn btn-link ps-0 text-decoration-none"
                                                    onClick={() => handleAddNewScholarship("")}
                                                >
                                                    <i class="bi bi-plus-circle"></i> {"  "} Add
                                                    Scholarship
                                                </Button>
                                            </div>

                                            {scholarshipDetail?.length > 0
                                                ? scholarshipDetail?.map((content, index) => {
                                                    if (content?.rec_tag !== "B") {
                                                        return (
                                                            <React.Fragment>
                                                                <Col xs={12}>
                                                                    <Form.Label>
                                                                        #{index + 1} Scholar
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Name of Scholarship / Sponsorship
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            disabled={isDisabled}
                                                                            value={content?.scholar_sponsor}
                                                                            onChange={(e) => {
                                                                                handleChangeListScholarship(
                                                                                    "scholar_sponsor",
                                                                                    e.target.value,
                                                                                    index
                                                                                );
                                                                            }}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>Facilities</Form.Label>
                                                                        <Form.Check
                                                                            id="option-3"
                                                                            label="Tuition Fees"
                                                                            disabled={isDisabled}
                                                                            checked={content?.tutfee_c_tag === "Y"}
                                                                            onChange={(e) => {
                                                                                handleChangeListScholarship(
                                                                                    "tutfee_c_tag",
                                                                                    e.target.checked ? "Y" : "",
                                                                                    index
                                                                                );
                                                                            }}
                                                                        ></Form.Check>
                                                                    </Form.Group>

                                                                    <Form.Check
                                                                        id="option-4"
                                                                        label="Living Allowance"
                                                                        disabled={isDisabled}
                                                                        checked={
                                                                            content?.l_allowance_c_tag === "Y"
                                                                        }
                                                                        onChange={(e) => {
                                                                            handleChangeListScholarship(
                                                                                "l_allowance_c_tag",
                                                                                e.target.checked ? "Y" : "",
                                                                                index
                                                                            );
                                                                        }}
                                                                    ></Form.Check>

                                                                    {content?.l_allowance_c_tag === "Y" ? (
                                                                        <Row className={`ms-4`}>
                                                                            <Col xs={6}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Amount</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        disabled={isDisabled}
                                                                                        value={
                                                                                            content?.l_allowance_c_details
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            handleChangeListScholarship(
                                                                                                "l_allowance_c_details",
                                                                                                e.target.value,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    ></Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col xs={6}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Type</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        disabled={isDisabled}
                                                                                        value={
                                                                                            content?.l_allowance_c_type
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            handleChangeListScholarship(
                                                                                                "l_allowance_c_type",
                                                                                                e.target.value,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    ></Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : null}

                                                                    <Form.Check
                                                                        id="option-5"
                                                                        label="Others (please specify)"
                                                                        disabled={isDisabled}
                                                                        checked={content?.others_c_tag === "Y"}
                                                                        onChange={(e) => {
                                                                            handleChangeListScholarship(
                                                                                "others_c_tag",
                                                                                e.target.checked ? "Y" : "",
                                                                                index
                                                                            );
                                                                        }}
                                                                    ></Form.Check>
                                                                    {content?.others_c_tag === "Y" ? (
                                                                        <Row className={`ms-4`}>
                                                                            <Col xs={12}>
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Other coverage Details
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        disabled={isDisabled}
                                                                                        value={content?.others_c_details}
                                                                                        onChange={(e) => {
                                                                                            handleChangeListScholarship(
                                                                                                "others_c_details",
                                                                                                e.target.value,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    ></Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    ) : null}
                                                                </Col>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })
                                                : ""}
                                        </>
                                    ) : null}
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Government Bursary
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Text>
                                                I am receiving a government bursary in the current
                                                academic year
                                            </Form.Text>
                                            <Form.Check
                                                id="option-gov-1"
                                                label="Yes"
                                                disabled={isDisabled}
                                                checked={applyingGovBursary}
                                                onChange={() => setApplyingGovBursary(true)}
                                            ></Form.Check>
                                            <Form.Check
                                                id="option-gov-2"
                                                label="No"
                                                disabled={isDisabled}
                                                checked={!applyingGovBursary}
                                                onChange={() => setApplyingGovBursary(false)}
                                            ></Form.Check>
                                        </Form.Group>
                                    </Col>
                                    {applyingGovBursary ? (
                                        <>
                                            <div>
                                                <Button
                                                    type="button"
                                                    variant="btn btn-link ps-0 text-decoration-none"
                                                    onClick={() => handleAddNewScholarship("B")}
                                                >
                                                    <i class="bi bi-plus-circle"></i> {"  "} Add Gov
                                                    Bursary
                                                </Button>
                                            </div>

                                            {scholarshipDetail?.length > 0
                                                ? scholarshipDetail?.map((content, index) => {
                                                    if (content?.rec_tag === "B") {
                                                        return (
                                                            <React.Fragment>
                                                                <Col xs={12}>
                                                                    <Form.Label>
                                                                        #{index + 1} Government Bursary
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Name of Government Bursary
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            disabled={isDisabled}
                                                                            value={content?.scholar_sponsor}
                                                                            onChange={(e) => {
                                                                                handleChangeListScholarship(
                                                                                    "scholar_sponsor",
                                                                                    e.target.value,
                                                                                    index
                                                                                );
                                                                            }}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Form.Group>
                                                                        {" "}
                                                                        <Form.Label>Quantum</Form.Label>
                                                                        <Form.Control
                                                                            type={`text`}
                                                                            disabled={isDisabled}
                                                                            value={content?.govbur_quantum}
                                                                            onChange={(e) => {
                                                                                handleChangeListScholarship(
                                                                                    "govbur_quantum",
                                                                                    e.target.value,
                                                                                    index
                                                                                );
                                                                            }}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Form.Group
                                                                        className={`d-flex flex-column`}
                                                                    >
                                                                        <Form.Label>Period</Form.Label>
                                                                        <DatePicker
                                                                            selected={content?.govbur_period ? moment(`${content.govbur_period}-01`, "MM-YYYY-DD").toDate() : null}
                                                                            className={`form-control`}
                                                                            onChange={(date) => {
                                                                                if (date == null) {
                                                                                    handleChangeListScholarship(
                                                                                        "govbur_period",
                                                                                        "",
                                                                                        index
                                                                                    );
                                                                                } else {
                                                                                    handleChangeListScholarship(
                                                                                        "govbur_period",
                                                                                        moment(date).format("YYYY-MM-DD"),
                                                                                        index
                                                                                    );
                                                                                }
                                                                            }}
                                                                            dateFormat="MM-YYYY"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            disabled={isDisabled}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })
                                                : ""}
                                        </>
                                    ) : null}
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Charged-in-court
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            label={`Charged in a court of law in any country`}
                                            id={`cic-0`}
                                            disabled={isDisabled}
                                            checked={applicationDetail?.charge_in_court === "Y"}
                                            onChange={(e) =>
                                                handleChange(
                                                    "charge_in_court",
                                                    e.target.checked ? "Y" : ""
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Application Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                disabled={isDisabled}
                                                value={applicationDetail?.remark}
                                                onChange={(e) => handleChange("remark", e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="">
                <Accordion activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="0" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue">
                            Other Details
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check
                                            label={`CPF Approved Funds`}
                                            disabled={isDisabled}
                                            id={`cic-1`}
                                            checked={applicationDetail?.cpf_approved_tag === "Y"}
                                            onChange={(e) =>
                                                handleChange(
                                                    "cpf_approved_tag",
                                                    e.target.checked ? "Y" : ""
                                                )
                                            }
                                        />
                                        <Form.Check
                                            label={`Intact Family`}
                                            disabled={isDisabled}
                                            id={`cic-2`}
                                            checked={applicationDetail?.intact_family_tag === "Y"}
                                            onChange={(e) =>
                                                handleChange(
                                                    "intact_family_tag",
                                                    e.target.checked ? "Y" : ""
                                                )
                                            }
                                        />
                                        <Form.Check
                                            label={`Company Sponsored`}
                                            disabled={isDisabled}
                                            id={`cic-3`}
                                            checked={applicationDetail?.comp_sponsor_tag === "Y"}
                                            onChange={(e) =>
                                                handleChange(
                                                    "comp_sponsor_tag",
                                                    e.target.checked ? "Y" : ""
                                                )
                                            }
                                        />
                                        <Form.Check
                                            label={`Paying MOE Subsidised Fees`}
                                            disabled={isDisabled}
                                            id={`cic-4`}
                                            checked={applicationDetail?.moe_subsidised === "Y"}
                                            onChange={(e) =>
                                                handleChange(
                                                    "moe_subsidised",
                                                    e.target.checked ? "Y" : ""
                                                )
                                            }
                                        />
                                    </Col>

                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in NPPS</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                disabled={true}
                                                value={studentDetail?.NPPS}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={6}>
                                        <Form.Group>
                                            <Form.Label>Recommended in FASOP</Form.Label>
                                            <Form.Control
                                                type={"text"}
                                                disabled={true}
                                                value={studentDetail?.FASOP}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

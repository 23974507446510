import Layout from "@/components/Layout";
import {Button, Col, Form, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import CustomModal from "@/components/CustomModal";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import {deleteScheme, getSchemeList, postScheme, putScheme,} from "@/apis/preparation/setup-scheme/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";

export default function SchemeDetail() {
    const [show, setShow] = useState(false);

    const [selectedId, setSelectedId] = useState("");

    const [selectedSN, setSelectedSN] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [editArr, setEditArr] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false)
    const [isFirstTime, setIsFirstTime] = useState(false)

    const [data, setData] = useState([]);

    const handleAddRow = () => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        const newRow = {
            institute_id: "",
            institution: "",
            scheme_id: "",
            year_offer: "",
            month_offer: "",
            serial_no: "",
            new_data: true
        };

        let updatedSerialNumbers = [];
        if (data?.length > 0) {
            updatedSerialNumbers = [newRow, ...data];
        } else {
            updatedSerialNumbers = [newRow];
        }

        console.log(updatedSerialNumbers)

        setData(updatedSerialNumbers);
        setIsFirstTime(true)
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const handleDeleteRow = () => {
        // if (editArr.some((edit) => edit === false)) {
        //   toast.error("Save changes before editing another row");
        //
        //   return false;
        // }

        if (selectedId) {
            deleteScheme(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (response?.name == "AxiosError") {
                            toast.error(response?.message)
                            return false;
                        }
                        toast.success("Serial number has been deleted");
                        getSchemes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);
        }

        setIsEditing(false)
        setIsDisabled(false)
    };

    const handleEdit = (index) => {
        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
        setIsDisabled(!isDisabled)
    };

    const handleCancelEdit = (index) => {
        if(data[index].new_data === true){
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
        setIsDisabled(false)
    }

    const handleSubmit = (index, serialid) => {
        if (
            !data[index].institute_id ||
            !data[index].scheme_id ||
            !data[index].institution ||
            !data[index].year_offer ||
            !data[index].month_offer
        ) {
            toast.error("Please fill out the required fields");
            return false;
        }

        const serialData = {
            institute_id: data[index].institute_id,
            scheme_id: data[index].scheme_id,
            institution: data[index].institution,
            year_offer: moment(data[index].year_offer).year(),
            month_offer: moment(data[index].month_offer).month() + 1,
        };

        //submit data
        if (serialid) {
            //update
            putScheme(serialid, serialData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (response?.name == "AxiosError") {
                            toast.error(response?.message)
                            return false;
                        }
                        toast.success(`Changes saved successfully `);
                        getSchemes();
                        setIsDisabled(!isDisabled)

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message);
                });
        } else {
            //post
            postScheme(serialData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        console.log(response)
                        if (response?.name == "AxiosError") {
                            toast.error(response?.message)
                            return false;
                        }
                        toast.success(`Changes saved successfully `);
                        getSchemes();
                        setIsDisabled(!isDisabled)

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message);
                });
        }
    };

    const getSchemes = () => {
        getSchemeList()
            .then((response) => {
                setData(response?.data?.data);
            })
            .then((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        getSchemes();
    }, []);

    useEffect(() => {
        const newArr = Array.from({length: data?.length}).fill(true);
        setEditArr(newArr);
        if (isFirstTime) {
            handleEdit(0)
            setIsFirstTime(false)
        }
    }, [data?.length]);

    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Setup Scheme Detail</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Setup Scheme Detail</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Button
                    variant={`outline-dark`}
                    onClick={handleAddRow}
                    className={`d-flex gap-3`}
                >
                    <i className="bi bi-plus-circle"></i>
                    Add new serial number
                </Button>
            </sgds-content-area-bottom>
            <sgds-content-body>
                {data?.map((serialNumber, index) => (
                    <div className={`p-3 bg-white shadow-sm`} key={index}>
                        <div className={`d-flex justify-content-between`}>
                            <h3 className={`text-blue fw-bold`}>
                                Serial Number {serialNumber.serial_no}
                            </h3>

                            <div className={`d-flex gap-1`}>
                                {!editArr[index] ? (
                                    <div className={`d-flex gap-1`}>
                                        <button
                                            className={`btn-clear text-green-500`}
                                            onClick={() => handleSubmit(index, serialNumber.id)}
                                        >
                                            <i className={`bi bi-check-lg`}></i>
                                            Save
                                        </button>

                                        <button
                                            className={`btn-clear text-red-500`}
                                            onClick={() => handleCancelEdit(index)}
                                        >
                                            <i className={`bi bi-x-circle`}></i>
                                            {` `}
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className={`btn-clear`}
                                        disabled={isDisabled}
                                        type={"button"}
                                        onClick={() => {
                                            if (isEditing) {
                                                toast.error("Save changes before editing another row");

                                                return false;
                                            }
                                            handleEdit(index)
                                        }}
                                    >
                                        <i className={`bi bi-pencil`}></i>
                                        {` `}
                                        Edit
                                    </button>
                                )}

                                <button
                                    className={`btn-clear`}
                                    disabled={index == 0 ? serialNumber?.scheme_id == "" ? false : isDisabled : isDisabled}
                                    type={"button"}
                                    onClick={() => {
                                        // if (isEditing) {
                                        //   toast.error("Save changes before editing another row");
                                        //
                                        //   return false;
                                        // }
                                        setShow(true);
                                        setSelectedId(serialNumber.id ?? "");
                                        setSelectedIndex(index ?? "");
                                        setSelectedSN(serialNumber.serial_no ?? "");
                                    }}
                                >
                                    <i className={`bi bi-trash`}></i>
                                </button>
                            </div>
                        </div>

                        <div className={`d-flex flex-column gap-3`}>
                            <Row>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>
                                            Institute id<span className={`text-danger`}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            required={true}
                                            type={`text`}
                                            value={serialNumber.institute_id}
                                            onChange={(e) =>
                                                handleChange(index, "institute_id", e.target.value)
                                            }
                                            disabled={editArr[index]}
                                            maxLength={4}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={7}>
                                    <Form.Group>
                                        <Form.Label>
                                            Institution<span className={`text-danger`}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            type={`text`}
                                            value={serialNumber.institution}
                                            onChange={(e) =>
                                                handleChange(index, "institution", e.target.value)
                                            }
                                            disabled={editArr[index]}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>
                                            Scheme id<span className={`text-danger`}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            type={`text`}
                                            value={serialNumber.scheme_id}
                                            onChange={(e) =>
                                                handleChange(index, "scheme_id", e.target.value)
                                            }
                                            disabled={editArr[index]}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>

                                <Col xs={3}>
                                    <Form.Group className={`d-flex flex-column`}>
                                        <Form.Label>
                                            Year of offer<span className={`text-danger`}> *</span>
                                        </Form.Label>
                                        <DatePicker
                                            className={`form-control`}
                                            selected={serialNumber.year_offer}
                                            onChange={(date) => {
                                                if (date == null) {
                                                    handleChange(index, "year_offer", "");
                                                } else {
                                                    handleChange(index, "year_offer", date);
                                                }
                                            }}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            disabled={editArr[index]}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className={`d-flex flex-column`}>
                                        <Form.Label>
                                            Month of Offer<span className={`text-danger`}> *</span>
                                        </Form.Label>
                                        <DatePicker
                                            className={`form-control`}
                                            selected={serialNumber.month_offer}
                                            onChange={(date) => {
                                                if (date == null) {
                                                    handleChange(index, "month_offer", "");
                                                } else {
                                                    handleChange(index, "month_offer", date);
                                                }
                                            }}
                                            dateFormat="MM"
                                            showMonthYearPicker
                                            disabled={editArr[index]}
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control
                                            type={`text`}
                                            value={serialNumber?.serial_no == null ? "" : serialNumber?.serial_no}
                                            // onChange={(e) => handleChange(index, "serial_no", e.target.value)}
                                            disabled={true}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ))}
            </sgds-content-body>
            <CustomModal
                show={show}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                title={`Delete serial number ${selectedSN}`}
                description={`System will reset the serial number to previous number. Do you wish to proceed?`}
            />
        </Layout>
    );
}

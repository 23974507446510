import {Button, Col, Dropdown, Form, FormCheck, Modal, Row, Table,} from "@govtechsg/sgds-react";
import Select, {components} from "react-select";
import React, {useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {getListApplications, patchApplications,} from "@/apis/applications/api";
import {getRemarkNYP} from "@/apis/preparation/setup-remarks/api";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getCodeList, getStudentList} from "@/apis/sims/api";
import CustomModal from "@/components/CustomModal";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {toast} from "react-toastify";
import {postStudentList} from "@/apis/app-records/verification/api";
import {sortExerciseList} from "@/components/utils/utils";
import {ApplicationStatus, VerificationStatus} from "@/apis/app-records/maintenance/status";

export default function BatchUpdate() {
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [dataAdminNo, setDataAdminNo] = useState([]);
    const [dataApplications, setDataApplications] = useState([]);
    const [dataAward, setDataAward] = useState([]);
    const [dataAwardType, setDataAwardType] = useState([]);
    const [dataExercise, setDataExercise] = useState([]);
    const [dataRemark, setDataRemark] = useState([]);

    const [academyYear, setAcademyYear] = useState(null);
    const [adminNo, setAdminNo] = useState(null);
    const [addRemarks, setAddRemarks] = useState({
        appln_status: "A",
        remark: "",
        remarks1: "",
    });
    const [applicationStatus, setApplicationStatus] = useState(null);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [award, setAward] = useState(null);
    const [awardType, setAwardType] = useState(null);
    const [exercise, setExercise] = useState(null);

    const [isOpen, setIsOpen] = useState(true);
    const [showDeleteApplication, setShowDeleteApplication] = useState(false);

    const contentRef = useRef(null);

    const [items, setItems] = useState([]);

    const [groupEdit, setGroupEdit] = useState({name: "", age: ""});

    const [selectAll, setSelectAll] = useState(false);

    const [show, setShow] = useState(false);

    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);
    const [loadingListAward, setLoadingListAward] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const refTimeoutSearch = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const [retrieved, setRetrieved] = useState(false)

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAdminNo();
        handleGetListAwardType();
        handleGetListRemark();
        setSelectedOptions(
            columnVisibilityOptions.map((option) => ({
                value: option.value,
                label: option.label,
            }))
        );

    }, []);

    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        if (awardType?.value) handleGetListAward(awardType?.value);
    }, [awardType]);

    const handleDeleteApplication = () => {
        if (selectedItem) {
            deleteApplication(
                selectedItem?.acad_yr,
                selectedItem?.exercise,
                selectedItem?.adm_no
            )
                .then(() => {
                    handleGetListApplications();
                    setShowDeleteApplication(false);
                    setSelectedItem(null);
                    toast.success("Record deleted successfully");
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();

            setDataAcademyYear(
                response?.data?.data?.map((item) => ({
                    label: item?.acad_yr,
                    value: item?.acad_yr,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };

    const handleGetListAdminNo = async (search = "") => {
        try {
            setAdminNo(null);
            setDataAdminNo([]);

            const params = {
                student_id: "",
                studstatus: "",
                transfer_tag: "N",
                page: 1,
                limit: 20,
                search: search,
                sort: "id_no",
            };

            const response = await getStudentList(params);

            setDataAdminNo(
                response?.data?.data?.map((item) => ({
                    label: item?.admNo,
                    value: item?.admNo,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListApplications = async () => {
        try {
            const params = {
                acad_yr: academyYear?.value ?? "",
                exercise: exercise?.value ?? "",
                adm_no: adminNo?.value ?? "",
                appln_status: applicationStatus?.value ?? "",
                verify_status: verificationStatus?.value ?? "",
                rec_award_type: awardType?.value ?? "",
                rec_award_code: award?.value ?? "",
                page: currentPage,
                limit: itemsPerPage,
            };

            const response = await getListApplications(params);

            setTotalPages(response?.data?.totalPage);
            setIsNextPage(response?.data?.nextPage);

            if (response?.data?.data?.length === 0) {
                toast.warning("Application data is empty");
                setDataApplications([])
                return;
            }

            const modifiedItems = response?.data?.data?.map((item) => ({
                ...item,
                name: "",
            }));

            setDataApplications(modifiedItems);

            const itemsMap = modifiedItems.reduce((map, item) => {
                map[item.adm_no] = item;
                return map;
            }, {});

            const students_id = response?.data?.data
                ?.map((application) => application.adm_no)
                .join(",");

            if (students_id) {
                postStudentList(students_id, false).then((response2) => {
                    response2?.data?.data?.forEach((student) => {
                        const item = itemsMap[student.admNo];
                        console.log(item)
                        if (item) {
                            setDataApplications((prevItems) =>
                                prevItems.map((prevItem) => {
                                    if (prevItem.adm_no === item.adm_no) {
                                        return {
                                            ...prevItem,
                                            name: student.name,
                                        };
                                    }
                                    return prevItem;
                                })
                            );
                        }
                    });
                });
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListAward = async (codeType = "") => {
        setLoadingListAward(true);

        try {
            setAward(null);

            const response = await getCodeList(codeType);

            setDataAward(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingListAward(false);
        }
    };

    const handleGetListAwardType = async (codeType = "AWARDTYPECODE") => {
        try {
            setAwardType(null);

            const response = await getCodeList(codeType);

            setDataAwardType(
                response?.data?.data?.map((item) => ({
                    label: item?.description,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );

            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleGetListRemark = async () => {
        try {
            const params = {
                page: 0,
            };

            const response = await getRemarkNYP(params);

            setDataRemark(
                response?.data?.data?.map((item) => ({
                    label: item?.remark,
                    value: item?.code,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) setItems([]);
        else setItems(dataApplications);

        setSelectAll((prevState) => !prevState);
    };

    const updateGroupEditForm = (selectedItems) => {
        if (selectedItems.length === 0) {
            setGroupEdit({name: "", age: "", role: ""});
            return;
        }
        const name = selectedItems.map((item) => item.name).join(", ");
        const age = selectedItems.map((item) => item.age).join(", ");
        const role = selectedItems.map((item) => item.role).join(", ");
        setGroupEdit({name, age, role});
    };

    const handleSearch = (searchFunction = null, value = "") => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };

    const handleSelectItem = (data) => {
        let currentItem = [...items];
        const index = items?.findIndex((item) => item?.id === data?.id);

        if (index < 0) currentItem.push(data);
        else currentItem?.splice(index, 1);

        setItems(currentItem);
        setSelectAll(
            dataApplications.every((item) =>
                currentItem?.find((data) => data?.id === item?.id)
            )
        );
        updateGroupEditForm(currentItem.filter((item) => item.checked));
    };

    const handleChange = (id, field, value) => {
        setItems(
            items.map((item) => {
                if (item.id === id) {
                    return {...item, [field]: value};
                }
                return item;
            })
        );
    };

    const handleDelete = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    const handleEdit = (id) => {
        setItems(
            items.map((item) => {
                if (item.id === id) {
                    return {...item, editable: !item.editable};
                }
                return item;
            })
        );
    };

    const handleAddRemarks = async () => {
        try {
            const params = {
                data: [
                    ...items.map((data) => ({
                        acad_yr: data?.acad_yr ?? academyYear?.value,
                        exercise: data?.exercise ?? exercise?.value,
                        adm_no: data?.adm_no ?? adminNo?.value,
                        // VERIFY_STATUS: addRemarks?.appln_status?.value,
                        appln_status: addRemarks?.appln_status,
                        remark: addRemarks?.remark?.value,
                        remarks1: addRemarks?.remarks1,
                        last_upd_id: sessionStorage.getItem('staffId') ? sessionStorage.getItem('staffId') : process.env.REACT_APP_LAST_UPD_ID,
                    })),
                ],
            };

            console.log(params)

            const resp = await patchApplications(params);

            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    toast.success("Application has been saved");
                }
            }

            setItems([]);
            getListApplications();
            setAddRemarks({
                appln_status: "A",
                remark: "",
                remarks1: "",
            });

            setShow(false);
        } catch (error) {
            console.log(error?.message);
        }
    };

    const [columnVisibilityOptions, setColumnVisibilityOptions] = useState([
        {
            value: "age",
            label: "SNo",
            visible: true,
            columnEditable: false
        },
        {
            value: "adm_no",
            label: "Adm. No.",
            visible: true,
            columnEditable: false,
        },
        {
            value: "id",
            label: "ID No.",
            visible: true,
            columnEditable: false,
        },
        {
            value: "name",
            label: "Name",
            visible: true,
            columnEditable: false
        },
        {
            value: "courseName",
            label: "Course",
            visible: true,
            columnEditable: false,
        },
        {
            value: "studyStage",
            label: "Study Stage",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mthly_income",
            label: "Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "family_members",
            label: "Members",
            visible: true,
            columnEditable: false,
        },
        {
            value: "dependent",
            label: "Dependant",
            visible: true,
            columnEditable: false,
        },
        {
            value: "mpci",
            label: "MPCI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gpa",
            label: "GPA",
            visible: true,
            columnEditable: false,
        },
        {
            value: "citizen",
            label: "Citizen",
            visible: true,
            columnEditable: false,
        },
        {
            value: "physical_status",
            label: "PR",
            visible: true,
            columnEditable: false
        },
        {
            value: "homehousetype",
            label: "Housing",
            visible: true,
            columnEditable: false,
        },
        {
            value: "other_income",
            label: "GHI Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "gross_income",
            label: "Gross Income",
            visible: true,
            columnEditable: false,
        },
        {
            value: "fpci",
            label: "FPCI",
            visible: true,
            columnEditable: false,
        },
        {
            value: "rec_award_type",
            label: "Award Type",
            visible: true,
            columnEditable: false
        },
        {
            value: "rec_award_code",
            label: "Award",
            visible: true,
            columnEditable: false
        },
        {
            value: "verify_status",
            label: "Verification Status",
            visible: true,
            columnEditable: false,
        },
        {
            value: "appln_status",
            label: "Approval Status",
            visible: true,
            columnEditable: false,
        },
        {
            value: "remarks1",
            label: "Special Remarks",
            visible: true,
            columnEditable: false,
        },
    ])

    const [selectedOptions, setSelectedOptions] = useState([]);

    const customOption = (props) => (
        <div>
            <components.Option {...props}>
                <input
                    type={"checkbox"}
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );

    const handleSubmitRetrieve = (e) => {
        e.preventDefault()
        setRetrieved(true)
        handleGetListApplications()
    }

    const toggleColumnVisibility = (key) => {
        setColumnVisibilityOptions(
            columnVisibilityOptions.map((column) =>
                column.value === key ? {...column, visible: true} : column
            )
        );
    };

    const handleSelectedVisibilityOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions);

        const updatedOptions = columnVisibilityOptions.map((option) => ({
            ...option,
            visible: selectedOptions.some(
                (selected) => selected.value === option.value
            ),
        }));

        setColumnVisibilityOptions(updatedOptions);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        if(retrieved){
            handleGetListApplications()
        }
    }, [currentPage]);

    return (
        <Layout currentNav={"processing"} toc={false}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Application Processing</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Batch Application Status Update
                </Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Batch Application Status Update</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className="d-flex gap-4 flex-column">
                    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                        <div className="row">
                            <div className="col">
                                <h3>Data Retrieval</h3>
                            </div>
                        </div>

                        <Form
                            ref={contentRef}
                            className={` d-flex flex-column gap-3 collapse-animation`}
                            style={{
                                overflow: isOpen ? "unset" : "hidden",
                                maxHeight: isOpen
                                    ? `${
                                        contentRef?.current?.scrollHeight == null
                                            ? "100%"
                                            : contentRef?.current?.scrollHeight
                                    }px`
                                    : "0px",
                            }}
                            onSubmit={(e) => handleSubmitRetrieve(e)}
                        >
                            <Row>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Academic Year</Form.Label>
                                        <Select
                                            onChange={(value) => setAcademyYear(value)}
                                            options={dataAcademyYear}
                                            placeholder={`Academic Year`}
                                            value={academyYear}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Exercise</Form.Label>
                                        <Select
                                            isDisabled={!academyYear?.value}
                                            onChange={(value) => setExercise(value)}
                                            options={dataExercise}
                                            value={exercise}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Form.Group>
                                        <Form.Label>Adm. No</Form.Label>
                                        <Select
                                            onChange={(value) => setAdminNo(value)}
                                            options={dataAdminNo}
                                            onInputChange={(value) =>
                                                handleSearch(handleGetListAdminNo, value)
                                            }
                                            value={adminNo}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>ID No.</Form.Label>
                                        <Select options={options} />
                                    </Form.Group>
                                </Col> */}
                                {/* <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Verification Status</Form.Label>
                                        <Select options={options} />
                                    </Form.Group>
                                </Col> */}
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Application Status</Form.Label>
                                        <Select
                                            backspaceRemovesValue={true}
                                            onChange={(value) => setApplicationStatus(value)}
                                            options={ApplicationStatus()}
                                            placeholder={`Application Status`}
                                            value={applicationStatus}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Verification Status</Form.Label>
                                        <Select
                                            backspaceRemovesValue={true}
                                            onChange={(value) => setVerificationStatus(value)}
                                            options={VerificationStatus()}
                                            placeholder={`Verification Status`}
                                            value={verificationStatus}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award Type</Form.Label>
                                        <Select
                                            onChange={(value) => setAwardType(value)}
                                            options={dataAwardType}
                                            value={awardType}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Award</Form.Label>
                                        <Select
                                            isDisabled={!awardType?.value}
                                            onChange={(value) => setAward(value)}
                                            options={dataAward}
                                            value={award}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={3}>
                                    <Button type="submit" className="btn btn-primary">
                                        Retrieve
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="btn-clear btn-link ps-0 mt-3 d-flex gap-2 align-items-center"
                        >
                            {isOpen ? "Hide " : "Expand "}{" "}
                            {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                        </button>
                    </div>
                </div>
                {dataApplications?.length > 0 ? (
                    <div className="mt-4 p-3 bg-white shadow-sm border rounded border-1">
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Columns visibility</Form.Label>
                                    <Select
                                        isMulti
                                        value={selectedOptions}
                                        options={columnVisibilityOptions}
                                        components={{Option: customOption}}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={handleSelectedVisibilityOptions}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={6}
                                className="d-flex justify-content-end align-items-end gap-3"
                            >
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className={`btn btn-outline-dark`}
                                        disabled={items?.length <= 0}
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                        Update Status
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setShow(true)}>
                                            Add Remarks
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <button type="button" className="btn btn-primary">
                                    Export
                                </button> */}
                            </Col>
                            <Col xs={12}>
                                <Table responsive>
                                    <Table.Header>

                                        <Table.Row>
                                            <Table.HeaderCell>
                                                <FormCheck
                                                    id="table_no"
                                                    label=""
                                                    name="table_no "
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                />
                                            </Table.HeaderCell>
                                            {columnVisibilityOptions.map(
                                                (column) =>
                                                    column.visible && (
                                                        <Table.HeaderCell key={column.value}>
                                                            {column.label}
                                                        </Table.HeaderCell>
                                                    )
                                            )}
                                            <Table.HeaderCell></Table.HeaderCell>
                                            {/*<Table.HeaderCell>*/}
                                            {/*    <FormCheck*/}
                                            {/*        id="table_no"*/}
                                            {/*        label=""*/}
                                            {/*        name="table_no "*/}
                                            {/*        type="checkbox"*/}
                                            {/*        checked={selectAll}*/}
                                            {/*        onChange={handleSelectAll}*/}
                                            {/*    />*/}
                                            {/*</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>SNo</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Admin No.</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>ID No.</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Name</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Course</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Study Stage</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Income</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Members</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Dependant</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>MPCI</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>GPA</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Citizen</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>PR</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Housing</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>GHI Income</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Gross Income</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>FPCI</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Award Type</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Award</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Verification Status</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Approval Status</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell>Special Remarks</Table.HeaderCell>*/}
                                            {/*<Table.HeaderCell></Table.HeaderCell>*/}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            dataApplications.map((item, index) => (
                                                <Table.Row key={index}>
                                                    <Table.DataCell>
                                                        {" "}
                                                        <FormCheck
                                                            id="table_no"
                                                            label=""
                                                            name="table_no "
                                                            type="checkbox"
                                                            checked={items?.find(
                                                                (data) => data?.id === item?.id
                                                            )}
                                                            onChange={() => handleSelectItem(item)}
                                                        />
                                                    </Table.DataCell>
                                                    {columnVisibilityOptions.map((column, index) =>
                                                            column.visible && (
                                                                <Table.DataCell key={column.value}>
                                                                    {
                                                                        column.value === "age" ? index + 1 : item[column.value] ?? "-"
                                                                    }
                                                                </Table.DataCell>
                                                            )
                                                    )}
                                                    <Table.DataCell>
                                                        <div className={`d-flex gap-1`}>
                                                            <button
                                                                className={`btn-clear`}
                                                                onClick={() => {
                                                                    setItems([item]);
                                                                    setShow(true);
                                                                }}
                                                            >
                                                                <i className={`bi bi-pencil`}></i>
                                                            </button>
                                                        </div>
                                                    </Table.DataCell>
                                                </Table.Row>
                                            ))}
                                    </Table.Body>
                                    {/*<Table.Body>*/}
                                    {/*    {dataApplications.map((item, index) => (*/}
                                    {/*        <Table.Row key={index}>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {" "}*/}
                                    {/*                <FormCheck*/}
                                    {/*                    id="table_no"*/}
                                    {/*                    label=""*/}
                                    {/*                    name="table_no "*/}
                                    {/*                    type="checkbox"*/}
                                    {/*                    checked={items?.find(*/}
                                    {/*                        (data) => data?.id === item?.id*/}
                                    {/*                    )}*/}
                                    {/*                    onChange={() => handleSelectItem(item)}*/}
                                    {/*                />*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>{index + 1}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item.editable ? (*/}
                                    {/*                    <input*/}
                                    {/*                        type="text"*/}
                                    {/*                        value={item.adm_no}*/}
                                    {/*                        onChange={(e) =>*/}
                                    {/*                            handleChange(item.id, "name", e.target.value)*/}
                                    {/*                        }*/}
                                    {/*                    />*/}
                                    {/*                ) : (*/}
                                    {/*                    item.adm_no*/}
                                    {/*                )}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.LAST_UPD_ID ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.name ?? "-"}*/}
                                    {/*                /!*{item.editable ? (*!/*/}
                                    {/*                /!*    <input*!/*/}
                                    {/*                /!*        type="number"*!/*/}
                                    {/*                /!*        value={item.age}*!/*/}
                                    {/*                /!*        onChange={(e) =>*!/*/}
                                    {/*                /!*            handleChange(item.id, "age", e.target.value)*!/*/}
                                    {/*                /!*        }*!/*/}
                                    {/*                /!*    />*!/*/}
                                    {/*                /!*) : (*!/*/}
                                    {/*                /!*    item.age*!/*/}
                                    {/*                /!*)}*!/*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>-</Table.DataCell>*/}
                                    {/*            <Table.DataCell>-</Table.DataCell>*/}
                                    {/*            <Table.DataCell>${item?.MTHLY_INCOME}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.FAMILY_MEMBERS ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.DEPENDENT ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>{item?.MPCI ?? "-"}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>-</Table.DataCell>*/}
                                    {/*            <Table.DataCell>-</Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.PHYSICAL_STATUS ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.HOMEHOUSETYPE ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>${item?.OTHER_INCOME}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>${item?.GROSS_INCOME}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>{item?.FPCI}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>{item?.REC_AWARD_TYPE}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>{item?.REC_AWARD_code}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.VERIFY_STATUS ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                {item?.appln_status ?? "-"}*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*            <Table.DataCell>{item?.remarks1 ?? "-"}</Table.DataCell>*/}
                                    {/*            <Table.DataCell>*/}
                                    {/*                <div className={`d-flex gap-1`}>*/}
                                    {/*                    /!* {item.editable ? (*/}
                                    {/*                        <button*/}
                                    {/*                            className={`btn-clear text-green-500`}*/}
                                    {/*                            onClick={() => handleEdit(item.id)}*/}
                                    {/*                        >*/}
                                    {/*                            <i className={`bi bi-check-lg`}></i> Save*/}
                                    {/*                        </button>*/}
                                    {/*                    ) : ( *!/*/}
                                    {/*                    <button*/}
                                    {/*                        className={`btn-clear`}*/}
                                    {/*                        onClick={() => {*/}
                                    {/*                            setItems([item]);*/}
                                    {/*                            setShow(true);*/}
                                    {/*                        }}*/}
                                    {/*                    >*/}
                                    {/*                        <i className={`bi bi-pencil`}></i>*/}
                                    {/*                    </button>*/}
                                    {/*                    /!* )} *!/*/}
                                    {/*                    /!* <button*/}
                                    {/*                        className={`btn-clear`}*/}
                                    {/*                        onClick={() => {*/}
                                    {/*                            setSelectedItem(item);*/}
                                    {/*                            setShowDeleteApplication(true);*/}
                                    {/*                        }}*/}
                                    {/*                    >*/}
                                    {/*                        <i className={`bi bi-trash`}></i>*/}
                                    {/*                    </button> *!/*/}
                                    {/*                </div>*/}
                                    {/*            </Table.DataCell>*/}
                                    {/*        </Table.Row>*/}
                                    {/*    ))}*/}
                                    {/*</Table.Body>*/}
                                </Table>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <button
                                className={`btn-clear`}
                                onClick={prevPage}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
              {" "}
                                Page {currentPage} of {totalPages}{" "}
            </span>
                            <button
                                className={`btn-clear`}
                                onClick={nextPage}
                                disabled={!isNextPage}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : null}
            </sgds-content-body>
            <Modal
                size={"lg"}
                show={show}
                onHide={() => setShow(false)}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Action - add remarks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className={`justify-content-center`}>
                        <Col xs={8} className={`bg-yellow-100 p-2 text-center`}>
                            Selected{" "}
                            {items
                                ?.slice(0, 4)
                                ?.map((item) => (item?.name !== "" ? item?.name : item?.adm_no))
                                ?.join(", ")}
                            {items?.length > 4 ? `and ${items?.length - 2} more` : ""}
                        </Col>
                    </Row>

                    <Row className={`flex-column mt-3`}>
                        <h5 className={`fw-bold`}>Application Status</h5>

                        <Col>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusRad"
                                    id="optionRad1"
                                    checked={addRemarks?.appln_status === "A"}
                                    onChange={() =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            appln_status: "A",
                                        }))
                                    }
                                />
                                <label className="form-check-label" htmlFor="optionRad1">
                                    Approve
                                </label>
                            </div>
                        </Col>

                        <Col>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="statusRad"
                                    id="optionRad2"
                                    checked={addRemarks?.appln_status === "E"}
                                    onChange={() =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            appln_status: "E",
                                        }))
                                    }
                                />
                                <label className="form-check-label" htmlFor="optionRad2">
                                    Reject
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <div className={`d-flex flex-column gap-3 mt-3`}>
                        <h5 className={`fw-bold mb-0`}>Add remarks</h5>

                        <Row>
                            <Col xs={4}>
                                <label>Approval Status Remarks</label>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    backspaceRemovesValue={true}
                                    onChange={(value) =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            remark: value,
                                        }))
                                    }
                                    options={dataRemark}
                                    value={dataRemark?.find(option => option.value === addRemarks?.remark)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={4}>
                                <label>Special Remarks</label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control
                                    type={"text"}
                                    onChange={(e) =>
                                        setAddRemarks((prevState) => ({
                                            ...prevState,
                                            remarks1: e?.target?.value,
                                        }))
                                    }
                                    value={addRemarks?.remarks1}
                                ></Form.Control>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`gap-2`}>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddRemarks}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomModal
                show={showDeleteApplication}
                handleClose={() => setShowDeleteApplication(false)}
                action={handleDeleteApplication}
                title={`Delete record?`}
                description={`The selected record will be removed from the list. Proceed to delete?`}
            />
        </Layout>
    );
}

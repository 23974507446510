import { apiCall } from "@/utils/apiCall";


export async function getDataUnmatch(
    ACAD_YR = "",
    REC_AWARD_TYPE = "",
    REC_AWARD_CODE = ""
) {
    return apiCall(
        `${process.env.REACT_APP_API_URL}/applications/postals/unmatch?acad_yr=${ACAD_YR}&rec_award_type=${REC_AWARD_TYPE}&rec_award_code=${REC_AWARD_CODE}`,
        {},
        "GET"
    );
}

import { apiCall } from "@/utils/apiCall";

// {
//     "OCCUPATION": "STUDENT",
//     "INCOME_CONTRIBUTION": "Y",
//     "INDEPENDENT": "Y",
//     "REMARK": "Y"
// }

export async function getOccupationList(limit = 20, page = 1) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations?limit=${limit}&page=${page}`,
    {},
    "GET"
  );
}

export function postOccupation(OCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations`,
    OCData,
    "POST"
  );
}

export function putOccupation(OCID, OCData) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations/${OCID}`,
    OCData,
    "PUT"
  );
}

export function deleteOccupation(selectedId) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/occupations/${selectedId}`,
    {},
    "DELETE"
  );
}

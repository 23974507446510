import React from "react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

function EditAndSaveButtonHomes({
  isDisabled,
  handleClickEdit,
  handleSubmitApplicationForm,
  handleToggleAll,
  isExpandAll
}) {
  return (
    <div className="d-flex justify-content-between">
      {isDisabled ? (
        <button
          className="bg-white border-1 px-3 rounded-2 "
          onClick={handleClickEdit}
        >
          <i className={`bi bi-pencil`}></i>
          {"  "}Edit
        </button>
      ) : (
        <button
          className="bg-white border-1 px-3 rounded-2 text-green-500"
          onClick={handleSubmitApplicationForm}
        >
          <i className={`bi bi-check-lg`}></i>
          {"  "}Save
        </button>
      )}
      <button
          className="bg-white border-1 px-3 rounded-2 "
          onClick={handleToggleAll}
      >
          {isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
          {" "}
          {isExpandAll ? "Expand All" : "Collapse All"}
      </button>

    </div>
  );
}

export default EditAndSaveButtonHomes;

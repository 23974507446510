import {Button, Col, Form, Row,} from "@govtechsg/sgds-react";
import Layout from "@/components/Layout";
import React, {useEffect, useRef, useState} from "react";
import UploadModal from "@/components/UploadModal";
import {toast} from "react-toastify";
import CustomModal from "@/components/CustomModal";
import * as XLSX from "xlsx";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
    deleteComcare,
    getComcareList,
    postAppendComcare,
    postComcare,
    postOverwriteComcare,
    putComcare,
} from "@/apis/preparation/maintain-comcare/api";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {isStartDateLaterThanEndDate} from "@/components/utils/utils";
import SortableTable from "@/components/SortableTable";
import {getStudentList} from "@/apis/sims/api";
import Select from "react-select";

export default function ComcareDetail() {
    const [adminNo, setAdminNo] = useState("");
    const [schemeName, setSchemeName] = useState("");

    const [data, setData] = useState([
        {
            adm_no: "",
            schemes_name: "",
            schemes_startdate: "",
            schemes_enddate: "",
        },
    ]);

    const [header, setHeader] = useState([
        {key: 'index', label: '#', required: false},
        {key: 'adm_no', label: 'Adm No', required: false},
        {key: 'schemes_name', label: 'Scheme Name', required: false},
        {key: 'schemes_startdate', label: 'Start Date', required: true},
        {key: 'schemes_enddate', label: 'End Date', required: true},
        {key: 'action', label: '', required: false},
    ]);

    const [editArr, setEditArr] = useState([true]);

    const [show, setShow] = useState(false);

    const [showUpload, setShowUpload] = useState(false);

    const [selectedId, setSelectedId] = useState("");

    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [selectedFile, setSelectedFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isNextPage, setIsNextPage] = useState(true);
    const itemsPerPage = 20;
    const [dataAdminNo, setDataAdminNo] = useState([]);

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };

    const [isEditing, setIsEditing] = useState(false);

    function excelDateToJSDate(excelDate) {
        if (typeof excelDate === "string" && excelDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            return excelDate;
        }

        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const dateOffset = (excelDate - 25569) * millisecondsPerDay;
        const jsDate = new Date(dateOffset);
        return jsDate.toISOString().split("T")[0];
    }

    const postOverwrite = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let canProceed = true;

            jsonData.map((row) => {
                if (
                    isStartDateLaterThanEndDate(
                        row?.schemes_startdate,
                        row?.schemes_enddate
                    )
                ) {
                    toast.error("End date cannot be earlier than start date.");
                    canProceed = false;
                }
            });

            if (canProceed) {
                const modifiedData = jsonData.map((row) => {
                    const dateColumns = ["schemes_startdate", "schemes_enddate"];

                    dateColumns.forEach((column) => {
                        if (row[column]) {
                            const formattedDate = (row[column]);
                            row[column] = formattedDate;
                        }
                    });
                    return row;
                });
                postOverwriteComcare(modifiedData)
                    .then((response) => {
                        if (response?.response?.data?.message) {
                            if (
                                response?.response?.data?.message?.length > 0 &&
                                Array.isArray(response?.response?.data?.message)
                            ) {
                                response?.response?.data?.message?.map((contentErr) => {
                                    toast.error(contentErr?.msg);
                                });
                            } else {
                                toast.error(response?.response?.data?.message);
                            }
                        } else if (response?.response?.status == 404) {
                            toast.error("Data not found");
                        } else {
                            toast.success(`Record updated successfully `);
                            getCodes()
                            setSelectedFile(null)
                            setShowUpload(false);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response?.data.message);
                    });
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const postAppend = () => {
        if (!selectedFile) {
            toast.error(`Select file`);

            return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: "binary"});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet);

            let canProceed = true;
            jsonData.map((row) => {
                if (
                    isStartDateLaterThanEndDate(
                        row?.schemes_startdate,
                        row?.schemes_enddate
                    )
                ) {
                    toast.error("End date cannot be earlier than start date.");
                    canProceed = false;
                }
            });

            if (canProceed) {
                const modifiedData = jsonData.map((row) => {
                    const dateColumns = ["schemes_startdate", "schemes_enddate"];

                    dateColumns.forEach((column) => {
                        if (row[column]) {
                            const formattedDate = (row[column]);
                            row[column] = formattedDate;
                        }
                    });

                    return row;
                });

                postAppendComcare(modifiedData)
                    .then((response) => {
                        if (response?.response?.data?.message) {
                            if (
                                response?.response?.data?.message?.length > 0 &&
                                Array.isArray(response?.response?.data?.message)
                            ) {
                                response?.response?.data?.message?.map((contentErr) => {
                                    toast.error(contentErr?.msg);
                                });
                            } else {
                                toast.error(response?.response?.data?.message);
                            }
                        } else if (response?.response?.status == 404) {
                            toast.error("Data not found");
                        } else {
                            toast.success(`Record updated successfully `);
                            getCodes()
                            setSelectedFile(null)
                            setShowUpload(false);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response?.data.message);
                    });
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    const handleEdit = (index) => {
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = false;
            return newArr;
        });

        setIsEditing(true);
    };

    const handleSubmitEdit = (index, CodeID) => {
        if (
            !data[index].adm_no ||
            !data[index].schemes_name ||
            !data[index].schemes_startdate
        ) {
            toast.error("Please fill out the required fields");
            return false;
        }

        const startDate = moment(data[index].schemes_startdate);
        const endDate = moment(data[index].schemes_enddate);

        if (endDate.isBefore(startDate)) {
            toast.error("End date cannot be earlier than start date.");
            return
        } else if (startDate.isAfter(endDate)) {
            toast.error("Start date cannot be later than end date.");
            return
        }

        const CodeData = {
            adm_no: data[index].adm_no,
            schemes_name: data[index].schemes_name,
            schemes_startdate: moment(data[index].schemes_startdate).format(
                "YYYY-MM-DD"
            ),
            schemes_enddate: moment(data[index].schemes_enddate).format("YYYY-MM-DD"),
        };

        //submit data
        if (CodeID) {
            //update
            putComcare(CodeID, CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });
                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            //post
            postComcare(CodeData)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success(`Changes saved successfully`);
                        getCodes();

                        setEditArr((prevArr) => {
                            const newArr = [...prevArr];
                            newArr[index] = true;
                            return newArr;
                        });

                        setIsEditing(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        }
    };

    const handleDeleteRow = () => {
        if (selectedId) {
            deleteComcare(selectedId)
                .then((response) => {
                    if (response?.response?.data?.message) {
                        if (
                            response?.response?.data?.message?.length > 0 &&
                            Array.isArray(response?.response?.data?.message)
                        ) {
                            response?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(response?.response?.data?.message);
                        }
                    } else if (response?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        toast.success("Record deleted successfully");
                        getCodes();
                        setShow(false);
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data.message);
                });
        } else {
            const updatedData = [...data];

            updatedData.splice(selectedIndex, 1);

            setData(updatedData);

            setShow(false);
            setIsEditing(false);

            const updatedEditArray = [...editArr];
            updatedEditArray.splice(selectedIndex, 1);
            setEditArr(updatedEditArray);
        }
    };

    const handleChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            [field]: value,
        };
        setData(updatedData);
    };

    const getCodes = () => {
        getComcareList(adminNo, schemeName, itemsPerPage, currentPage)
            .then((response) => {
                if (response?.data?.data?.length > 0) {
                    setData(response?.data.data);
                    const newArr = Array.from({
                        length: response?.data.data.length,
                    }).fill(true);

                    setEditArr(newArr);
                    setTotalPages(response?.data?.totalPage);
                    setIsNextPage(response?.data?.nextPage);
                } else {
                    toast.warning("Data not found");
                }
            })
            .catch((error) => {
                toast.error(error.response?.data.message);
            });
    };

    const handleCancelEdit = (index) => {
        if (data[index].new_data === true) {
            const updatedData = data.filter(item => !item.new_data);
            setData(updatedData);
        }

        setEditArr((prevArr) => {
            const newArr = [...prevArr];
            newArr[index] = true;
            return newArr;
        });

        setIsEditing(false);
    }

    const renderActionButtons = (row, rowIndex) => (
        <div className={`d-flex gap-1`}>
            {!editArr[rowIndex] ? (
                <div className={`d-flex gap-1`}>
                    <button
                        className={`btn-clear text-green-500`}
                        onClick={() => handleSubmitEdit(rowIndex, row.id)}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        Save
                    </button>

                    <button
                        className={`btn-clear text-red-500`}
                        onClick={() => handleCancelEdit(rowIndex)}
                    >
                        <i className={`bi bi-x-circle`}></i>
                        {` `}
                        Cancel
                    </button>
                </div>
            ) : (
                <button
                    className={`btn-clear`}
                    disabled={isEditing}
                    onClick={() => handleEdit(rowIndex)}
                >
                    <i className={`bi bi-pencil`}></i>
                    {` `}
                    Edit
                </button>
            )}

            <button
                className={`btn-clear`}
                disabled={rowIndex == 0 ? row?.id ? isEditing : false : isEditing}
                onClick={() => {
                    setShow(true);
                    setSelectedId(row.id ?? "");
                    setSelectedIndex(rowIndex);
                }}
            >
                <i className={`bi bi-trash`}></i>
            </button>
        </div>
    );

    const renderCellContent = (row, rowIndex, key) => (
        editArr[rowIndex] ? (
            key === "schemes_startdate" || key === "schemes_enddate" ? moment(row[key]).format("DD/MM/YYYY") : row[key]
        ) : (
            key === "schemes_name" ? (
                    <input
                        className={`form-control`}
                        type={`text`}
                        value={row[key]}
                        onChange={(e) => handleChange(rowIndex, key, e.target.value)}
                    />
                ) :
                key === "adm_no" ? (
                        <Select
                            menuPortalTarget={document.body}
                            onChange={(option) => handleChange(rowIndex, key, option.value)}
                            options={dataAdminNo}
                            onInputChange={(value) => handleSearch(handleGetListAdminNo, value)}
                            value={{
                                label: row[key],
                                value: row[key]
                            }}
                        />
                    ) :
                    key !== "adm_no" ?
                        <DatePicker
                            className={`form-control`}
                            selected={row[key]}
                            onChange={(date) => {
                                if (date === null) {
                                    handleChange(rowIndex, key, "");
                                } else {
                                    handleChange(rowIndex, key, date);
                                }
                            }}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                        />
                        : row[key]
        )
    );

    const refTimeoutSearch = useRef(null);

    const handleSearch = (searchFunction = null, value = "") => {
        clearTimeout(refTimeoutSearch.current);

        if (value) {
            refTimeoutSearch.current = setTimeout(() => {
                searchFunction(value);
            }, 500);
        }
    };

    const handleGetListAdminNo = async (search = "") => {
        try {
            setAdminNo(null);
            setDataAdminNo([]);

            const params = {
                student_id: "",
                studstatus: "",
                transfer_tag: "N",
                page: 1,
                limit: 20,
                search: search,
                sort: "id_no",
            };

            const response = await getStudentList(params);

            setDataAdminNo(
                response?.data?.data?.map((item) => ({
                    label: item?.admNo,
                    value: item?.admNo,
                }))
            );
        } catch (error) {
            console.log(error?.message);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDownloadTemplate = () => {
        const wb = XLSX.utils.book_new();

        let sheet1 = [];

        sheet1.push(["adm_no", "schemes_name", "schemes_startdate", "schemes_enddate"]);
        sheet1.push(["1234XYZ", "kresna_test_3", "2024-09-21", "2024-10-31"]);

        const ws = XLSX.utils.aoa_to_sheet(sheet1);
        XLSX.utils.book_append_sheet(wb, ws, "Template");

        XLSX.writeFile(wb, "import_comcare_template.xlsx");
    }

    const handleAddRow = () => {
        toast.dismiss();
        if (editArr.some((edit) => edit === false)) {
            toast.error("Save changes before editing another row");

            return false;
        }

        setData((prevArr) => {
            const newObj = {
                adm_no: "",
                schemes_name: "",
                schemes_startdate: new Date(),
                schemes_enddate: new Date()
            };
            return [newObj, ...prevArr];
        });

        setEditArr((prevEditArr) => {
            return [false, ...prevEditArr];
        });

        setIsEditing(true);
    };


    useEffect(() => {
        getCodes();
        handleGetListAdminNo();
    }, [currentPage]);
    return (
        <Layout currentNav={"preparation"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Setup Preparation</Breadcrumb.Item>
                <Breadcrumb.Item active>Maintain and Upload Comcare</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Maintain and Upload Comcare</h2>
            </sgds-content-header-top>
            <sgds-content-area-bottom>
                <Row className={`align-items-end`}>
                    <Col xs={3}>
                        <Form.Group>
                            <Form.Label>Adm no.</Form.Label>
                            {/*
                            <Select
                                onChange={(value) => setAdminNo(value)}
                                options={dataAdminNo}
                                onInputChange={(value) =>
                                    handleSearch(handleGetListAdminNo, value)
                                }
                                value={adminNo}
                            />

                            */}
                            <Form.Control
                                type={"text"}
                                onChange={(e) => setAdminNo(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>Scheme Name</Form.Label>
                            <Form.Control
                                type={"text"}
                                onChange={(e) => setSchemeName(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Button className={`btn-primary`} onClick={() => getCodes()}>
                            Retrieve
                        </Button>
                    </Col>
                </Row>
            </sgds-content-area-bottom>
            <div className="d-flex gap-3">
                <Button
                    variant={`outline-dark`}
                    onClick={handleAddRow}
                    className={`d-flex gap-3`}
                >
                    <i className="bi bi-plus-circle"></i>
                    Add
                </Button>
                <Button
                    variant={"outline-dark"}
                    className={`d-flex gap-2`}
                    onClick={() => {
                        setShowUpload(true);
                    }}
                >
                    <i className={`bi bi-upload`}></i>
                    Upload File
                </Button>
            </div>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm`}>
                    <SortableTable headers={header} data={data} renderActionButtons={renderActionButtons}
                                   renderCellContent={renderCellContent} setData={setData}/>

                    <div className="text-center">
                        <button
                            className={`btn-clear`}
                            onClick={prevPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
              {" "}
                            Page {currentPage} of {totalPages}{" "}
            </span>
                        <button
                            className={`btn-clear`}
                            onClick={nextPage}
                            disabled={!isNextPage}
                        >
                            Next
                        </button>
                    </div>
                </div>
                {/*<Table responsive>*/}
                {/*    <TableHeader>*/}
                {/*        <TableRow>*/}
                {/*            <TableHeaderCell width={"5%"}>#</TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Adm No.*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Scheme Name*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>*/}
                {/*                Start Date*/}
                {/*                <span className={`text-danger`}> *</span>*/}
                {/*            </TableHeaderCell>*/}
                {/*            <TableHeaderCell>End Date</TableHeaderCell>*/}
                {/*            <TableHeaderCell width={"10%"}></TableHeaderCell>*/}
                {/*        </TableRow>*/}
                {/*    </TableHeader>*/}

                {/*    <TableBody>*/}
                {/*        {data?.map((row, index) => (*/}
                {/*            <TableRow key={index}>*/}
                {/*                <TableDataCell>{index + 1}</TableDataCell>*/}
                {/*                <TableDataCell>{row.adm_no}</TableDataCell>*/}
                {/*                <TableDataCell>{row.schemes_name}</TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    {!editArr[index] ? (*/}
                {/*                        <DatePicker*/}
                {/*                            selected={row.schemes_startdate}*/}
                {/*                            onChange={(date) => {*/}
                {/*                                if (date == null) {*/}
                {/*                                    handleChange(index, "schemes_startdate", "");*/}
                {/*                                } else {*/}
                {/*                                    handleChange(index, "schemes_startdate", date);*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            dateFormat="dd/MM/yyyy"*/}
                {/*                            showMonthDropdown*/}
                {/*                            showYearDropdown*/}
                {/*                        />*/}
                {/*                    ) : (*/}
                {/*                        moment(row.schemes_startdate).format("DD/MM/YYYY")*/}
                {/*                    )}*/}
                {/*                </TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    {!editArr[index] ? (*/}
                {/*                        <DatePicker*/}
                {/*                            selected={row.schemes_enddate}*/}
                {/*                            onChange={(date) => {*/}
                {/*                                if (date == null) {*/}
                {/*                                    handleChange(index, "schemes_enddate", "");*/}
                {/*                                } else {*/}
                {/*                                    handleChange(index, "schemes_enddate", date);*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                            dateFormat="dd/MM/yyyy"*/}
                {/*                            showMonthDropdown*/}
                {/*                            showYearDropdown*/}
                {/*                        />*/}
                {/*                    ) : (*/}
                {/*                        moment(row.schemes_enddate).format("DD/MM/YYYY")*/}
                {/*                    )}*/}
                {/*                </TableDataCell>*/}
                {/*                <TableDataCell>*/}
                {/*                    <div className={`d-flex gap-1`}>*/}
                {/*                        {!editArr[index] ? (*/}
                {/*                            <button*/}
                {/*                                className={`btn-clear text-green-500`}*/}
                {/*                                onClick={() => handleSubmitEdit(index, row.id)}*/}
                {/*                            >*/}
                {/*                                <i className={`bi bi-check-lg`}></i>*/}
                {/*                                {` `}*/}
                {/*                                Save*/}
                {/*                            </button>*/}
                {/*                        ) : (*/}
                {/*                            <button*/}
                {/*                                className={`btn-clear`}*/}
                {/*                                disabled={isEditing}*/}
                {/*                                onClick={() => handleEdit(index)}*/}
                {/*                            >*/}
                {/*                                <i className={`bi bi-pencil`}></i> Edit*/}
                {/*                            </button>*/}
                {/*                        )}*/}

                {/*                        <button*/}
                {/*                            className={`btn-clear`}*/}
                {/*                            disabled={isEditing}*/}
                {/*                            onClick={() => {*/}
                {/*                                setShow(true);*/}
                {/*                                setSelectedId(row.id ?? "");*/}
                {/*                                setSelectedIndex(index);*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            <i className={`bi bi-trash`}></i>*/}
                {/*                        </button>*/}
                {/*                    </div>*/}
                {/*                </TableDataCell>*/}
                {/*            </TableRow>*/}
                {/*        ))}*/}
                {/*    </TableBody>*/}
                {/*</Table>*/}

            </sgds-content-body>

            <UploadModal
                show={showUpload}
                handleClose={() => setShowUpload(false)}
                title={`Upload Comcare`}
                onFileSelect={handleFileSelect}
                selectedFile={selectedFile}
                actionOverwrite={postOverwrite}
                actionAppend={postAppend}
                handleDownloadTemplate={handleDownloadTemplate}
            />

            <CustomModal
                show={show}
                title={`Delete record?`}
                handleClose={() => setShow(false)}
                action={handleDeleteRow}
                description={`The selected record will be remove from the list. Proceed to delete?`}
            />
        </Layout>
    );
}

import { apiCall } from "@/utils/apiCall";

export function getSiblings(acadYear, exercise, adm_no) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/siblings?acad_yr=${acadYear}&exercise=${exercise}&adm_no=${adm_no}`,
    {},
    "GET"
  );
}

export function updateSiblings(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/siblings`,
    params,
    "POST"
  );
}

export function deleteSiblings(id) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/siblings/${id}`,
    {},
    "DELETE"
  );
}

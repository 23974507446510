import { apiCall } from "@/utils/apiCall";

export function getParents(acadYear, exercise, adm_no) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/parents?acad_yr=${acadYear}&exercise=${exercise}&adm_no=${adm_no}`,
    {},
    "GET"
  );
}

export function updateParents(params) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/parents`,
    params,
    "PATCH"
  );
}

import { apiCall } from "@/utils/apiCall";

export async function getApplicationHomes(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/homes/online?acad_yr=${ACAD_YR}&exercise=${EXERCISE}&adm_no=${ADM_NO}&appln_status_not_equals=D`,
    {},
    "GET"
  );
}

export async function getMTCRequest(ACAD_YR, EXERCISE, ADM_NO) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/requests?acad_yr=${ACAD_YR}&faas_exercise=${EXERCISE}&adm_no=${ADM_NO}`,
    {},
    "GET"
  );
}

export async function getMTCRequestResult(REQUEST_ID, MEAN_TEST_ID) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/requests/results?request_id=${REQUEST_ID}&means_test_id=${MEAN_TEST_ID}`,
    {},
    "GET"
  );
}

export async function getMTCases(MEANS_TEST_ID, CASE_ID) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/cases?means_test_id=${MEANS_TEST_ID}&case_id=${CASE_ID}`,
    {},
    "GET"
  );
}

export async function getMTCaseNotes(MEANS_TEST_ID, CASE_ID) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/cases/notes?means_test_id=${MEANS_TEST_ID}&case_id=${CASE_ID}`,
    {},
    "GET"
  );
}

export async function getMTCDetails(
  ACAD_YR,
  EXERCISE,
  ADM_NO,
  DETAIL_TAG = ""
) {
  if (DETAIL_TAG == "") {
    return apiCall(
      `${process.env.REACT_APP_API_URL}/applications/mtc/details?acad_yr=${ACAD_YR}&faas_exercise=${EXERCISE}&adm_no=${ADM_NO}`,
      {},
      "GET"
    );
  } else {
    return apiCall(
      `${process.env.REACT_APP_API_URL}/applications/mtc/details?acad_yr=${ACAD_YR}&faas_exercise=${EXERCISE}&adm_no=${ADM_NO}&detail_tag=${DETAIL_TAG}`,
      {},
      "GET"
    );
  }
}

export async function postApplicationHomes(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications`,
    data,
    "PATCH"
  );
}

export async function getMtConsent(ACK_NO, NYP_CONSENT_ID = "", DETAIL_TAG, HH_UIN="") {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/consents?ack_no=${ACK_NO}&nyp_consent_id=${NYP_CONSENT_ID}&detail_tag=${DETAIL_TAG}&hh_uin=${HH_UIN}&limit=0`,
    {},
    "GET"
  );
}

export async function getMtIncomes(MEANS_TEST_ID) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mt/incomes?means_test_id=${MEANS_TEST_ID}`,
    {},
    "GET"
  );
}

export async function postAppeal(data) {
  return apiCall(
    `${process.env.REACT_APP_API_URL}/applications/mtc/details`,
    { data: data },
    "POST"
  );
}

export function postQueryHomesRhhMtc(jsonData) {
  return apiCall(
    `${process.env.REACT_APP_API_HOMES_URL}/homes/mtc/rhh`,
    jsonData,
    "POST"
  );
}
